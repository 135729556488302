<template>
  <div>
    <el-button @click="showModal()" class="button_style_1"
      >Select PDF
    </el-button>

    <el-button v-if="pdfID > 0" @click="removePDF()" class="button_style_1"
      >Remove PDF
    </el-button>

    <el-dialog v-model="dialog" width="900px">
      <header class="tab-header media_tab_header">
        <button @click="tab = 1" :class="{ active: tab == 1 }" type="button">
          PDFs
        </button>
        <button @click="tab = 0" :class="{ active: tab == 0 }" type="button">
          Upload (Drag 'n' Drop)
        </button>
      </header>

      <div v-if="tab == 1">
        <div class="d-flex flex-row flex-wrap media_images">
          <div
            v-for="pdf in $store.state.pdfs.PDFs"
            :key="pdf.id"
            class="ma-2 selectable"
            :class="{ selected: pdfID && pdfID === pdf.id }"
          >
            <div class="iframe-overlay" @click="() => slectedPDF(pdf.id)">
              <span>{{ pdf.alt }}</span>
              <iframe
                :src="returnURI + pdf.slug"
                frameBorder="0"
                scrolling="auto"
                height="100%"
                width="100%"
              ></iframe>
            </div>
            <div class="delete_media" @click="() => mediaDelete(pdf.id)">
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
          <div class="full-w d-flex" v-if="setSpinner">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab == 0">
        <div class="drag-and-drop__box">
          <div v-bind="getRootProps()">
            <input v-bind="getInputProps()" />
            <div class="d-flex center" v-if="loading">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <p v-else>
              Drag 'n' drop some files here, or click to select files
            </p>
          </div>
        </div>
      </div>
      <el-dialog
        class="dialog-fix"
        v-model="confirmDelete"
        title="Deleting"
        width="30%"
        :before-close="handleClose"
      >
        <span>Are you sure you want to delete?</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="confirmDelete = false" class="button_style_1"
              >Cancel</el-button
            >
            <el-button
              type="primary"
              class="button_style_2 c-w"
              @click="
                confirmDelete = false;
                destroyIt();
              "
              >Delete</el-button
            >
          </span>
        </template>
      </el-dialog>
      <el-button
        @click="insertImage"
        class="button_style_1"
        :title="'Image URL needs to be valid'"
        :disabled="!pdfID"
      >
        Select PDF
      </el-button>
      <el-button class="button_style_2" @click="dialog = false">
        Close
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { ElMessageBox, ElNotification } from "element-plus";
import { useCookies } from "vue3-cookies";
import { ref } from "vue";
import { useStore } from "vuex";
import api from "../../services/api";

export default {
  name: "PDFSelector",
  setup() {
    const { cookies } = useCookies();
    const loading = ref(false);
    const store = useStore();

    function onDrop(acceptFiles, rejectReasons) {
      loading.value = true;
      const formData = new FormData();
      if (acceptFiles[0].size > process.env.VUE_APP_MAX_WEIGHT_BYTE) {
        loading.value = false;
        ElNotification({
          title: "Error",
          message: `Your media larger then ${process.env.VUE_APP_MAX_WEIGHT_BYTE_DISPLAY}MB limit!`,
          type: "error",
        });
      } else {
        formData.append("file", acceptFiles[0]);
        api
          .post(`${process.env.VUE_APP_SHARED_API}api/media`, formData)
          .then((res) => {
            if (res.status === 200) {
              loading.value = false;
              store.dispatch("resetPDFs");
              ElNotification({
                title: "Success!",
                message: "Media uploaded successfully!",
                type: "success",
              });
            }
          })
          .catch((e) => {
            loading.value = false;
            ElNotification({
              title: `${e.message}`,
              message: `${e.response.data?.errors?.file[0]}`,
              type: "error",
            });
          });
      }
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    const handleClose = (done) => {
      ElMessageBox.confirm("Are you sure to close this dialog?")
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };
    return {
      getRootProps,
      getInputProps,
      handleClose,
      loading,
      ...rest,
    };
  },
  props: {
    mediaType: {
      type: String,
    },
    fileSelect: {
      type: Function,
    },
    externalPDFId: Number,
    externalPDFSlug: Text,
    mediaData: Function,
  },
  methods: {
    fetchPDFs() {
      this.setSpinner = true;
      this.$api
        .get(
          `${process.env.VUE_APP_SHARED_API}api/media?per_age=50&type=documents`
        )
        .then((res) => {
          if (res.data.current_page) {
            this.setSpinner = false;
            this.$store.dispatch("setPDFs", res.data.data);
          }
        });
    },
    showModal() {
      this.dialog = true;
      this.$emit("openModal");
      if (this.$store.state.pdfs.PDFs.length < 1) this.fetchPDFs();
    },
    insertImage() {
      this.fileSelect(this.pdfID);
      this.closeModal();
      if (this.mediaData) {
        this.mediaData(
          this.$store.state.pdfs.PDFs.filter((data) => data.id === this.pdfID)
        );
      }
    },
    slectedPDF(id) {
      this.pdfID = id;
    },
    closeModal() {
      this.dialog = false;
      this.tab = 1;
    },
    destroyIt() {
      const loader = this.$loading.show();
      this.$api
        .delete(
          `${process.env.VUE_APP_SHARED_API}api/media/${this.mediaToDelete}`
        )
        .then((res) => {
          loader.hide();
          ElNotification({
            title: "Success!",
            message: res.data.message,
            type: "success",
          });
          this.fetchPDFs();
        })
        .catch((e) => {
          loader.hide();
          ElNotification({
            title: "Warning!",
            message: e.response.data.message,
            type: "warning",
          });
        });
    },
    removePDF() {
      this.slectedPDF(0);
      this.pdfID = 0;
      this.$store.dispatch("resetPDFId");
    },
    mediaDelete(id) {
      this.mediaToDelete = id;
      this.confirmDelete = true;
    },
  },
  computed: {
    returnURI() {
      return process.env.VUE_APP_SHARED_API;
    },
  },
  data() {
    return {
      dialog: false,
      tab: 1,
      pdfID: 0,
      setSpinner: false,
      selectedImage: null,
      confirmDelete: false,
      mediaToDelete: 0,
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new" &&
        this.pdfID === 0
      ) {
        this.pdfID = state.customFields.techniques?.pdfData?.id;
        this.fileSelect(this.pdfID);
        this.closeModal();
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  watch: {
    tab(newVal, oldVal) {
      if (this.$store.state.pdfs.PDFs.length < 1) this.fetchPDFs();
    },
  },
};
</script>

<style lang="scss" scoped>
.media_images {
  border: 1px solid #42b983;
  border-top: none;
  padding: 15px;
  margin-bottom: 30px;
  max-height: 900px;
  overflow-y: scroll;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  .delete_media {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: red;
    border-radius: 50%;
    align-items: center;
    z-index: 22;
    svg {
      color: white;
    }
  }

  .selectable {
    max-width: 100px;
    max-height: 100px;
    margin: 3px;
  }

  div {
    position: relative;
  }

  div.selected:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  div.selected:after {
    content: "\2714";
    font-size: 24px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.selectable {
  cursor: pointer;
}

.drag-and-drop__box {
  height: 300px;
  border: 1px solid #42b983;
  border-top: 0;
  cursor: pointer;
  margin-bottom: 30px;

  div {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    p {
      font-size: 18px;
    }
  }
}

.media_document {
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0 8px;
  flex-direction: column;
  text-align: center;

  svg {
    font-size: 30px;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
  }
}

.tab-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #222;
}

.tab-header button {
  color: #222;
  background: none;
  border: 0;
  flex: 1;
  padding: 5px 10px;
  cursor: pointer;
}

.tab-header button.active {
  color: #fff;
}

.tab-header.media_tab_header button {
  padding: 20px 0;
  font-size: 20px;
}

.tab-header.media_tab_header button.active {
  background-color: #42b983;
}
@media (max-width: 550px) {
  .tab-header.media_tab_header button {
    font-size: 15px;
    word-break: break-all;
  }
}

.tab-header.media_tab_header {
  border-bottom: 1px solid #42b983;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #42b983;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.full-w {
  width: 100%;
  display: flex;
}
.iframe-overlay {
  cursor: pointer;
  transition: all 0.3s;
  width: 100px;
  height: 100px;
  position: relative;
}
.iframe-overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  text-align: center;
  color: white;
  font-weight: bold;
  transition: all 0.3s;
  text-transform: capitalize;
}
.iframe-overlay::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  background: #42b9834f;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.iframe-overlay iframe {
  pointer-events: none;
}
.center .lds-ellipsis {
  width: auto !important;
  height: auto !important;
  position: relative;
  left: -45px;
  top: -45px;
}
.c-w {
  color: white !important;
}
@media (max-width: 550px) {
  .media_images {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .drag-and-drop__box div {
    text-align: center;
    font-size: 15px !important;
  }
}
</style>

