<template>
  <img class="site-logo" src="../assets/img/ceres_logo_app.svg" />
  <div class="main-menu">
    <div class="navigation-menu">
      <ul>
        <li>
          <router-link
            :to="{
              name: 'Dashboard',
            }"
          >
            <font-awesome-icon
              :icon="['fas', 'tachometer-alt']"
              class="nav_menu_icons"
            />
            Dashboard
          </router-link>
        </li>
        <li>
          <el-dropdown trigger="click">
            <a>
              <font-awesome-icon
                :icon="['fas', 'copy']"
                class="nav_menu_icons"
              />
              Pages<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Pages',
                    }"
                  >
                    All Pages
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'New Item',
                      params: {
                        type: 'page',
                      },
                    }"
                  >
                    Add new page
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Pages trash',
                    }"
                  >
                    Trash
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click">
            <a>
              <font-awesome-icon
                :icon="['fas', 'users']"
                class="nav_menu_icons"
              />
              Users<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Users',
                    }"
                  >
                    All Users
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Deactivated',
                    }"
                  >
                    Deactivated Users
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <router-link
            :to="{
              name: 'Menu',
            }"
          >
            <font-awesome-icon
              :icon="['fab', 'elementor']"
              class="nav_menu_icons"
            />
            Menus
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'Widgets',
            }"
          >
            <font-awesome-icon :icon="['fa', 'table']" class="nav_menu_icons" />
            Widgets
          </router-link>
        </li>
        <li>
          <el-dropdown trigger="click">
            <a>
              <font-awesome-icon
                :icon="['fas', 'paint-brush']"
                class="nav_menu_icons"
              />
              Artworks<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Artworks',
                    }"
                  >
                    All Artworks
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'New Item',
                      params: {
                        type: 'artworks',
                      },
                      query: {
                        v: '2',
                        mode: 'new',
                        singular: 'artwork',
                      },
                    }"
                  >
                    Add new artwork
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Tags',
                    }"
                  >
                    Tags
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CustomCat',
                      params: {
                        type: 'collector-items',
                      },
                    }"
                  >
                    Collector Items
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CustomCat',
                      params: {
                        type: 'colors',
                      },
                    }"
                  >
                    Colors
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CustomCat',
                      params: {
                        type: 'types',
                      },
                    }"
                  >
                    Types
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CustomCat',
                      params: {
                        type: 'suits',
                      },
                    }"
                  >
                    Suits
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CustomCat',
                      params: {
                        type: 'subject-matters',
                      },
                    }"
                  >
                    Subject Matter
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Trash',
                      params: {
                        type: 'artworks',
                      },
                    }"
                  >
                    Trash
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click">
            <a>
              <font-awesome-icon
                :icon="['fas', 'user']"
                class="nav_menu_icons"
              />
              Artists<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Artists',
                    }"
                  >
                    All Artists
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'New Item',
                      params: {
                        type: 'artists',
                      },
                      query: {
                        v: '2',
                        mode: 'new',
                        singular: 'artist',
                      },
                    }"
                  >
                    Add new artist
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Trash',
                      params: {
                        type: 'artists',
                      },
                    }"
                  >
                    Trash
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click">
            <a>
              <font-awesome-icon
                :icon="['fas', 'ruler']"
                class="nav_menu_icons"
              />
              Techniques<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Techniques',
                    }"
                  >
                    All Techniques
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'New Item',
                      params: {
                        type: 'techniques',
                      },
                      query: {
                        v: '2',
                        mode: 'new',
                        singular: 'technique',
                      },
                    }"
                  >
                    Add new technique
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'Trash',
                      params: {
                        type: 'techniques',
                      },
                    }"
                  >
                    Trash
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <router-link
            :to="{
              name: 'Orders',
            }"
          >
            <font-awesome-icon
              :icon="['fab', 'elementor']"
              class="nav_menu_icons"
            />
            Orders
          </router-link>
        </li>
        <li>
          <el-dropdown trigger="click">
            <a>
              <font-awesome-icon
                :icon="['fas', 'file-export']"
                class="nav_menu_icons"
              />
              Exports<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CategoryExport',
                    }"
                  >
                    ID's
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    :to="{
                      name: 'CustomerExport',
                    }"
                  >
                    Users
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <router-link
            :to="{
              name: 'Tax',
            }"
          >
            <font-awesome-icon
              :icon="['fas', 'coins']"
              class="nav_menu_icons"
            />
            Tax Settings
          </router-link>
        </li>
        <!-- <li>
          <router-link
            :to="{
              name: 'NFTs',
            }"
          >
            <font-awesome-icon
              :icon="['fab', 'elementor']"
              class="nav_menu_icons"
            />
            NFTs
          </router-link>
        </li> -->
      </ul>
      <div class="logout_button">
        <el-button type="warning" plain @click="logOutUser">Log out</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavigationMenu",
  methods: {
    logOutUser() {
      this.$cookies.remove("bearerToken");
      this.$store.dispatch("logOutUser");
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.nav_menu_icons {
  margin-right: 5px;
  min-width: 30px;
}

.site-logo {
  max-width: 150px;
  margin: 20px auto;
  display: block;
  max-width: 90px;
}
</style>
