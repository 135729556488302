import { createStore } from 'vuex'
import user from './modules/user'
import items from './modules/items'
import media from './modules/media'
import customFields from './modules/cf'
import block from './modules/blockEditor'
import pdfs from './modules/pdfs'
import widgets from './modules/widgets'
import menuEmits from './modules/menus'
import loginModal from './modules/loginModal'
import errorHandler from './modules/errorBoundaries'

export default createStore({
  modules: {
    user,
    items,
    media,
    customFields,
    block,
    pdfs,
    widgets,
    menuEmits,
    loginModal,
    errorHandler
  }
})
