<template>
  <div class="row">
    <div class="col-12">
      <el-button
        :disabled="disabled"
        class="button_style_1 mt-2"
        @click="addMenuItem"
      >
        Add New Menu Item
      </el-button>
    </div>
    <div class="col-12">
      <el-button
        :disabled="disabled"
        class="button_style_1 mt-2 mb-2"
        @click="addMenuItemsGroup"
      >
        Add New Menu Item Group
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabled: true,
    };
  },
  props: {
    selected: Number,
  },
  methods: {
    addMenuItem() {
      this.$store.dispatch("addMenuItems", "single-item");
    },
    addMenuItemsGroup() {
      this.$store.dispatch("addMenuItems", "menu-group");
    },
  },
  watch: {
    selected: function (newVal, oldVal) {
      if (newVal !== false) this.disabled = false;
      if (newVal === false) this.disabled = true;
    },
  },
};
</script>

<style>
</style>
