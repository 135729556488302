<template>
  <div class="row mb-5">
    <small>Group name</small>
    <el-input
      v-model="menuItemData.name"
      type="text"
      required
      placeholder="Name"
    ></el-input>
  </div>
  <div
    v-for="(item, index) in megaMenu"
    :key="item"
    class="relative-parent mb-4"
  >
    <div
      class="delete-row"
      style="z-index: 1"
      @click="
        () => {
          megaMenu.splice(index, 1);
        }
      "
    >
      <svg
        class="svg-inline--fa fa-trash-alt fa-w-14"
        data-v-b4ae8fbc=""
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="trash-alt"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
        ></path>
      </svg>
    </div>
    <group-row :parentId="menuItem.id" :propsData="item" />
  </div>
  <el-button @click="addNewRow" class="mt-2 ml-3" size="small" type="primary"
    >Add new row</el-button
  >
</template>

<script>
import GroupRow from "./components/GroupRow.vue";

export default {
  props: {
    menuItem: Object,
  },
  components: {
    "group-row": GroupRow,
  },
  data() {
    return {
      menuItemData: {
        name: "",
        type: "megamenu",
      },
      megaMenu: [],
    };
  },
  mounted() {
    this.menuItemData = { ...this.menuItem };
    this.megaMenu = this.menuItem.item_properties || [];
  },
  methods: {
    addNewRow() {
      this.megaMenu.push({
        name: "",
        type: "",
        data: {},
        blankTarget: false,
        externalLink: "",
      });
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_MENU_DATA") {
        this.megaMenu = [];
        state.menuEmits.menuItemsGroup.map((obj) => {
          if (obj.parentId == this.menuItem.id) {
            this.megaMenu.push(obj.menuData);
          }
        });
        this.$emit("collectData", {
          menuItemData: this.menuItemData,
          megaMenu: this.megaMenu,
        });
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.delete-row {
  position: absolute;
  top: -5px;
  right: 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.relative-parent {
  position: relative;
}
.ml-3 {
  margin-left: 11px;
}
</style>
