<template>
  <el-button class="button_style_1 mt-2" @click="createTechnique">
    Add new technique
  </el-button>
</template>

<script>
import { ElMessageBox, ElNotification } from "element-plus";
import { createTechnique } from "../services";
export default {
  props: {
    externalCb: {
      type: Function,
      required: true,
    },
  },
  methods: {
    createTechnique() {
      ElMessageBox.prompt("Please enter technique name", "New technique", {
        confirmButtonText: "Create",
        cancelButtonText: "Cancel",
      }).then(async ({ value }) => {
        if (value) {
          const loader = this.$loading.show();
          try {
            const technqiue = await createTechnique(
              value,
              this.$cookies.get("bearerToken")
            );
            if (technqiue.data.id) {
              this.$emit("externalCb", technqiue.data);
              ElNotification({
                title: "Success!",
                message: `${value} created successfully.`,
                type: "success",
              });
            }
            loader.hide();
          } catch (e) {
            loader.hide();
            if (e.response) {
              for (let error in e.response.data.errors) {
                for (var i = 0; i < e.response.data.errors[error].length; i++) {
                  ElNotification({
                    title: "Warning!",
                    message: e.response.data.errors[error][i],
                    type: "warning",
                  });
                }
              }
            } else {
              ElNotification({
                title: "Warning!",
                message: e.message,
                type: "warning",
              });
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new__box {
  width: 30px;
  height: 100%;
  margin-left: 20px;
  background-color: #1f3c2a;
  color: white;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  min-height: 30px;
  margin-top: 7px;
  cursor: pointer;
}
</style>
