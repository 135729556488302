<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Users exports</div>
  </div>
  <div class="row p-1">
    <p>
      Download this CSV to view all CERES users and further info about them.
    </p>
    <el-button @click="exportData" class="button_style_1"
      >Download CSV
    </el-button>
  </div>
</template>

<script>
import { excelParser } from "@/helpers/excele-parser";
import axios from "axios";
import { ElNotification } from "element-plus";

export default {
  name: "CustomerExport",
  components: {},
  data() {
    return {
      json_data: [],
    };
  },
  mounted() {
    this.fetchData().then((res) => {
      this.json_data = res.data;
    });
  },
  methods: {
    exportData() {
      try {
        excelParser().exportDataFromJSON(this.json_data, "customers", "csv");
      } catch (error) {
        ElNotification({
          title: "Error!",
          message: error,
          type: "error",
        });
      }
    },
    async fetchData() {
      let data = {
        role: "customer",
      };
      return await axios
        .post(
          `${process.env.VUE_APP_SHARED_API}api/bulk-export/customers`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$cookies.get("bearerToken")}`,
            },
          }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          ElNotification({
            title: "Error!",
            message: error,
            type: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.button_style_1 {
  max-width: 350px !important;
}
</style>
