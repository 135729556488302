<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Widgets</div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-4">
      <h2>Available Widgets</h2>
      <p class="info">
        To activate a widget, click on <b>+</b> sign. To deactivate a widget and
        delete its settings, click on delete button.
        <br />
        If you want to reorder them, just drag and drop them.
      </p>
      <ul class="widgets-list">
        <li>
          <div class="widget" @click="addWidgetToList('image_id')">
            <h3>Image</h3>
            <div class="plus-sign">+</div>
          </div>
          <p class="widget-description">This is image widget.</p>
        </li>
        <li>
          <div class="widget" @click="addWidgetToList('menu_id')">
            <h3>Menu</h3>
            <div class="plus-sign">+</div>
          </div>
          <p class="widget-description">This is menu widget.</p>
        </li>
        <li>
          <div class="widget" @click="addWidgetToList('custom_html')">
            <h3>Custom HTML</h3>
            <div class="plus-sign">+</div>
          </div>
          <p class="widget-description">This is HTML widget.</p>
        </li>
        <li>
          <div class="widget" @click="addWidgetToList('klaviyo')">
            <h3>Klaviyo</h3>
            <div class="plus-sign">+</div>
          </div>
          <p class="widget-description">
            Read more
            <a href="https://www.klaviyo.com/" target="_blank">about Klaviyo</a>
          </p>
        </li>
      </ul>
    </div>
    <div class="col-12 col-sm-8 mt-2">
      <p class="ml-2">
        Location:
        <el-select v-model="location" class="m-2" placeholder="Location">
          <el-option
            v-for="item in locations"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </p>
      <div class="widgets-area-list">
        <div class="row">
          <div class="col-12 col-lg-6 mr-1">
            <draggable
              :list="widgets"
              item-key="id"
              class="list-group"
              ghost-class="ghost"
              @update="saveUpdatedOrder"
            >
              <template #item="{ element }">
                <div class="widget-item" @click="expand(element.id)">
                  <h2 class="widget-title">
                    {{ element.name }}
                    <span v-if="!element.expand" class="expander"
                      ><div class="plus-sign">+</div>
                    </span>
                    <span v-else class="expander"
                      ><div class="plus-sign">-</div>
                    </span>
                  </h2>
                  <transition>
                    <div class="child" v-if="element.expand" @click.stop="">
                      <VErrorBoundary>
                        <template #boundary="{ hasError }">
                          <div v-if="!hasError">
                            <el-input
                              v-model="element.name"
                              class="add-new-item__title"
                              placeholder="Title"
                            ></el-input>
                            <div v-if="element.type == 'custom_html'">
                              <tiptap
                                v-if="element.custom_html.type != 'klaviyo'"
                                class="mb-3"
                                style="height: 200px; overflow-y: scroll"
                                v-model="element.custom_html"
                              />
                              <p v-else>
                                <small><i>Newsletter list id:</i></small>
                                <el-input
                                  v-model="
                                    element.custom_html.properties
                                      .newsletter_list_id
                                  "
                                  class="add-new-item__title"
                                  placeholder="Id"
                                ></el-input>
                              </p>
                            </div>
                            <div
                              style="max-width: 200px"
                              v-else-if="element.type == 'image'"
                            >
                              <ImageSelectorInput
                                class="mb-3"
                                v-model="element.image_id"
                                :multiple="false"
                                :featuredImageSlug="
                                  element?.image?.slug
                                    ? domainURI + element.image.slug
                                    : 'empty'
                                "
                              ></ImageSelectorInput>
                            </div>
                            <div v-else-if="element.type == 'menu'">
                              <el-select
                                v-model="element.menu_id"
                                class="m-2"
                                placeholder="Menu"
                              >
                                <el-option
                                  v-for="item in menus.data"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                                />
                              </el-select>
                            </div>
                            <div v-else>
                              <small><i>Newsletter list id:</i></small>
                              <el-input
                                v-model="element.newsletter_list_id"
                                class="add-new-item__title"
                                placeholder="Id"
                              ></el-input>
                            </div>
                            <el-button
                              class="button_style_1 mb-3"
                              @click="
                                saveWidgets(
                                  element.created_at ? 'update' : 'create',
                                  element.id
                                )
                              "
                            >
                              {{
                                element.created_at
                                  ? "Update"
                                  : "Create new widget"
                              }}
                            </el-button>
                          </div>
                          <div v-else>
                            This widget is not valid, try to create it again.
                          </div>
                        </template>
                      </VErrorBoundary>
                      <el-button
                        v-if="element.created_at"
                        class="button_style_2 mb-3"
                        @click="removeWidget(element.id)"
                      >
                        Remove widget
                      </el-button>
                      <el-button
                        v-if="!element.created_at"
                        class="button_style_2 mb-3"
                        @click="localRemoveWidget(element.id)"
                      >
                        Remove widget
                      </el-button>
                    </div>
                  </transition>
                </div>
              </template>
            </draggable>
            <el-button
              v-if="runBulk"
              class="button_style_1 mt-3"
              @click="bulkUpdate"
            >
              Save new order
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageSelectorInput from "../../components/Tiptap/ImageSelectorInput.vue";
import Tiptap from "../../components/Tiptap/Tiptap.vue";
import { getAllMenus } from "../../services/menu";
import draggable from "vuedraggable";
import VErrorBoundary from "vue-error-boundary";
import { ElInput, ElNotification } from "element-plus";
export default {
  components: {
    ElInput,
    Tiptap,
    ImageSelectorInput,
    draggable,
    VErrorBoundary,
  },
  data() {
    return {
      widgets: [],
      menus: [],
      orderIds: [],
      location: "footer",
      runBulk: false,
      locations: [
        {
          id: 1,
          name: "footer",
        },
        {
          id: 2,
          name: "header",
        },
      ],
    };
  },
  methods: {
    addWidgetToList(type) {
      const ids = this.widgets
        .map((w) => parseInt(w.id))
        .filter((id) => !isNaN(id));
      const largestId = Math.max(...ids, 0);

      const newWidget = {
        id: largestId + 1,
        name: "Please enter name",
        type: type.replace("_id", ""),
        location: this.location,
        order:
          this.widgets.length > 0
            ? parseInt(this.widgets[this.widgets.length - 1].order) + 1
            : 1,
        expand: true,
        [type]: "",
      };

      this.widgets.push(newWidget);
    },
    expand(id) {
      let index = this.widgets.findIndex((w) => w.id == id);
      this.widgets[index].expand = !this.widgets[index].expand;
    },
    getById(id, array) {
      for (var i = 0, len = array.length; i < len; i++) {
        if (array[i].id === id) {
          return array[i];
        }
      }
      return null;
    },
    async saveWidgets(mode, id) {
      const loader = this.$loading.show();
      let item = this.getById(id, this.widgets);
      if (mode === "create") {
        if (item.hasOwnProperty("klaviyo")) {
          debugger;
          item = {
            ...item,
            type: "custom_html",
            custom_html: {
              type: "klaviyo",
              properties: {
                newsletter_list_id: item.newsletter_list_id,
              },
            },
          };
        }
        this.$api
          .post(`${this.domainURI}api/widgets`, {
            ...item,
          })
          .then((res) => {
            if (res.data.id) {
              this.fetchWidgetsByLocation();
              loader.hide();
              ElNotification({
                title: "Success!",
                message: "Widget successfully created.",
                type: "success",
              });
            }
          })
          .catch((e) => {
            loader.hide();
            if (e.response?.data?.errors?.image_id) {
              ElNotification({
                title: "Warning!",
                message: `Image is required!`,
                type: "warning",
              });
            } else if (e.response?.data?.errors?.menu_id) {
              ElNotification({
                title: "Warning!",
                message: `Menu is required!`,
                type: "warning",
              });
            } else if (e.response?.data?.errors?.custom_html) {
              ElNotification({
                title: "Warning!",
                message: `HTML is required!`,
                type: "warning",
              });
            } else {
              ElNotification({
                title: "Warning!",
                message: `Something went wrong, please try later`,
                type: "warning",
              });
            }
          });
      } else {
        this.$api
          .put(`${this.domainURI}api/widgets/${item.id}`, {
            ...item,
          })
          .then((res) => {
            if (res.data.id) {
              this.fetchWidgetsByLocation();
              loader.hide();
              ElNotification({
                title: "Success!",
                message: "Widget successfully updated.",
                type: "success",
              });
            }
          })
          .catch((e) => {
            loader.hide();
            ElNotification({
              title: "Warning!",
              message: "Something went wrong, please try later!",
              type: "warning",
            });
          });
      }
    },
    async removeWidget(id) {
      const loader = this.$loading.show();
      this.$api
        .delete(`${this.domainURI}api/widgets/${id}`)
        .then((res) => {
          let index = this.widgets.findIndex(function (o) {
            return o.id === id;
          });
          if (index !== -1) this.widgets.splice(index, 1);
          ElNotification({
            title: "Success!",
            message: res.data.message,
            type: "success",
          });
          loader.hide();
        })
        .catch((e) => {
          loader.hide();
          ElNotification({
            title: "Warning!",
            message: "Something went wrong, please try later!",
            type: "warning",
          });
        });
    },
    localRemoveWidget(id) {
      this.widgets = this.widgets.filter((w) => w.id !== id);
    },
    saveUpdatedOrder(e) {
      this.widgets = this.widgets.map((w, i) => ({
        ...w,
        order: i,
      }));
      this.runBulk = true;
    },
    fetchWidgetByLocation() {
      this.widgets = [];
      const loader = this.$loading.show();
      this.$api
        .get(`${this.domainURI}api/widgets?location=${this.location}&sort=ASC`)
        .then((res) => {
          loader.hide();
          let localData = [];
          this.widgets = [];
          if (res.data.length > 0) {
            res.data.map((d, index) => {
              this.orderIds.push({
                i: index,
              });
              return localData.push({
                ...d,
                custom_html: JSON.parse(d.content).custom_html,
              });
            });
            this.widgets = localData;
          }
        })
        .catch((e) => {
          loader.hide();
          ElNotification({
            title: "Warning!",
            message: "Something went wrong, please try later!",
            type: "warning",
          });
        });
    },
    async bulkUpdate() {
      let saveWidgetStatus = true;
      this.widgets.map((widget) => {
        if (!widget.created_at) {
          saveWidgetStatus = false;
          return ElNotification({
            title: "Warning!",
            message: "Create the widget first, and then set a new order.",
            type: "warning",
          });
        }
      });
      if (saveWidgetStatus) {
        const loader = this.$loading.show();
        this.$api
          .post(`${this.domainURI}api/widgets/bulk-update`, this.widgets)
          .then((res) => {
            this.runBulk = false;
            if (res.data) {
              loader.hide();
              ElNotification({
                title: "Success!",
                message: "Widget successfully updated.",
                type: "success",
              });
            }
          })
          .catch((e) => {
            this.runBulk = false;
            loader.hide();
            if (e.response?.data?.errors?.image_id) {
              ElNotification({
                title: "Warning!",
                message: `${e.response?.data?.errors?.image_id[0]}`,
                type: "warning",
              });
            } else if (e.response?.data?.errors?.menu_id) {
              ElNotification({
                title: "Warning!",
                message: `${e.response?.data?.errors?.menu_id[0]}`,
                type: "warning",
              });
            } else {
              ElNotification({
                title: "Warning!",
                message: `Something went wrong, please try later`,
                type: "warning",
              });
            }
          });
      }
    },
    fetchWidgetsByLocation() {
      const loader = this.$loading.show();
      this.$api
        .get(`${this.domainURI}api/widgets?location=${this.location}&sort=ASC`)
        .then((res) => {
          loader.hide();
          let localData = [];

          res.data.map((d, index) => {
            this.orderIds.push({
              i: index,
            });
            return localData.push({
              ...d,
              custom_html: JSON.parse(d.content).custom_html,
            });
          });
          this.widgets = localData || [];
        })
        .catch((e) => {
          loader.hide();
          ElNotification({
            title: "Warning!",
            message: "Something went wrong, please try later!",
            type: "warning",
          });
        });
    },
    async fetchWidgetsLocations() {
      this.$api
        .get(`${this.domainURI}api/widget-locations?per_page=99999`) // have pagination on the BE, avoid page 2
        .then((res) => {
          this.locations = res.data.data;
        })
        .catch((e) => {
          this.runBulk = false;
          loader.hide();
          if (e.response?.data?.errors?.image_id) {
            ElNotification({
              title: "Warning!",
              message: `${e.response?.data?.errors?.image_id[0]}`,
              type: "warning",
            });
          } else if (e.response?.data?.errors?.menu_id) {
            ElNotification({
              title: "Warning!",
              message: `${e.response?.data?.errors?.menu_id[0]}`,
              type: "warning",
            });
          } else {
            ElNotification({
              title: "Warning!",
              message: `Something went wrong, please try later`,
              type: "warning",
            });
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch("setMediaData", "image");
    getAllMenus().then((results) => {
      this.menus = results;
    });
    this.fetchWidgetsByLocation();
    this.fetchWidgetsLocations();
  },
  watch: {
    $route(to, from) {},
    location(newV, old) {
      this.location = newV;
      this.fetchWidgetByLocation();
    },
  },
  computed: {
    domainURI() {
      return process.env.VUE_APP_SHARED_API;
    },
  },
};
</script>

<style lang="scss" scoped>
.required-field {
  color: red;
  font-weight: bold;
}
.danger-area {
  width: 100%;
  display: flex;
  opacity: 0;
  transition: all 0.3s;
}
tr:hover .danger-area {
  transition: all 0.3s;
  opacity: 1;
}
.danger-area p {
  margin: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  color: #2271b1;
  margin-right: 10px;
  line-height: 10px;
}
.danger-area p.delete {
  color: #b32d2e;
}
h4 {
  font-size: 23px;
  font-weight: 400;
  margin: 0;
  margin-right: 0px;
  padding: 9px 0 4px;
  line-height: 1.3;
  padding-left: 10px;
  margin-bottom: 30px;
}
.col-4 h2,
.col-8 h2 {
  color: #1d2327;
  font-size: 1.3em;
  overflow: hidden;
  white-space: normal;
  line-height: 1.5;
  display: block;
  font-weight: 600;
}
.info {
  color: #50575e;
  margin: 0;
  font-style: normal;
  font-size: 13px;
}
.widgets-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 20px;
  list-style-type: none;
  padding: 0;
  position: sticky;
  top: 20px;
  left: 0;
}
.widgets-list li {
  color: #3c434a;
  margin-bottom: 0px;
}
.widget-description {
  display: block;
  padding: 10px 15px;
  font-size: 12px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  margin-left: 0;
}
.widget {
  cursor: pointer;
  background: #f6f7f7;
  color: #1d2327;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4em;
  border: 1px solid #c3c4c7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  outline: none;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}
.widget h3 {
  margin: 0;
  padding: 15px;
  font-size: 1em;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  user-select: none;
  padding-left: 0;
}
.plus-sign {
  font-weight: bold;
  margin-right: 10px;
  font-size: 20px;
}
.widgets-area-list {
  display: flex;
}
.widgets-area-list .row {
  width: 100%;
}
.widget-item {
  background: #fff;
  margin: 10px 0 0;
  border: 1px solid #c3c4c7;
  padding: 0 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  color: #3c434a;
  position: relative;
}
.widget-title {
  margin: 0;
  padding: 8px 10px;
  overflow: hidden;
  white-space: normal;
  line-height: 1.5;
  color: #1d2327;
  font-size: 1.3em;
  padding: 15px 15px 15px 7px;
  cursor: pointer;
  position: relative;
}
.expander {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease;
  height: 400px;
  overflow-y: scroll;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  height: 0;
}
</style>
