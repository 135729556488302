<template>
  <div class="block-wrapper">
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="() => {}">
        <el-tab-pane label="Left column" name="leftcol">
          <p>Icon</p>
          <ImageSelectorInput
            v-model="blockContent.content.leftColumn.icon"
            :multiple="false"
            :featuredImageSlug="
              blockContent.content.leftColumn?.icon
                ? blockContent.content.leftColumn.icon
                : 'empty'
            "
            :returnSlug="true"
          ></ImageSelectorInput>
          <p class="mt-2">Description</p>
          <el-input
            v-model="blockContent.content.leftColumn.text"
            :rows="2"
            type="textarea"
            placeholder="Please enter description"
          />
        </el-tab-pane>
        <el-tab-pane label="Right column" name="rightcol">
          <p>Icon</p>
          <ImageSelectorInput
            v-model="blockContent.content.rightColumn.icon"
            :multiple="false"
            :featuredImageSlug="
              blockContent.content.rightColumn?.icon
                ? blockContent.content.rightColumn.icon
                : 'empty'
            "
            :returnSlug="true"
          ></ImageSelectorInput>
          <p class="mt-2">Description</p>
          <el-input
            v-model="blockContent.content.rightColumn.text"
            :rows="2"
            type="textarea"
            placeholder="Please enter descirption"
        />
      </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <el-button
    @click="deleteBlock(blockData.id)"
    class="mt-2"
    size="small"
    type="danger"
    >Delete this block</el-button
  >
</template>

<script>
import ImageSelectorInput from "../../Tiptap/ImageSelectorInput.vue";

export default {
  name: "TwoColumn",
  props: {
    blockData: Object,
  },
  components: {
    ImageSelectorInput,
  },
  data() {
    return {
      blockContent: {
        content: {
          leftColumn: {
            icon: "",
            text: "",
          },
          rightColumn: {
            icon: "",
            text: "",
          },
        },
      },
      activeName: "leftcol",
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_BLOCK_CONTENT") {
        this.$store.dispatch("updateBlockContent", {
          blockData: this.blockData,
          blockContent: this.blockContent,
        });
      }
    });
    if (this.blockData.created_at) {
      this.blockContent.content = this.blockData.content;
    }
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    deleteBlock(id) {
      this.$store.dispatch("removeBlockFromList", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.block-wrapper {
  border: #adb2ad solid 1px;
  padding: 16px 20px;
}
.input-bottom {
  padding-left: 8px;
  margin-top: 8px;
}
</style>
