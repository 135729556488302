<template>
  <div>
    <div v-for="size in sizes" :key="size.id">
      <input
        :disabled="waitOnSuccess"
        :checked="isChecked(size.id)"
        @change="handleChange(size.id, $event)"
        type="checkbox"
        :id="size.id"
        :name="size.id"
      />
      <label :for="size.id">{{ size.title }}</label>
    </div>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";

export default {
  name: "SizeTypes",
  props: {
    sizes: [],
    artwork: Object,
  },
  data() {
    return {
      selectedSizes: [],
      waitOnSuccess: false,
    };
  },
  watch: {
    artwork: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.size_type) {
          this.updateSelectedSizes(newValue.size_type);
        }
      },
    },
  },
  methods: {
    updateSelectedSizes(sizeTypeArray) {
      if (Array.isArray(sizeTypeArray)) {
        this.selectedSizes = sizeTypeArray.map((size) => size.id);
      }
    },
    async update() {
      this.waitOnSuccess = true;
      try {
        const url = `${process.env.VUE_APP_SHARED_API}api/artworks/${this.artwork.id}`;
        await this.$api.patch(url, { size_types: this.selectedSizes });
        // Optional: Add some success notification here
      } catch (error) {
        console.error("Update failed:", error); // Log error for debugging
        ElNotification({
          title: "Warning!",
          message: "Unable to update artwork size, please try again",
          type: "warning",
        });
      } finally {
        this.waitOnSuccess = false;
      }
    },
    isChecked(sizeId) {
      return this.selectedSizes.includes(sizeId);
    },
    async handleChange(sizeId, event) {
      if (this.waitOnSuccess) return;

      const isChecked = event.target.checked;
      if (isChecked) {
        if (!this.selectedSizes.includes(sizeId)) {
          this.selectedSizes.push(sizeId);
        }
      } else {
        this.selectedSizes = this.selectedSizes.filter((id) => id !== sizeId);
      }
      await this.update();
    },
  },
};
</script>
<style>
  input {
    margin-right: 5px;
  }
</style>
