import store from '../store'
export const validateForm = async (vuexStateName) => {
  // debugger
  let isPass = true;
  let fieldsToValid = await store.state.customFields[vuexStateName];
  for (const [key, value] of Object.entries(fieldsToValid)) {
    if (fieldsToValid.nonRequired.includes(key) === false && key !== 'principles') {
      // debugger
      if (Array.isArray(value) && value?.length < 1 && key !== "collectorTrends" && (key === "quantity" && value < 0)) {
        // debugger
        console.log(key)
        return isPass = false;
      } else if (value < 0 || value === "") {
        // debugger
        console.log(key)
        return isPass = false;
      } else if (Array.isArray(value) && value?.length > 0) {
        // debugger
        value.map(item => {
          if (key === "techniques" && item.techniqueId === "Please enter a keyword") {
            console.log(key)
            return isPass = false;
          }
          if (key === "collectorTrends" && item.price === 0 || item.date === "") {
            console.log(key)
            return isPass = false;
          }
        })
      }
    }
  }

  return isPass;
}
