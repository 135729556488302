<template>
  <div class="flex-container mb-4 mt-4">
    <div class="custom-col">
      Start date
      <el-date-picker
        v-model="startDate"
        type="date"
        placeholder="Pick a date"
        :disabled-date="disabledDate"
      />
    </div>
    <div class="custom-col">
      End date
      <el-date-picker
        :disabled="!startDate"
        :disabled-date="disabledDate"
        v-model="endDate"
        type="date"
        placeholder="Pick a date"
      />
    </div>
    <div class="custom-col btnclass" v-if="startDate && endDate">
      <el-button class="button_style_1 mt-3" @click="runSearch">
        Search
      </el-button>
    </div>
  </div>
  <div v-if="runChart">
    <div class="">
      <div class="row">
        <div class="col-sm-3">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-end">
                <div class="mdi mdi-account-multiple widget-icon">
                  <font-awesome-icon :icon="['fas', 'users']" class="" />
                </div>
              </div>
              <h5 class="text-muted fw-normal mt-0" title="Number of Customers">
                Users
              </h5>
              <h3 class="mt-3 mb-3">{{ numberOfCustomers }}</h3>
            </div>
            <!-- end card-body-->
          </div>
          <!-- end card-->
        </div>
        <!-- end col-->

        <div class="col-sm-3">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-end">
                <div class="mdi mdi-account-multiple widget-icon">
                  <font-awesome-icon :icon="['fas', 'cart-plus']" class="" />
                </div>
              </div>
              <h5 class="text-muted fw-normal mt-0" title="Number of Orders">
                Orders
              </h5>
              <h3 class="mt-3 mb-3">{{ numberOfOrders }}</h3>
            </div>
            <!-- end card-body-->
          </div>
          <!-- end card-->
        </div>
        <!-- end col-->

        <div class="col-sm-3">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-end">
                <div class="mdi mdi-account-multiple widget-icon">
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" class="" />
                </div>
              </div>
              <h5 class="text-muted fw-normal mt-0" title="Average Revenue">
                Revenue
              </h5>
              <h3 class="mt-3 mb-3">${{ totalAmount }}</h3>
            </div>
            <!-- end card-body-->
          </div>
          <!-- end card-->
        </div>
        <!-- end col-->

        <div class="col-sm-3">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-end">
                <div class="mdi mdi-account-multiple widget-icon">
                  <font-awesome-icon :icon="['fas', 'image']" class="" />
                </div>
              </div>
              <h5 class="text-muted fw-normal mt-0" title="Growth">Artworks</h5>
              <h3 class="mt-3 mb-3">{{ numberOfArtworks }}</h3>
            </div>
            <!-- end card-body-->
          </div>
          <!-- end card-->
        </div>
        <!-- end col-->
      </div>
      <!-- end row -->
    </div>
    <!-- end col -->

    <div class="row">
      <div class="col-12">
        <div class="card card-h-100">
          <div class="card-body pt-0">
            <div dir="ltr">
              <BarChart :chartData="chartData" :options="options" />
            </div>
          </div>
          <!-- end card-body-->
        </div>
        <!-- end card-->
      </div>
    </div>
  </div>
  <!-- end col -->
</template>
<script>
import { defineComponent, ref } from "vue";
import { BarChart } from "vue-chart-3";
import { getAdaptiveReport } from "@/services/statistics";
import moment from "moment";

export default defineComponent({
  name: "Home",
  components: { BarChart },
  setup() {
    const hexColors = [
      "#7A942E",
      "#8DB255",
      "#5B8930",
      "#6B9362",
      "#407A52",
      "#006442",
      "#87D37C",
      "#26A65B",
      "#26C281",
      "#049372",
      "#2ABB9B",
      "#16A085",
      "#36D7B7",
      "#03A678",
      "#4DAF7C",
      "#7A942E",
      "#8DB255",
      "#5B8930",
      "#6B9362",
      "#407A52",
      "#006442",
      "#87D37C",
      "#26A65B",
      "#26C281",
      "#049372",
      "#2ABB9B",
      "#16A085",
      "#36D7B7",
      "#03A678",
      "#4DAF7C",
    ];

    const startDate = ref("");
    const endDate = ref("");
    let finalHexColors = [];

    const calcDayDiff = () => {
      let dayDiff = moment(endDate.value).diff(moment(startDate.value), "days");
      let quotient = Math.floor(dayDiff / 30);
      let reminder = dayDiff % 30;
      let localArr = [];

      for (let i = 0; i < quotient; i++) {
        localArr.push(...hexColors);
      }
      for (let i = 0; i < reminder; i++) {
        localArr.push(hexColors[i]);
      }
      finalHexColors.push(...localArr);
    };

    const chartData = ref({
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ref(finalHexColors),
        },
      ],
    });

    const options = {
      responsive: true,
      plugins: {
        legend: false,
        title: {
          display: true,
          text: "Revenue by adaptive period",
        },
      },
    };

    const runChart = ref(false);

    const numberOfCustomers = ref(0);
    const numberOfOrders = ref(0);
    const numberOfArtworks = ref(0);
    const totalAmount = ref(0);

    const disabledDate = (time) => {
      return time.getTime() > Date.now();
    };

    const runSearch = () => {
      calcDayDiff();
      getAdaptiveReport(startDate, endDate).then((results) => {
        chartData.value.labels = Object.keys(results.chart_report);
        chartData.value.datasets[0].data = Object.values(results.chart_report);
        numberOfArtworks.value = results.number_of_artworks;
        totalAmount.value = results.total_cash;
        numberOfOrders.value = results.number_of_orders;
        numberOfCustomers.value = results.number_of_customers;
        runChart.value = true;
      });
    };

    return {
      chartData,
      options,
      numberOfCustomers,
      numberOfOrders,
      numberOfArtworks,
      totalAmount,
      runChart,
      startDate,
      endDate,
      disabledDate,
      runSearch,
    };
  },
});
</script>
<style scoped>
.pl-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
.flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  min-height: 100%;
}

.custom-col {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  flex-direction: column;
  display: flex;
  margin-left: 10px;
}
.btnclass {
  display: flex;
  justify-content: flex-end;
  min-height: 64px;
}
</style>
