<template>
  <div class="row">
    <div class="col">
      <label for="value"
        >Value Indicators
        <el-tooltip class="box-item" effect="dark" placement="top">
          <template #content>
            The Recognition Value indicator is linked to the artist of this
            artwork and is pre-filled by selecting them on the General tab.
            <br />
            The Composition Value Indicator is linked to the technique of this
            artwork and is pre-filled by selecting the first technique on the
            Techniques tab. <br />
            By deleting a Value Indicator or editing the content for it, you
            cannot pre-fill it again by selecting a different artist or
            technique.
          </template>
          <small
            style="
              border-radius: 50%;
              background: #1f3c2a;
              color: white;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            ><font-awesome-icon :icon="['fas', 'info']" class="cursor_pointer"
          /></small> </el-tooltip
      ></label>
      <div>
        <div v-if="valueIndicators.length > 0">
          <div v-for="(item, index) in valueIndicators" :key="index">
            <div class="row-wrapper p-2">
              <label for="value-indicators-icon">Icon </label>
              <br />
              <div class="relative-parent">
                <img
                  v-if="item.imageUrl"
                  class="mr-4"
                  width="30"
                  height="20"
                  :src="domainURI + '/' + item?.imageUrl"
                  :alt="item?.imageUrl.alt"
                />
                <el-select v-model="item.imageUrl" value-key="price" clearable>
                  <el-option
                    class="select"
                    v-for="icon in valueIndicatorsIcons"
                    :value="icon.slug"
                    :key="icon.id"
                  >
                    <div class="option-grid">
                      <img
                        width="30"
                        height="30"
                        :src="domainURI + '/' + icon.slug"
                        :alt="item.alt"
                      />
                    </div>
                  </el-option>
                </el-select>
              </div>
              <br />
              <label class="mt-2" for="title">Title </label>
              <el-input
                v-model="item.title"
                :disabled="true"
                :value="convertor(item.imageUrl, index)"
                id="title"
                placeholder="Title"
                class="mb-2"
              ></el-input>
              <div class="text-repeeater">
                <p class="p-0 m-0">Text list</p>
                <tiptap
                  :vimode="true"
                  style="height: 250px; overflow: scroll"
                  v-model="item.textList"
                />
              </div>
              <div
                class="delete-row"
                @click="
                  () => {
                    this.valueIndicators.splice(index, 1);
                  }
                "
              >
                <svg
                  class="svg-inline--fa fa-trash-alt fa-w-14"
                  data-v-b4ae8fbc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="trash-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <el-button
          class="button_style_1 mt-2"
          @click="
            () => {
              this.valueIndicators.push({
                id: this.valueIndicatorsCounter++,
                imageUrl: '',
                title: '',
                textList: '',
                isBinded: true,
              });
            }
          "
        >
          Add New Row
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElButton, ElInput } from "element-plus";
import Tiptap from "../../components/Tiptap/Tiptap.vue";

export default {
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
    },
  },
  components: {
    ElButton,
    ElInput,
    Tiptap,
  },
  data() {
    return {
      valueIndicators: [],
      valueIndicatorsCounter: 2,
      valueIndicatorsIcons: [],
      selectedIcon: "",
      isBinded: false,
      choosenArtist: false,
      choosenTechnique: false,
    };
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            valueIndicators: this.valueIndicators,
          },
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new"
      ) {
        this.valueIndicators = state.customFields.artworks.value_indicators;
      }
      if (
        mutation.type === "BIND_VALUE_INDICATORS" &&
        this.$route.query.mode == "new"
      ) {
        this.valueIndicators = state.customFields.artworks.valueIndicators;
      }
    });
  },
  mounted() {
    this.$api
      .get(`${process.env.VUE_APP_SHARED_API}api/media?per_page=50&type=icon`)
      .then((res) => {
        if (res.data.data) {
          this.valueIndicatorsIcons = res.data.data;
        }
      });
  },
  computed: {
    domainURI() {
      return process.env.VUE_APP_SHARED_API;
    },
  },
  methods: {
    convertor(slug, i) {
      if (slug === "media/icons/intent.png") {
        this.valueIndicators[i].title = "Intent";
        return "Intent";
      }
      if (slug === "media/icons/ceres-take.png") {
        this.valueIndicators[i].title = "Ceres Take";
        return "Ceres Take";
      }
      if (slug === "media/icons/recognition.png") {
        this.valueIndicators[i].title = "Recognition";
        return "Recognition";
      }
      if (slug === "media/icons/composition.png") {
        this.valueIndicators[i].title = "Composition";
        return "Composition";
      }
    },
  },
  watch: {
    $data: {
      handler: function (val, oldVal) {
        if (val.valueIndicators.length > 0) {
          val.valueIndicators.map((indicator, i) => {
            if (
              this.choosenArtist === false &&
              indicator?.title === "Recognition"
            ) {
              let va =
                this.$store.state.customFields.artworks.valueIndicators.filter(
                  (v) => v.title == "Recognition"
                );
              if (va[0] != undefined) {
                this.valueIndicators[i] = va[0];
                this.choosenArtist = true;
              }
            }
            // if (
            //   this.choosenTechnique === false &&
            //   indicator?.title === "Composition"
            // ) {
            //   let va =
            //     this.$store.state.customFields.artworks.valueIndicators.filter(
            //       (v) => v.title == "Composition"
            //     );
            //   if (va[0] != undefined) {
            //     this.valueIndicators[i] = va[0];
            //     this.choosenTechnique = true;
            //   }
            // }
          });
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
.option-grid {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.mr-4 {
  margin-right: 10px;
}
.relative-parent {
  position: relative;
  margin-bottom: 10px;
}
.relative-parent img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.relative-parent .el-select {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 10px;
  z-index: 1;
}
.relative-parent::before {
  left: 0;
  top: 0;
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1f3b2a;
  position: absolute;
  opacity: 0.3;
}
.relative-parent::after {
  left: 0;
  top: 0;
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGPSURBVEiJrdQ9axRRFMbxX8SXqCGoSaeFIBjtAinsUomp7GQLU4kgBBHxK1j4FQKJIFaWEQSNhSnsUgRZQUinSBRRsLBRSOJazBn2ZjKbcWb2gcO9c+be/3P3nDsLvZax4gAdjnEHnw9aWKKjOFe1KDfYwoWaBtN4V7XoUOH5BDoxlqkTUUs9fEwAPcyXrLuBbVk552W/oLIHRYOTuInjhTWdgKfxqIlBmYonz5//6xZVGRThxXwrg7QsZY293cagCg4TuINrSe4UHuAhZgYZDCpLmY5gPOZTso81781OsPSwi58Ru5HbTnJpfErgK1jHFXyNfc9wP+abI7Gpjn7hvOxKv8Rs8m4Rd+Pdd/ypyd6nGfyVnfYHTmMUq5F72gZ+Sb8s32Jcx+uYd3GmKfwivgRoGWNY029wF5PDgC/J/jCP4UVisNH09Gf1y7KIEXtr3sXbmN9rYvAkOXkOf2VvWcaw0AQO7wM2MQDeWpsBfIwPw4Yv6N/5PN4MC3494L9xC1dxWdaHoWgUzzFXd+M/J+Wea8xW6ssAAAAASUVORK5CYII=");
}
.box-item {
  margin-left: 20px;
}
.box-item {
  margin-top: 10px;
}
</style>
