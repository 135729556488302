<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Menus</div>
  </div>
  <div class="container">
    <router-view></router-view>
    <div class="manage-menus">
      Select a menu to edit:
      <el-select
        value-key="id"
        size="small"
        v-model="selectedMenu"
        placeholder="Select"
      >
        <el-option
          v-for="item in menus"
          :key="item.id"
          :label="item.name"
          :value="{ id: item.id, name: item.name }"
        />
      </el-select>
      or
      <span class="create-modal" @click="createMenu">create a new menu</span>.
      Do not forget to save your changes!
    </div>
    <menu-items
      @refreshMenu="refreshMenulist"
      @catchDeleteMethod="catchDeleteMethod"
      :menuData="{
        data: selectedMenu,
        menuItems: singleMenu,
      }"
      class="mt-5"
    ></menu-items>
  </div>
</template>

<script>
import MenuItems from "./MenuItems.vue";
import { getMenus, getMenu, createMenu } from "./services";
import { ElMessageBox, ElNotification } from "element-plus";

export default {
  name: "Menu",
  components: {
    MenuItems,
  },
  data() {
    return {
      selectedMenu: "",
      menus: [],
      singleMenu: {},
    };
  },
  methods: {
    createMenu() {
      ElMessageBox.prompt("Please enter menu name", "New menu", {
        confirmButtonText: "Create",
        cancelButtonText: "Cancel",
      }).then(({ value }) => {
        if (value) {
          const loader = this.$loading.show();
          createMenu(value)
            .then((res) => {
              this.selectedMenu = res.data;
              loader.hide();
              ElNotification({
                title: "Success!",
                message: "Menu created successfully.",
                type: "success",
              });
              this.fetchAllMenus();
            })
            .catch(() => {
              ElNotification({
                title: "Warning!",
                message: "Unable to create menu! Please try later.",
                type: "warning",
              });
              loader.hide();
            });
        }
      });
    },
    fetchMenu() {
      const loader = this.$loading.show();
      getMenu(this.selectedMenu?.id)
        .then((res) => {
          loader.hide();
          this.singleMenu = res.data || {};
        })
        .catch(() => {
          ElNotification({
            title: "Warning!",
            message: "Unable to fetch menus! Please try later.",
            type: "warning",
          });
          loader.hide();
        });
    },
    fetchAllMenus() {
      const loader = this.$loading.show();
      getMenus()
        .then((res) => {
          loader.hide();
          this.menus = res.data.data || [];
        })
        .catch(() => {
          ElNotification({
            title: "Warning!",
            message: "Unable to fetch menus! Please try later.",
            type: "warning",
          });
          loader.hide();
        });
    },
    catchDeleteMethod(value) {
      if (value) {
        this.selectedMenu = "";
        this.singleMenu = {};
        this.fetchAllMenus();
      }
    },
    refreshMenulist() {
      this.$store.dispatch("resetGroupMediaData");
      this.fetchAllMenus();
      this.singleMenu = {};
      this.fetchMenu();
    },
  },
  mounted() {
    this.fetchAllMenus();
  },
  watch: {
    selectedMenu(newV, oldV) {
      this.$store.dispatch("resetMenuData");
      if (newV) {
        this.fetchMenu();
      }
    },
  },
  beforeUnmount() {
    this.$store.dispatch("resetMenuData");
  }
};
</script>
<style lang="scss" scoped>
.manage-menus {
  margin-top: 23px;
  padding: 10px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #c3c4c7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
}
@media (max-width: 767px) {
  .manage-menus {
    font-size: 12px;
  }
}
.create-modal {
  color: #135e96;
  cursor: pointer;
  text-decoration: underline;
}
</style>
