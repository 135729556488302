<template>
  <div class="row">
    <div class="col">
      <label class="mb-2">Biography</label>
      <tiptap style="height: 250px; overflow: scroll" v-model="biography" />
    </div>
  </div>
</template>

<script>
import Tiptap from "../../components/Tiptap/Tiptap.vue";
export default {
  data() {
    return {
      biography: "",
    };
  },
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
      type: Object,
    },
  },
  components: {
    Tiptap,
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            biography: this.biography,
          },
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new" &&
        this.biography === ""
      ) {
        this.biography = state.customFields.artists.biography;
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style>
</style>
