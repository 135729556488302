<template>
  <div class="row">
    <div class="col">
      <label class="mr-1" for="value">Techniques</label>
      <el-tooltip class="box-item" effect="dark" placement="top">
        <template #content>
          When choosing the first technique, you will automatically pre-fill the
          Composition Value Indicator in the Value Indicators tab. <br />Once
          you choose the Artist for the first time, the Value Indicator won't
          change if you choose another artist.
        </template>
        <small
          style="
            border-radius: 50%;
            background: #1f3c2a;
            color: white;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          ><font-awesome-icon :icon="['fas', 'info']" class="cursor_pointer"
        /></small>
      </el-tooltip>
      <div>
        <div v-if="techniques.length > 0">
          <div
            class="center-me"
            v-for="(item, index) in techniques"
            :key="index"
          >
            <small
              class="row-wrapper p-2"
              v-if="externalData.find((x) => x.id === item.techniqueId)?.name"
            >
              {{
                externalData.find((x) => x.id === item.techniqueId).name
              }}</small
            >
            <small
              v-if="
                $route.query.mode === 'edit' &&
                techniquesListRawServer[index]?.name
              "
              class="p-2"
              >{{
                techniquesListRawServer[index] &&
                techniquesListRawServer[index].name
              }}</small
            >
            <div class="row-wrapper p-2">
              <el-select
                v-if="
                  !techniquesListRawServer[index]?.name &&
                  !externalData.find((x) => x.id === item.techniqueId)?.name
                "
                v-model="item.techniqueId"
                filterable
                remote
                reserve-keyword
                placeholder="Please enter a keyword"
                :remote-method="remoteMethod"
                :loading="loading"
                value-key="id"
              >
                <el-option
                  v-for="item in techniquesList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <div
                class="delete-row"
                @click="
                  () => {
                    removeFromState(item);
                    this.techniques.splice(index, 1);
                  }
                "
              >
                <svg
                  class="svg-inline--fa fa-trash-alt fa-w-14"
                  data-v-b4ae8fbc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="trash-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-row">
          <el-button
            class="button_style_1 mt-2"
            @click="
              () => {
                this.techniquesList = [];
                this.techniques.push({
                  id: this.techniquesCounter++,
                  techniqueId: 'Please enter a keyword',
                });
              }
            "
          >
            Add existing technique
          </el-button>
          <create-technique @externalCb="externalCreation" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElButton, ElSelect, ElOption } from "element-plus";
import CreateTechnique from "./components/CreateTechnique.vue";
export default {
  props: {
    postType: {
      type: String,
    },
  },
  components: {
    ElButton,
    ElSelect,
    ElOption,
    CreateTechnique,
  },
  data() {
    return {
      techniques: [],
      techniquesCounter: 0,
      techniquesList: [],
      techniquesListRaw: [],
      techniquesListRawServer: [],
      binded: false,
      externalData: [],
    };
  },
  watch: {
    $data: {
      handler: function (val, oldVal) {
        if (this.$route.query.mode === "new") {
          if (
            val.techniques[0] &&
            Number.isInteger(val.techniques[0].techniqueId) &&
            !this.binded
          ) {
            let val = this.techniquesListRaw.find(
              (x) => x.id === this.techniques[0].techniqueId
            );
            this.$store.dispatch("bindValueIndicators", {
              type: "artworks",
              index: 1,
              data: {
                title: val.value_indicator?.name,
                icon_url: val.value_indicator?.icon_url,
                textList: val.value_indicator?.content,
              },
            });
            this.binded = true;
          }
        }
        this.techniquesCounter = this.techniques.length;
      },
      deep: true,
    },
  },
  methods: {
    remoteMethod: function (query) {
      if (query) {
        this.loading = true;
        this.techniquesList = [];
        this.$api
          .get(
            `${
              process.env.VUE_APP_SHARED_API
            }api/techniques/child?search=${query.toLowerCase()}`
          )
          .then((res) => {
            if (res.data.data) {
              this.techniquesListRaw = []
              this.techniquesList = []
              res.data.data.map((artist) => {
                this.techniquesListRaw = res.data.data;
                this.techniquesList.push({
                  id: artist.id,
                  label: artist.name,
                  value: artist.id,
                });
              });
              this.loading = false;
            } else {
              this.loading = false;
            }
          });
      } else {
        this.techniquesList = [];
      }
    },
    removeFromState(item) {
      if (item.techniqueId) {
        this.$store.dispatch("removeFromTechniquesArr", item.techniqueId);
      }
    },
    externalCreation(data) {
      this.externalData.push(data);
      this.binded = true;
      this.techniquesListRaw = [];
      this.$store.dispatch("bindValueIndicators", {
        type: "artworks",
        index: 1,
        data: {
          title: data.value_indicator?.name,
          icon_url: data.value_indicator?.icon_url,
          textList: data.value_indicator?.content,
        },
      });
      this.techniques.push({
        id: this.techniquesCounter++,
        techniqueId: data.id,
      });
    },
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            techniques: this.techniques,
          },
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new"
      ) {
        if (this.techniquesListRawServer.length < 1)
          this.techniquesListRawServer = state.customFields.artworks.techniques;

        this.techniques.length < 1 &&
          state.customFields.artworks.techniques.map((te, i) => {
            this.techniques.push({
              id: i,
              techniqueId: te.id,
            });
          });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: 0;
  left: -20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
.center-me {
  justify-content: space-between;
  display: flex;
  // align-items: center;
  flex-direction: column;
  margin: 10px 0;
}
.center-me small {
  font-weight: bold;
}
.mr-1 {
  margin-right: 1px;
}
.custom-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
