import axios from "axios";
import slugify from "slugify";

export const createTechnique = async (name, token) => {
  let techniqueName = name;
  let techniqueSlug = slugify(techniqueName).toLocaleLowerCase();

  let formdata = new FormData();
  formdata.append("name", techniqueName);
  formdata.append("slug", techniqueSlug);
  formdata.append("value_indicator[name]", 'Composition');
  formdata.append("value_indicator[icon_url]", 'media/icons/composition.png');
  formdata.append("value_indicator[content][]", '');

  return await axios.post(`${process.env.VUE_APP_SHARED_API}api/techniques`, formdata, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
