<template>
  <div class="row">
    <div class="col">
      <label for="value">Artists</label>
      <div>
        <div v-if="artists.length > 0">
          <div v-for="(item, index) in artists" :key="index">
            <div class="row-wrapper p-2">
              <el-select
                v-model="item.artistId"
                filterable
                remote
                placeholder="Please enter a keyword"
                :remote-method="remoteMethod"
                :loading="loading"
                :value="item.name"
              >
                <el-option
                  v-for="item2 in artistsList"
                  :key="item2.value"
                  :label="item2.label"
                  :value="item2.value"
                />
              </el-select>
              <div
                class="delete-row"
                @click="
                  () => {
                    this.artists.splice(index, 1);
                  }
                "
              >
                <svg
                  class="svg-inline--fa fa-trash-alt fa-w-14"
                  data-v-b4ae8fbc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="trash-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <el-button
          class="button_style_1 mt-2"
          @click="
            () => {
              this.artists.push({
                id: this.artistsCounter++,
                artistId: 'Please enter a keyword',
              });
            }
          "
        >
          Add New Row
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElButton, ElSelect, ElOption } from "element-plus";
export default {
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
      type: Object,
    },
  },
  components: {
    ElButton,
    ElSelect,
    ElOption,
  },
  data() {
    return {
      artists: [],
      artistsCounter: 0,
      artistsList: [],
      artistsListRaw: [],
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.artists = this.artists.filter(
          (data) => data.artistId != "Please enter a keyword"
        );
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            artist_ids: this.artists,
          },
        });
      }
      if (mutation.type === "UPDATE_VALUE" && this.artists.length < 1) {
        state.customFields.techniques.artist_ids.map((id, i) => {
          this.artistsList.push({
            id: id.id,
            label: id.name,
            value: id.id,
          });
          this.artists.push({
            id: i,
            artistId: id.id,
            name: id.name,
          });
        });
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    remoteMethod: function (query) {
      if (query) {
        this.loading = true;
        this.artistsList = [];
        this.$api
          .get(
            `${
              process.env.VUE_APP_SHARED_API
            }api/artists?search=${query.toLowerCase()}`
          )
          .then((res) => {
            if (res.data.data) {
              res.data.data.map((artist) => {
                this.artistsListRaw = res.data.data;
                this.artistsList.push({
                  id: artist.id,
                  label: artist.name,
                  value: artist.id,
                });
              });
              this.loading = false;
            } else {
              this.loading = false;
            }
          });
      } else {
        this.artistsList = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  left: -20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
</style>
