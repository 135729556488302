<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Orders</div>
  </div>
  <div class="items">
    <div class="serach-wrapper">
      <el-input
        v-model="search"
        @keyup.enter="serachIt"
        size="small"
        placeholder="Type to search"
      />
      <el-button type="primary" @click="serachIt" size="small"
        >Search
      </el-button>
      <el-button type="primary" @click="create" size="small"
        >Add new
      </el-button>
      <el-button type="primary" class="hide-me" size="small"
        >Bulk upload
        <input
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="(e) => handleFileUpload(e)"
        />
      </el-button>
    </div>
    <div class="table" v-if="taxData.length">
      <div class="row mt-3 items_form_title">
        <div class="col-2">ZIP Code</div>
        <div class="col-3">Tax</div>
        <div class="col-1"></div>
        <div class="col-1"></div>
      </div>
      <div class="row item_list" v-if="taxData.length !== 0">
        <div
          class="row single_row_item"
          v-for="(item, index) in taxData"
          :key="index"
        >
          <div class="col-2">
            {{ item?.zip_code }}
          </div>
          <div class="col-3">
            {{ item?.tax }}
          </div>
          <div class="col-1">
            <el-button
              size="small"
              @click="
                () => {
                  this.editData.id = item.id;
                  this.editData.zip_code = item.zip_code;
                  this.editData.tax = item.tax;
                  this.update(item.id);
                }
              "
              >Edit</el-button
            >
          </div>
          <div class="col-1">
            <el-button
              type="danger"
              size="small"
              @click="() => this.delete(item.id)"
              >Delete</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <p class="mt-2" v-else>No data... Try to serach on the server.</p>
    <div v-if="havePagination">
      <el-pagination
        layout="prev, pager, next"
        :total="totalNum"
        :page-count="Math.ceil(totalNum / 20)"
        :current-page="pageNo"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { ElMessageBox, ElNotification } from "element-plus";

export default {
  name: "Tax",
  data() {
    return {
      search: "",
      taxData: [],
      isLoading: true,
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
      file: "",
      editData: {
        id: "",
        zip_code: "",
        tax: "",
      },
    };
  },
  mounted() {
    this.fetchData().then((res) => {
      if (res.data.data.length > 0) {
        this.taxData = res.data.data;
      }
      if (res.data.last_page > 1) {
        this.totalNum = res.data.total;
        this.havePagination = true;
      }
    });
  },
  methods: {
    async fetchData(pageNo) {
      const loader = this.$loading.show();
      return await this.$api
        .get(
          `${
            process.env.VUE_APP_SHARED_API
          }api/zip-codes?per_page=20&sort=DESC${
            pageNo ? `&page=${pageNo}` : ``
          }`
        )
        .then((res) => {
          loader.hide();
          this.taxData = res.data.data;
          return res;
        });
    },
    async serachIt(pageNo = 1) {
      if (this.search != "") {
        const loader = this.$loading.show();
        await this.$api
          .get(
            `${process.env.VUE_APP_SHARED_API}api/zip-codes?per_page=20&search=${this.search}&sort=DESC
          `
          )
          .then((res) => {
            loader.hide();
            if (res.data.data.length > 0) {
              this.taxData = res.data.data;
              this.search = "";
            }
            if (res.data.last_page > 1) {
              this.totalNum = res.data.total;
              this.havePagination = true;
            } else {
              this.totalNum = 0;
              this.havePagination = false;
            }
          });
      } else {
        const loader = this.$loading.show();
        await this.$api
          .get(
            `${process.env.VUE_APP_SHARED_API}api/zip-codes?per_page=20&search=${this.search}&sort=DESC
          `
          )
          .then((res) => {
            loader.hide();
            if (res.data.data.length > 0) {
              this.taxData = res.data.data;
              this.search = "";
            }
            if (res.data.last_page > 1) {
              this.totalNum = res.data.total;
              this.havePagination = true;
            }
          });
      }
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      const loader = this.$loading.show();
      this.fetchData(pageNo).then((res) => {
        if (res.data.data.length > 0) {
          this.taxData = res.data.data;
          loader.hide();
        }
      });
    },
    getErrorMessage(err) {
      let errorMessage = "Something went wrong, please try again!";
      if (err.response && err.response.data) {
        const errorData = err.response.data;
        if (errorData.error && errorData.errors) {
          const errorMessages = Object.values(errorData.errors).flat();
          errorMessage = errorMessages.join(", ");
        } else if (errorData.error) {
          errorMessage = errorData.error;
        }
      }
      return errorMessage;
    },
    async create() {
      const { value: formValues } = await ElMessageBox.prompt(
        "",
        "New tax item",
        {
          confirmButtonText: "Create",
          cancelButtonText: "Cancel",
          showInput: false,
          dangerouslyUseHTMLString: true,
          message: `
          <div>
            <div style="margin-bottom: 10px;">
              <label for="zipCode">Zip Code:</label>
              <input id="zipCode" type="text" class="el-input__inner" />
            </div>
            <div>
              <label for="tax">Tax:</label>
              <input id="tax" type="text" class="el-input__inner" />
            </div>
          </div>
        `,
        }
      );

      const zipCode = document.getElementById("zipCode").value;
      const tax = document.getElementById("tax").value;

      if (!zipCode || !tax) {
        ElNotification.error("Both fields are required");
        return;
      }

      if (zipCode && tax) {
        this.isLoading = true;
        this.$api
          .post(`${process.env.VUE_APP_SHARED_API}api/zip-codes`, {
            zip_code: zipCode,
            tax: Number(tax),
          })
          .then((res) => {
            this.fetchData();
            this.isLoading = false;
            ElNotification({
              type: "success",
              message: "Created successfully.",
            });
          })
          .catch((err) => {
            this.isLoading = false;
            const errorMessage = this.getErrorMessage(err);
            ElNotification({
              title: "Error!",
              message: errorMessage,
            });
          });
      }
    },
    async update(id) {
      const { value: formValues } = await ElMessageBox.prompt(
        "",
        "Update tax item",
        {
          confirmButtonText: "Update",
          cancelButtonText: "Cancel",
          showInput: false,
          dangerouslyUseHTMLString: true,
          message: `
          <div>
            <div style="margin-bottom: 10px;">
              <label for="zipCode">Zip Code:</label>
              <input style="opacity:0.5;" disabled id="zipCode" value="${this.editData.zip_code}" type="text" class="el-input__inner" />
            </div>
            <div>
              <label for="tax">Tax:</label>
              <input id="tax" value="${this.editData.tax}"  type="text" class="el-input__inner" />
            </div>
          </div>
        `,
        }
      );

      const zipCode = document.getElementById("zipCode").value;
      const tax = document.getElementById("tax").value;

      if (!zipCode || !tax) {
        ElNotification.error("Both fields are required");
        return;
      }

      if (zipCode && tax) {
        this.isLoading = true;
        this.$api
          .put(`${process.env.VUE_APP_SHARED_API}api/zip-codes/${id}`, {
            tax: Number(tax),
          })
          .then((res) => {
            this.fetchData();
            this.isLoading = false;
            ElNotification({
              type: "success",
              message: "Created successfully.",
            });
          })
          .catch((err) => {
            this.isLoading = false;
            const errorMessage = this.getErrorMessage(err);
            ElNotification({
              title: "Error!",
              message: errorMessage,
            });
          });
      }


    },
    async delete(id) {
      ElMessageBox.confirm("Are you sure you want to delete?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.isLoading = true;
        this.$api
          .delete(`${process.env.VUE_APP_SHARED_API}api/zip-codes/${id}`)
          .then((res) => {
            ElNotification({
              type: "success",
              message: "Delete completed",
            });
            this.fetchData();
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            const errorMessage = this.getErrorMessage(err);
            ElNotification({
              title: "Error!",
              message: errorMessage,
            });
          });
      });
    },
    handleFileUpload(e) {
      this.file = e.target.files[0];
      this.submitFile();
    },
    submitFile() {
      const loader = this.$loading.show();
      let formData = new FormData();
      formData.append("csv_file", this.file);
      this.$api
        .post(
          `${process.env.VUE_APP_SHARED_API}api/bulk-upload/zip-codes`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          ElNotification({
            title: "Success!",
            message: "Successfully uploaded!",
            type: "success",
          });
          this.fetchData();
        })
        .catch((err) => {
          loader.hide();
          const errorMessage = this.getErrorMessage(err);
          ElNotification({
            title: "Error!",
            message: errorMessage,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.serach-wrapper {
  display: flex;
  max-width: 450px;
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #338973;
  }
}

.users_list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 30px;
}

.user {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  width: 23.6034%;

  .user_image {
    text-align: center;
    padding-bottom: 30px;

    img {
      max-width: 150px;
    }
  }

  .user_content {
    h3 {
      font-size: 24px;
    }

    p {
      margin-bottom: 30px;
    }

    button {
      background-color: #338973;
      text-transform: uppercase;
      color: white;
      border: 1px solid #338973;
      transition: all 0.4s ease;
    }

    button:hover {
      background-color: #fff;
      color: #338973;
    }
  }
}

.user:nth-child(4) {
  margin-right: 0;
}

.languages_switcher {
  .languages_switcher__p {
    display: inline-block;
    margin: 0 5px;

    a {
      display: inline-block;
      background-color: transparent;
      color: #338973;
      border: 1px solid #338973;
      border-bottom: 0;
      text-align: center;
      padding-top: 5px;
      width: 50px;
      font-size: 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #338973;
  }
}

.items {
  padding-right: 30px;
  padding-left: 30px;

  .items_form_title {
    border-radius: 0px;
    background-color: #1f3c2a;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .item_list {
    padding: 5px 25px;
    border: 2px solid #1f3c2a;
    border-top: none;
    margin-top: -15px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #338973;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #338973;
          text-decoration: none !important;
        }
      }

      .add_or_edit_item_language {
        display: flex;

        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #338973;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item > * {
      padding: 0;
      overflow-x: auto;
    }
  }
}

.dialog_category_p {
  font-size: 18px;
}

.status {
  display: inline-flex;
  line-height: 2.5em;
  color: #777;
  background: #e5e5e5;
  border-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: -0.25em 0;
  cursor: inherit !important;
  white-space: nowrap;
  max-width: 100%;
}

.status > span {
  margin: 0 1em;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
}

.status-created {
  background: #c6e1c6;
  color: #5b841b;
}

.status-completed {
  background: #c8d7e1;
  color: #2e4453;
}

.refund.status {
  background: #d47676;
  color: #ffffff;
}

.hide-me {
  position: relative;
}

.hide-me input {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}
</style>
