<template>
  <div class="tip_tap_editor">
    <div v-if="editor">
      <menu-bar :vimode="vimode" class="editor__header" :editor="editor" />
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import MenuBar from "./MenuBar.vue";
import Image from "@tiptap/extension-image";
import TextAlign from "@tiptap/extension-text-align";

export default {
  components: {
    EditorContent,
    MenuBar,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    vimode: Boolean,
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [
        Image.configure({
          HTMLAttributes: {
            class: "my-custom-image",
          },
        }),
        StarterKit,
        Link,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
      ],
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());
      },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    addImage() {
      const url = window.prompt("URL");
      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run();
      }
    },
    setLink() {
      const url = window.prompt("URL");

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },

    addCommand(data) {
      // {id, udrl, alt, type
      if (data.command !== null) {
        data.command(data.data);
      }
    },
  },
  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(this.modelValue, false);
    },
  },
};
</script>

<style lang="scss" scoped>
/* Basic editor styles */
.ProseMirror {
  outline: none !important;

  > * + * {
    margin-top: 0.75em;
    padding: 20px;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
}

.content {
  padding: 1rem 0 0;

  h3 {
    margin: 1rem 0 0.5rem;
  }

  pre {
    border-radius: 5px;
    color: #333;
  }

  code {
    display: block;
    white-space: pre-wrap;
    font-size: 0.8rem;
    padding: 0.75rem 1rem;
    background-color: #e9ecef;
    color: #495057;
  }
}

.tip_tap_editor {
  border: 1px solid gray;
  border-radius: 5px;
}

.modal_popup {
  display: none;
  position: fixed;
  width: 60%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  background-color: white;
}
</style>
