<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Dashboard</div>
  </div>
  <div class="home">
    <button
      @click="changeReport('adaptive')"
      :class="'report_btn ' + (report === 'adaptive' ? 'active' : '')"
    >
      Range
    </button>
    <button
      @click="changeReport('weekly')"
      :class="'report_btn ' + (report === 'weekly' ? 'active' : '')"
    >
      Last 7 Days
    </button>
    <button
      @click="changeReport('monthly')"
      :class="'report_btn ' + (report === 'monthly' ? 'active' : '')"
    >
      Last 30 days
    </button>
    <button
      @click="changeReport('year')"
      :class="'report_btn ' + (report === 'year' ? 'active' : '')"
    >
      All time
    </button>
    <div v-if="report === 'monthly'">
      <MonthlyReport />
    </div>
    <div v-if="report === 'year'">
      <AllTimeReport />
    </div>
    <div v-if="report === 'adaptive'">
      <AdaptiveReport />
    </div>
    <div v-if="report === 'weekly'">
      <WeeklyReport />
    </div>
  </div>
</template>

<script>
import MonthlyReport from "../components/charts/MonthlyReport";
import AllTimeReport from "@/components/charts/AllTimeReport";
import AdaptiveReport from "../components/charts/AdaptiveReport.vue";
import WeeklyReport from "../components/charts/WeeklyReport.vue";
export default {
  name: "Home",
  components: { MonthlyReport, AllTimeReport, AdaptiveReport, WeeklyReport },
  data() {
    return {
      report: "monthly",
    };
  },
  methods: {
    onClickButton() {
      this.$router.push({
        name: "Posts",
      });
    },
    changeReport(data) {
      this.report = data;
    },
  },
};
</script>

<style lang="scss">
.home {
  text-align: center;
  .dashboard-logo {
    margin-bottom: 50px;
  }
}
.card {
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
  --bs-card-border-width: 0px;
}

.widget-icon {
  color: #1f3c2a;
  font-size: 20px;
  background-color: rgba(#1f3c2a, 0.25);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
}

.home {
  text-align: left;
}

.report_btn {
  margin: 10px;
  padding: 10px 30px;
  background: rgba(31, 60, 42, 0.5);
  font-size: 20px;
  text-transform: uppercase;
  border: none;
  color: #fff;
}

.report_btn.active {
  background: rgba(31, 60, 42, 1);
}
@media (max-width: 768px) {
  .report_btn {
    font-size: 15px;
  }
}
</style>
