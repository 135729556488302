<template>
  <div class="block-wrapper">
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="() => {}">
        <el-tab-pane label="Description" name="description" class="row">
          <div class="col-6">
            <el-input
              v-model="blockContent.content.headline"
              placeholder="Headline"
            />
          </div>
          <div class="col-6">
            <el-input
              v-model="blockContent.content.subHeadline"
              placeholder="Sub headline"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="Techniques" name="techniquesIds">
          <div class="col" v-if="blockContent.content.techniques.length > 0">
            <div
              v-for="(techniques, i) in blockContent.content.techniques"
              :key="techniques.id"
              class="relative-parent"
            >
              <div
                class="delete-row"
                style="z-index: 1"
                @click="
                  () => {
                    blockContent.content.techniques.splice(i, 1);
                  }
                "
              >
                <svg
                  class="svg-inline--fa fa-trash-alt fa-w-14"
                  data-v-b4ae8fbc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="trash-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                  ></path>
                </svg>
              </div>
              <el-select
                v-model="blockContent.content.techniques[i]"
                filterable
                remote
                reserve-keyword
                placeholder="Please enter a keyword"
                :remote-method="remoteMethod"
                :loading="loading"
                value-key="id"
              >
                <el-option
                  v-for="item in techniquesListCopy"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </div>
          </div>
          <el-button @click="addNewRow" class="mt-2" size="small" type="primary"
            >Add new technique</el-button
          >
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-button
      @click="deleteBlock(blockData.id)"
      class="mt-2"
      size="small"
      type="danger"
      >Delete this block</el-button
    >
  </div>
</template>

<script>
export default {
  name: "ExploreTechniquesArchive",
  props: {
    blockData: Object,
  },
  data() {
    return {
      listOfTechniques: [],
      techniquesListCopy: [],
      techniquesListRaw: [],
      loading: false,
      blockContent: {
        content: {
          headline: "",
          subHeadline: "",
          techniques: [],
        },
      },
      activeName: "description",
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_BLOCK_CONTENT") {
        this.$store.dispatch("updateBlockContent", {
          blockData: this.blockData,
          blockContent: this.blockContent,
        });
      }
    });
    if (this.blockData.created_at) {
      this.techniquesListCopy = this.blockData.content.techniques;
      this.blockContent.content = this.blockData.content;
    }
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    addNewRow() {
      this.blockContent.content.techniques.push("Please select");
    },
    deleteBlock(id) {
      this.$store.dispatch("removeBlockFromList", id);
    },
    remoteMethod: function (query) {
      if (query) {
        this.loading = true;
        this.techniquesListCopy = [];
        this.$api
          .get(
            `${
              process.env.VUE_APP_SHARED_API
            }api/techniques?search=${query.toLowerCase()}`
          )
          .then((res) => {
            if (res.data.data) {
              res.data.data.map((art) => {
                this.techniquesListRaw.push(art);
                this.techniquesListCopy.push(art);
              });
              this.loading = false;
            } else {
              this.loading = false;
            }
          });
      } else {
        this.techniquesListCopy = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.block-wrapper {
  border: #adb2ad solid 1px;
  padding: 16px 20px;
}
.input-bottom {
  padding-left: 8px;
  margin-top: 8px;
}
.delete-row {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.relative-parent {
  position: relative;
}
</style>
