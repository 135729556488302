export default {
  state: {
    PDFs: []
  },
  mutations: {
    SET_PDFS(state, payload) {
      state.PDFs = payload;
    },
    RESET_PDFS(state) {
      state.PDFs = [];
    },
    RESET_PDFID(state) {
      return state;
    }
  },
  actions: {
    setPDFs({ commit }, pdfs) {
      commit("SET_PDFS", pdfs)
    },
    resetPDFs({ commit }) {
      commit("RESET_PDFS")
    },
    resetPDFId({ commit }) {
      commit("RESET_PDFID")
    }
  }
}
