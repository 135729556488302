<template>
  <div class="row">
    <div class="col">
      <label class="mb-2">Principles</label>
      <el-tag class="ml-2" type="info"
        >Info: If you want to embed a video link, please use
        <b>[video-link="link of video"]</b> format inside editor bellow.</el-tag
      >
      <tiptap style="height: 250px; overflow: scroll" v-model="principles" />
    </div>
  </div>
</template>

<script>
import Tiptap from "../../components/Tiptap/Tiptap.vue";
export default {
  data() {
    return {
      principles: "",
    };
  },
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
      type: Object,
    },
  },
  components: {
    Tiptap,
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            principles: this.principles,
            nonRequired: [
              "nonRequired",
              "nonRequiredSub",
              "validateSubFields",
              "pdfData",
              "id",
              "valueIndicator",
              "valueIndicators",
              "iconUrl",
            ],
          },
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new"
      ) {
        this.principles = state.customFields.techniques.principles;
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style scoped>
.ml-2 {
  margin-left: 20px;
}
</style>
