<template>
  <el-dialog
    :close-on-click-modal="false"
    :show-close="false"
    v-model="dialog"
    custom-class="color-picker__custom"
  >
    <header class="tab-header media_tab_header">Add new color</header>
    <div class="content-wrapper">
      <div class="p-10">
        <span>Color name <span class="required-field">*</span></span> <br />
        <el-input
          v-model="title"
          placeholder="Color name"
          class="mb-4 p-sticky"
        ></el-input>
        <div class="palette">
          <span
            class="palette"
            :style="`border-bottom: 3px solid ${colors[0]}`"
          >
            <span>First color <span class="required-field">*</span></span>
            <el-color-picker
              style="margin-bottom: 5px"
              size="small"
              v-model="colors[0]"
            />
          </span>
          <span
            class="palette"
            :style="`border-bottom: 3px solid ${colors[1]}`"
          >
            <span>Secound color</span>
            <el-color-picker
              size="small"
              style="margin-bottom: 5px"
              v-model="colors[1]"
            />
          </span>
        </div>
      </div>
      <div class="btn-group">
        <el-button @click="saveColors" class="button_style_1">Save</el-button>
        <el-button class="button_style_2" @click="closeDialogInner">
          Close
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  emits: ["onClose", "onSave"],
  data() {
    return {
      dialog: true,
      colors: ["", ""],
      title: "",
    };
  },
  methods: {
    colorValidator() {
      if (
        this.colors[0] === "" ||
        this.colors[0] === null ||
        this.title === ""
      ) {
        return false;
      }
      return true;
    },
    saveColors() {
      const isValid = this.colorValidator();
      if (isValid) {
        this.$emit("onSave", {
          colors: this.colors,
          title: this.title,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Empty required field.",
          type: "error",
        });
      }
    },
    closeDialogInner() {
      this.$emit("onClose");
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mv-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.nft-repeater {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  margin-top: 20px;
}
.nft-item {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  width: 100%;
  position: relative;
}
.nft-item p {
  margin: 0;
}
.delete-row {
  position: absolute;
  top: 5px;
  right: -20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.p-10 {
  margin-bottom: 20px;
}
.custom-color-picker {
  max-width: 200px !important;
}
.btn-group {
  display: flex;
  justify-content: space-between;
}
.btn-group .button_style_1 {
  flex: 1;
}
.palette {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
</style>
