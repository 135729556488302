<template>
  <div class="row mb-5">
    <div class="col-12 col-md-12 col-lg-3">
      <h4>Add menu item(s)</h4>
      <sidebar
        :selected="localData.data ? localData.data.id : false"
        :items="menuItems"
      ></sidebar>
    </div>
    <div class="col-12 col-md-12 col-lg-7">
      <h4>Menu structure</h4>
      <div v-if="localData?.data?.id">
        <div class="menu-wrapper">
          <div class="nav-menu-header">
            <p>Menu Name</p>
            <div class="input-wrapper">
              <el-input
                v-model="localData.data.name"
                placeholder="Please enter"
              />
            </div>
          </div>
          <div v-if="localData.menuItems[0]" class="nav-menu-body">
            <draggable
              :list="localData.menuItems"
              class="list-group"
              ghost-class="ghost"
              item-key="id"
              @update="saveUpdatedOrder"
            >
              <template #item="{ element }">
                <el-collapse accordion>
                  <el-collapse-item
                    :title="element.name || element.title"
                    :name="element.id"
                  >
                    <div class="p-10">
                      <div v-if="element.item_properties !== null">
                        <div
                          v-if="
                            element.localType == 'single-item' ||
                            element.item_properties[0]?.menuType ==
                              'single-item'
                          "
                        >
                          <menu-item
                            @collectData="collectData"
                            :menuItem="element"
                          />
                        </div>
                        <div v-else>
                          <menu-items-group
                            @collectData="collectData"
                            :menuItem="element"
                          />
                        </div>
                      </div>
                      <div v-else>
                        <p>This menu item is not valid. We are sorry.</p>
                        <p>Please create new one.</p>
                      </div>
                      <el-button
                        class="button_style_1 mt-3"
                        v-if="element.action"
                        @click="() => removeLocally(element.id)"
                      >
                        Remove menu item
                      </el-button>
                      <el-button
                        class="button_style_1 mt-3"
                        v-else
                        @click="() => removeOnServer(element.id)"
                      >
                        Remove menu item
                      </el-button>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </template>
            </draggable>
          </div>
          <div class="nav-menu-footer">
            <span
              class="delete-menu"
              @click="() => deleteMenu(localData.data.id)"
              >Delete Menu</span
            >
            <el-button class="button_style_1" @click="saveMenuItems"
              >Save menu</el-button
            >
          </div>
        </div>
      </div>
      <p v-else>Please select or create a new menu.</p>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { deleteMenu } from "./services";
import { ElNotification } from "element-plus";
import Sidebar from "./components/Sidebar.vue";
import { saveMenuItems, updateMenu, removeMenuItems } from "./services";
import MenuItem from "./components/MenuItem.vue";
import MenuItemsGroup from "./components/MenuItemsGroup.vue";

export default {
  name: "menu-items",
  props: {
    menuData: Object,
  },
  components: {
    draggable,
    Sidebar,
    MenuItem,
    MenuItemsGroup,
  },
  data() {
    return {
      menuItems: [
        {
          id: 0,
          label: "Custom Links",
          endpoint: "external",
        },
        {
          id: 1,
          label: "Filters",
          endpoint: "filters",
        },
        {
          id: 2,
          label: "Pages",
          endpoint: "pages",
        },
      ],
      tagetTypes: [
        {
          id: 0,
          label: "Default",
          target: "",
        },
        {
          id: 1,
          label: "Blank",
          target: "_blank",
        },
      ],
      localData: {},
      removedIds: [],
      updatedTitle: false,
      initialTitle: "",
      filterType: "",
      searchCopy: [],
      loading: false,
    };
  },
  methods: {
    deleteMenu(id) {
      const loader = this.$loading.show();
      deleteMenu(id)
        .then((res) => {
          ElNotification({
            title: "Success!",
            message: res.data.message,
            type: "success",
          });
          this.$emit("catchDeleteMethod", true);
          loader.hide();
        })
        .catch(() => {
          ElNotification({
            title: "Warning!",
            message: "Unable to delete menu! Please try later.",
            type: "warning",
          });
          loader.hide();
        });
    },
    saveUpdatedOrder: function (e) {
      let newArr = this.localData.menuItems.map((block, i) => {
        return (block = {
          ...block,
          order: i,
        });
      });
      this.localData.menuItems = newArr;
    },
    removeLocally(id) {
      this.localData.menuItems = this.localData?.menuItems.filter(
        (it) => it.id !== id
      );
    },
    removeOnServer(id) {
      this.removedIds.push(id);
      this.removeLocally(id);
    },
    collectData(data) {
      if (data !== undefined) {
        if (data.megaMenu) {
          this.localData.menuItems = this.localData.menuItems.map((obj) => {
            if (obj.id == data.menuItemData.id) {
              obj.item_properties = [];
              obj.type = "";
              obj.name = "";
              obj.related_id = "";
              obj.name = data.menuItemData.name;
              obj.type = "filter";
              obj.related_id = 0;
              obj.filter_by = "none"
              data.megaMenu.map((d, i) => {
                obj.item_properties.push({
                  data: d.data,
                  blankTarget: d.blankTarget || false,
                  externalLink: d.externalLink || "",
                  type: d.type,
                  name: d.name,
                });
                if (
                  d.type !== "pages" ||
                  d.type !== "external" ||
                  d.type !== "techniques" ||
                  d.type !== "artworks" ||
                  d.type !== "artists"
                ) {
                  obj.item_properties[i] = {
                    ...obj.item_properties[i],
                    filter_by: d.type,
                  };
                }
              });
            }
            return obj;
          });
        } else {
          this.localData.menuItems = this.localData.menuItems.map((obj) => {
            if (obj.id == data.id) {
              obj.item_properties = [];
              obj.type = "";
              obj.name = "";
              obj.filter_by = "none";
              obj.related_id = "";
              if (data.type == "pages") {
                obj.type = data.type;
                obj.related_id = data.data?.id || 0;
                obj.name = data.name;
                obj.item_properties[0] = {
                  data: data.data,
                  blankTarget: data.blankTarget || false,
                  menuType: data.localType || "single-item",
                };
              } else if (data.type == "external") {
                obj.type = data.type;
                obj.related_id = 0;
                obj.name = data.name;
                obj.item_properties[0] = {
                  blankTarget: data.blankTarget || false,
                  externalLink: data.externalLink,
                  menuType: data.localType || "single-item",
                };
              } else if (
                data.type == "collector-items" ||
                data.type == "colors" ||
                data.type == "types" ||
                data.type == "filter"
              ) {
                obj.type = "filter";
                obj.filter_by = data.type;
                obj.related_id = 0;
                obj.name = data.name;
                obj.item_properties[0] = {
                  data: data.data,
                  blankTarget: data.blankTarget || false,
                  externalLink: data.externalLink,
                  menuType: data.localType || "single-item",
                };
              } else if (
                data.type == "artworks" ||
                data.type == "artists" ||
                data.type == "techniques"
              ) {
                obj.type = "pages";
                obj.related_id = data.data?.id || 0;
                obj.name = data.name;
                obj.item_properties[0] = {
                  data: data.data || {},
                  blankTarget: data.targetBlank || false,
                  externalLink: data.externalLink,
                  menuType: data.localType || "single-item",
                  type: data.type,
                };
              } else {
                obj.type = ""; // non supported type - returns error
                obj.related_id = 0;
                obj.name = data.name;
                obj.item_properties[0] = {
                  data: data.data || {},
                  blankTarget: data.targetBlank || false,
                  externalLink: data.externalLink,
                  menuType: data.localType || "single-item",
                };
              }
            }
            return obj;
          });
        }
      }
    },
    validateMegaMenu(megaMenu) {
      if (megaMenu.length < 1) {
        return {
          ready: false,
          reason: "Menu items are required",
        };
      }
      for (const menuItem of megaMenu) {
        if (menuItem.name === "" || !menuItem.name) {
          return {
            ready: false,
            reason: "Menu item name is required",
          };
        }
        if (
          (menuItem.type === "" && menuItem.filter_by == "") ||
          (!!menuItem.type && !menuItem.filter_by)
        ) {
          return {
            ready: false,
            reason: "Menu item type is required",
          };
        }
        if (
          menuItem.type === "external" &&
          menuItem?.externalLink === "" &&
          menuItem.type !== "external" &&
          !megaMenu?.data
        ) {
          return {
            ready: false,
            reason: "Menu item link is required",
          };
        }
      }
      return {
        ready: true,
        reason: "",
      };
    },
    validateMenuItems(menuItems) {
      for (const menuItem of menuItems) {
        if (menuItem.localType !== "menu-group") {
          if (menuItem.type === "" && menuItem.filter_by == "none") {
            return {
              ready: false,
              reason: "Menu item type is required",
            };
          }
          if (
            menuItem.type === "external" &&
            (menuItem.item_properties[0].externalLink == "" ||
              !menuItem.item_properties[0].externalLink)
          ) {
            return {
              ready: false,
              reason: "Menu item link is required",
            };
          }
          if (
            menuItem.type !== "external" &&
            !menuItem.item_properties[0].data
          ) {
            return {
              ready: false,
              reason: "Menu item link is required",
            };
          }
        }
        if (
          menuItem.localType == "menu-group" ||
          (menuItem.localType == "menu-group" && menuItem.type === "filter")
        ) {
          const payload = this.validateMegaMenu(menuItem.item_properties);
          return payload;
        }
        let isValid = true;
        if (menuItem.type === "filter") {
          menuItem.item_properties.map((item) => {
            if (item.type !== "external" && item.data?.id == undefined) {
              isValid = false;
            }
          });
        }
        if (!isValid) {
          return {
            ready: false,
            reason: "Menu item link is required",
          };
        }
      }
      return {
        ready: true,
        reason: "",
      };
    },
    async validateMenu() {
      this.$store.dispatch("collectGroupMenuData");
      this.$store.dispatch("collectMenuData");
      this.$store.dispatch("resetMenuItems");
      this.collectData();

      const menuItems = this.localData.menuItems;
      if (menuItems.length < 1) {
        return {
          ready: false,
          reason: "Menu item(s) required!",
        };
      }
      let ignoreRemoved = menuItems.filter(
        (item) => !this.removedIds.includes(item.id)
      );
      const payload = this.validateMenuItems(ignoreRemoved);
      return payload;
    },
    async saveMenuItems() {
      let isMenuValid = await this.validateMenu();
      if (isMenuValid.ready) {
        const loader = this.$loading.show();
        saveMenuItems(this.localData.data.id, this.localData.menuItems)
          .then((res) => {
            this.removedIds = [];
            this.$emit("refreshMenu");
            loader.hide();
            ElNotification({
              title: "Success!",
              message: res.data.message,
              type: "success",
            });
          })
          .catch((e) => {
            loader.hide();
            ElNotification({
              title: "Oops!",
              message: "Something went wrong, please try later",
              type: "error",
            });
          });
        if (this.updatedTitle) {
          updateMenu(
            this.localData.data.id,
            this.localData.data.name,
            this.$cookies.get("bearerToken")
          ).then((res) => {
            if (!this.localData.menuItems[0]) {
              ElNotification({
                title: "Success!",
                message: res.data.message,
                type: "success",
              });
            }
            this.$emit("refreshMenu");
          });
        }
        if (this.removedIds[0]) {
          removeMenuItems(this.removedIds).then((res) => {
            loader.hide();
          });
        }
      } else {
        ElNotification({
          title: "Oops!",
          message: isMenuValid.reason,
          type: "error",
        });
      }
    },
    searchOnServer(query) {
      if (query) {
        this.loading = true;
        this.searchCopy = [];
        this.$api
          .get(
            `${process.env.VUE_APP_SHARED_API}api/${this.filterType
              .toLocaleLowerCase()
              .replace(/ /g, "-")}?search=${query.toLowerCase()}`
          )
          .then((res) => {
            if (res.data.data) {
              this.searchCopy = [];
              res.data.data.map((item) => {
                this.searchCopy.push(item);
              });
              this.loading = false;
            } else {
              this.loading = false;
            }
          });
      } else {
        this.searchCopy = [];
      }
    },
  },
  watch: {
    menuData(newV, oldV) {
      if (newV) this.localData = { ...this.menuData };
      if (newV) this.initialTitle = this.localData.data.name;

      if (newV)
        this.localData.menuItems[0] &&
          this.localData.menuItems.map((item) => {
            item?.item_properties?.length > 0 &&
              item.item_properties.map((i) => {
                i.id && this.searchCopy.push(i.id);
              });
          });
    },
    "localData.data.name"(newV, oldV) {
      if (newV !== this.initialTitle) {
        this.updatedTitle = true;
      } else {
        this.updatedTitle = false;
      }
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "ADD_MENU_ITEMS") {
        const returnLastOrder = (menuItems) => {
          return menuItems[0]
            ? parseInt(menuItems[menuItems.length - 1].order) + 1
            : 0;
        };
        const returnLastId = (menuItems) => {
          let largerId = [];
          let lastRemoved = this.removedIds.sort(function (a, b) {
            return a - b;
          });
          menuItems.map((w) => {
            if (lastRemoved[lastRemoved.length - 1] > parseInt(w.id)) {
              largerId.push(lastRemoved[lastRemoved.length - 1]);
            } else {
              largerId.push(parseInt(w.id));
            }
          });

          let sorted = largerId.sort(function (a, b) {
            return a - b;
          });

          let id = 0;
          if (sorted.length > 0) {
            id = parseInt(sorted[sorted.length - 1]) + 1;
          }

          return id;
        };

        state.menuEmits.menusItems &&
          this.localData?.menuItems.push({
            id: returnLastId(this.localData.menuItems),
            action: "new_item",
            name:
              state.menuEmits.menusItems == "single-item"
                ? "Menu item"
                : "Menu Items",
            localType: state.menuEmits.menusItems,
            type: "",
            order: returnLastOrder(this.localData.menuItems),
            related_id: "",
            item_properties: [],
            filter_by: "none",
          });
        this.$store.dispatch("resetMenuItems");
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.mr-3 {
  margin-right: 10px;
}
.col-aligmen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.save-menu {
  background: #2271b1;
  border-color: #2271b1;
  color: #fff;
  text-decoration: none;
  text-shadow: none;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  -webkit-appearance: none;
  border-radius: 3px;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 5px 10px;
}
.row h4 {
  color: #1d2327;
  font-size: 1.3em;
  margin: 1em 0;
  display: bloc;
  font-weight: 600;
}
.tabs {
  border: 1px solid #c3c4c7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
}
.menu-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #c3c4c7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
}
.nav-menu-header {
  border-bottom: 1px solid #dcdcde;
  margin-bottom: 0;
  padding: 10px;
  background: #f6f7f7;
  line-height: 1.4em;
  display: flex;
  align-items: center;
}
.nav-menu-header p {
  margin: 0;
  margin-right: 10px;
  font-size: 14px;
}
.nav-menu-header .input-wrapper {
  width: 270px;
}
.nav-menu-body {
  padding: 10px;
}
.nav-menu-footer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #c3c4c7;
  background: #f6f7f7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  align-items: center;
  z-index: 1;
}
.delete-menu {
  color: #b32d2e;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}
.list-group-item {
  border: 1px solid #dcdcde;
  position: relative;
  padding: 10px 15px;
  height: auto;
  min-height: 20px;
  max-width: 382px;
  line-height: 2.3076923;
  overflow: hidden;
  word-wrap: break-word;
  background: #f6f7f7;
  color: #1d2327;
  cursor: pointer;
  border-radius: 0 !important;
}
.p-10 {
  padding: 10px !important;
}
.filter-wrapper {
  padding: 5px;
  border: 1px solid #dcdcde;
  margin-top: 5px;
}
.delete-row {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.relative-parent {
  position: relative;
}
.mb-5 {
  margin-bottom: 10px;
}
</style>
