import { ElNotification } from "element-plus";
import store from "../store";
import { tagsConvertor } from "../helpers/tagsConvertor";
import api from "./api";

export const updatePost = async (
  postType,
  postData,
  customTaxs,
  token,
  postId,
  collectorItems
) => {
  let data = store.state.customFields[postType];

  if (postData && data) {
    if (postType === "techniques") {
      let artistsIds = [];
      data.artist_ids.map((item) => {
        artistsIds.push(item.artistId);
      });

      let content = [];
      // data.valueIndicator.valueIndicators && data.valueIndicator.valueIndicators.map(v => content.push(v.content))
      data.valueIndicator.valueIndicators !== "" &&
        content.push(data.valueIndicator.valueIndicators);

      let modifyValueKey = {
        name: data.valueIndicator.name,
        icon_url: data.valueIndicator.iconUrl,
        content: content,
      };

      let body = {
        name: postData.title || "",
        slug: postData.slug || "",
        text: postData.content || "",
        value_indicator: modifyValueKey,
        principles: data.principles,
        artist_ids: artistsIds.toString().replace("[", "").replace("]", ""),
        // pdf_id: postData.pdfId || '',
        featured_image_id: postData.featuredImage ? postData.featuredImage : "",
        featured_image: postData.featuredImage,
        status_id: postData.status == "publish" ? 2 : postData.status,
      };

      if (postData.pdfId > 0) {
        body = {
          ...body,
          pdf_id: postData.pdfId,
        };
      }

      return await api.put(
        `${process.env.VUE_APP_SHARED_API}api/techniques/${postId}`,
        body
      );
    } else if (postType === "artists") {
      let content = [];
      data.valueIndicator.valueIndicators !== "" &&
        content.push(data.valueIndicator.valueIndicators);

      let modifyValueKey = {
        name: data.valueIndicator.name,
        icon_url: data.valueIndicator.iconUrl,
        content: content,
      };

      let body = {
        name: postData.title || "",
        slug: postData.slug || "",
        about: postData.content || "",
        biography: data.biography || "",
        awards: JSON.stringify(data.awards),
        exhibitions: JSON.stringify(data.exhibitions),
        value_indicator: modifyValueKey,
        status_id: postData.status == "publish" ? 2 : postData.status,
      };
      if (data.died) {
        body = {
          ...body,
          died: data.died,
        };
      }

      if (data.born) {
        body = {
          ...body,
          born: data.born,
        };
      }

      if (data.short_description) {
        body = {
          ...body,
          short_description: data.short_description,
        };
      }

      if (postData.featuredImage !== 0) {
        body = {
          ...body,
          image_id: postData.featuredImage,
        };
      }

      return await api.put(
        `${process.env.VUE_APP_SHARED_API}api/artists/${postId}`,
        body
      );
    } else {
      let myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("title", postData.title);
      urlencoded.append("slug", postData.slug);
      urlencoded.append("artist_id", data.artistId);
      urlencoded.append("price", data.price);
      urlencoded.append("size_width", data.sizeWidth);
      urlencoded.append("size_height", data.sizeHeight);
      urlencoded.append("year", data.year);
      urlencoded.append("year_end", data.year_end);
      urlencoded.append("quantity", data.quantity);
      urlencoded.append("meta_title", postData.meta_title);
      urlencoded.append("meta_description", postData.meta_description);
      postData.featuredImage !== 0 &&
        urlencoded.append("featured_image", postData.featuredImage);
      postData.featuredImage !== 0 &&
        urlencoded.append("featured_image_id", postData.featuredImage);

      let status = 1;
      if (postData.status == "publish") {
        status = 1;
      } else if (postData.status == "draft") {
        status = 0;
      } else if (postData.status == "0" || postData.status == "1") {
        status = postData.status;
      }

      urlencoded.append("status", status);

      data.collectorTrendsTitle &&
        urlencoded.append("collector_trends_title", data.collectorTrendsTitle);

      data.gallery.map((galleryId) => {
        urlencoded.append("gallery[]", galleryId.id);
      });
      urlencoded.append(
        "value_indicators",
        JSON.stringify(data.valueIndicators)
      );
      urlencoded.append("overview", postData.content);
      data.techniques.map((techniques) => {
        urlencoded.append("technique_ids[]", techniques.techniqueId);
      });

      collectorItems &&
        collectorItems.map((item) => {
          urlencoded.append("collector_items[]", item);
        });

      function replaceWithUnderscore(name) {
        return name.replace(/-/g, "_");
      }

      customTaxs.map((tax) => {
        if (tax.taxs.length > 0) {
          tax.taxs[0]
            ? tax.taxs.map((t) => {
                urlencoded.append(`${replaceWithUnderscore(tax.name)}[]`, t);
              })
            : [tax.taxs].map((t) => {
                urlencoded.append(`${replaceWithUnderscore(tax.name)}[]`, t); // zbog radio button-a je ovo slucaj
              });
        }
      });

      let tags = await tagsConvertor(
        postData.choosenTags,
        postData.primaryTagId
      );
      if (tags.length > 0) {
        const key = "id";

        const arrayUniqueByKey = [
          ...new Map(tags.map((item) => [item[key], item])).values(),
        ];

        urlencoded.append("tags", JSON.stringify(arrayUniqueByKey));
      } else {
        urlencoded.append("tags", JSON.stringify([]));
      }

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
      };
      return await fetch(
        `${process.env.VUE_APP_SHARED_API}api/artworks/${postId}`,
        requestOptions
      );
    }
  } else {
    ElNotification({
      title: "Oops!",
      message: "Something went wrong, please try later",
      type: "warning",
    });
  }
};
