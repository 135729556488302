import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/user/Register";
import ItemsList from "../views/item/ItemsList";
import Login from "../views/user/Login";
import app from "../main";
import NewItem from "../views/item/NewItem";
import Users from "../views/user/Users";
import NewUser from "../views/user/NewUser";
import Menu from "../views/menu/Menu";
import MenuItems from "../views/menu/MenuItems";
import NItemsList from "../views/newItem/NItemsList";
import EditItem from "../views/blockeditor/EditItem.vue";
import Trash from "../views/trash/Trash.vue";
import Widgets from "../views/widgets/Widgets.vue";
import Orders from "../views/orders/Orders.vue";
import EditOrder from "../views/orders/Edit.vue";
import CategoryExport from "../views/exports/Category.vue";
import CustomerExport from "../views/exports/Customers.vue";
import Tax from "../views/tax/Tax.vue";
import Deactivated from "../views/user/Deactivated.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/widgets",
    name: "Widgets",
    component: Widgets,
  },
  {
    path: "/posts",
    name: "Posts",
    component: ItemsList,
  },
  {
    path: "/pages",
    name: "Pages",
    component: ItemsList,
  },
  {
    path: "/artworks",
    name: "Artworks",
    component: NItemsList,
  },
  {
    path: "/artists",
    name: "Artists",
    component: NItemsList,
  },
  {
    path: "/techniques",
    name: "Techniques",
    component: NItemsList,
  },
  {
    path: "/pages/trash",
    name: "Pages trash",
    component: ItemsList,
  },
  {
    path: "/posts/trash",
    name: "Posts trash",
    component: ItemsList,
  },
  {
    path: "/categories",
    name: "Categories",
    component: ItemsList,
  },
  {
    path: "/tags",
    name: "Tags",
    component: ItemsList,
  },
  {
    path: "/category/:type",
    name: "CustomCat",
    component: ItemsList,
  },
  {
    path: "/item/:type",
    name: "New Item",
    component: NewItem,
    children: [
      {
        path: ":id",
        name: "edit_item",
        component: NewItem,
        props: true,
      },
    ],
  },
  {
    path: "/trash/:type",
    name: "Trash",
    component: Trash,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/deactivated-users",
    name: "Deactivated",
    component: Deactivated,
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
    children: [
      {
        path: ":id",
        name: "menu-items",
        component: MenuItems,
      },
    ],
  },
  {
    path: "/user",
    name: "User",
    component: NewUser,
    children: [
      {
        path: ":id",
        component: NewUser,
      },
    ],
  },
  {
    path: "/block-editor/:id",
    name: "edit_item_block",
    component: EditItem,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/tax",
    name: "Tax",
    component: Tax,
  },
  {
    path: "/edit_order/:id",
    name: "edit_order",
    component: EditOrder,
  },
  {
    path: "/export/ids",
    name: "CategoryExport",
    component: CategoryExport,
  },
  {
    path: "/export/users",
    name: "CustomerExport",
    component: CustomerExport,
  },
  {
    path: "/nfts",
    name: "NFTs",
    component: NItemsList,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "Login" &&
    !app.config.globalProperties.$cookies.get("bearerToken")
  )
    next({ name: "Login" });
  if (
    to.name === "Login" &&
    app.config.globalProperties.$cookies.get("bearerToken")
  )
    next({ name: "Dashboard" });
  else next();
});

export default router;
