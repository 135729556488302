<template>
  <div class="row">
    <div class="col">
      <label>Summary </label>
      <el-input
        v-model="short_description"
        type="text"
        required
        placeholder="Summary"
        class="mb-2"
      ></el-input>
    </div>
  </div>
  <el-tag type="info"
    >This field will not be visible on this single page, it is used on other
    pages, such as: artworks</el-tag
  >
</template>

<script>
import { ElInput, ElTag } from "element-plus";
export default {
  data() {
    return {
      short_description: "",
    };
  },
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
      type: Object,
    },
  },
  components: {
    ElTag,
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            short_description: this.short_description,
            nonRequired: [
              "nonRequired",
              "valueIndicatorsCounter",
              "validateSubFields",
              "died",
              "valueIndicator",
              "awards",
              "exhibitions",
              "title",
              "year",
            ],
          },
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new" &&
        this.short_description === ""
      ) {
        this.short_description = state.customFields.artists.short_description;
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style>
.required-field {
  color: red;
  font-weight: bold;
}
</style>
