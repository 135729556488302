<template>
  <el-dialog
    custom-class="color-picker__custom"
    :close-on-click-modal="false"
    :show-close="false"
    v-model="dialog"
  >
    <header class="tab-header media_tab_header">Something went wrong.</header>
    <div class="content-wrapper">
      <div class="p-10">
        <p>
          You received an error that was not <br />handled by the system. <br />
          Our recommendation is to completely <br />
          refresh the application.
        </p>
        <br />
        <p>We are sorry about that.</p>
      </div>
      <div class="btn-group">
        <el-button @click="refreshApp" class="button_style_1"
          >Refresh app</el-button
        >
        <el-button
          @click="
            () => {
              dialog = false;
            }
          "
          class="button_style_1"
          >Close</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
// custom-class="color-picker__custom"
export default {
  mounted() {},
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    refreshApp() {
      window.location.reload();
    },
  },
};
</script>

<style>
</style>
