<template>
  <div class="row mb-2">
    <div
      class="col-12 col-sm-6"
      style="display: flex; column-gap: 16px; align-items: center"
    >
      <label for="artist">Artist <span class="required-field">*</span></label>
      <small v-if="$route.query.mode === 'edit'" class="p-2">{{
        artistData.name
      }}</small>
      <el-button
        v-if="artistData.name"
        @click="newArtist"
        size="small"
        type="primary"
        ><font-awesome-icon :icon="['fas', 'pen']" class="cursor_pointer"
      /></el-button>
      <el-tooltip class="box-item" effect="dark" placement="top">
        <template #content>
          When choosing an artist, you will automatically pre-fill the
          Recognition Value Indicator in the Value Indicators tab. <br />Once
          you choose the Artist for the first time, the Value Indicator won't
          change if you choose another artist.
        </template>
        <small
          style="
            border-radius: 50%;
            background: #1f3c2a;
            color: white;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          ><font-awesome-icon :icon="['fas', 'info']" class="cursor_pointer"
        /></small>
      </el-tooltip>
      <el-select
        v-if="!artistData.name"
        v-model="artistId"
        filterable
        remote
        reserve-keyword
        placeholder="Please enter a keyword"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <el-option
          v-for="item in artistListCopy"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-3">
      <label for="price">Price <span class="required-field">*</span></label>
      <el-input
        @keypress="isNumber($event, true)"
        v-model="price"
        type="text"
        min="0"
        required
        id="price"
        placeholder="Price"
        class="mb-2"
        @paste="(e) => e.preventDefault()"
      ></el-input>
    </div>
    <div class="col-12 col-sm-3">
      <label for="size-h"
        >Size Height <span class="required-field">*</span></label
      >
      <el-input
        v-model="sizeHeight"
        @keypress="isNumber($event)"
        required
        id="size-h"
        placeholder="Size Height"
        class="mb-2"
        type="text"
        @paste="(e) => e.preventDefault()"
        min="0"
      ></el-input>
    </div>
    <div class="col-12 col-sm-3">
      <label for="size-w"
        >Size Width <span class="required-field">*</span></label
      >
      <el-input
        v-model="sizeWidth"
        @keypress="isNumber($event)"
        required
        id="size-w"
        placeholder="Size Width"
        class="mb-2"
        type="text"
        min="0"
        @paste="(e) => e.preventDefault()"
      ></el-input>
    </div>
    <div class="col">
      <label for="size-h">Quantity</label>
      <el-input
        v-model="quantity"
        @keypress="isNumber($event)"
        required
        id="size-h"
        placeholder="Quantity"
        class="mb-2"
        type="text"
        @paste="(e) => e.preventDefault()"
        min="0"
      ></el-input>
    </div>
    <div class="col-3">
      <label for="price">Year start</label>
      <el-input
        @keypress="isNumber($event, true)"
        v-model="year"
        type="text"
        min="0"
        required
        id="price"
        placeholder="Year start"
        class="mb-2"
        @paste="(e) => e.preventDefault()"
      ></el-input>
    </div>
    <div class="col-3">
      <label for="price">Year end</label>
      <el-input
        @keypress="isNumber($event, true)"
        v-model="year_end"
        type="text"
        min="0"
        required
        id="price"
        placeholder="Year end"
        class="mb-2"
        @paste="(e) => e.preventDefault()"
      ></el-input>
    </div>
  </div>
</template>

<script>
import { ElSelect, ElOption, ElInput } from "element-plus";

export default {
  components: {
    ElSelect,
    ElOption,
    ElInput,
  },
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
      type: Object,
    },
  },
  methods: {
    isNumber: function (evt, disableDot) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46) ||
        (disableDot && charCode == 46)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    remoteMethod: function (query) {
      if (query) {
        this.loading = true;
        this.artistListCopy = [];
        this.$api
          .get(
            `${
              process.env.VUE_APP_SHARED_API
            }api/artists?search=${query.toLowerCase()}`
          )
          .then((res) => {
            if (res.data.data) {
              res.data.data.map((artist) => {
                this.artistListRaw = res.data.data;
                this.artistListCopy.push({
                  id: artist.id,
                  label: artist.name,
                  value: artist.id,
                });
              });
              this.loading = false;
            } else {
              this.loading = false;
            }
          });
      } else {
        this.artistListCopy = [];
      }
    },
    newArtist() {
      (this.artistId = "Please enter a keyword"), (this.artistData.name = "");
    },
  },
  data() {
    return {
      loading: false,
      artistListCopy: [],
      artistListRaw: [],
      artistId: "Please enter a keyword",
      price: 0,
      sizeWidth: 0,
      sizeHeight: 0,
      artistData: {},
      quantity: 0,
      year: "",
      year_end: ""
    };
  },
  watch: {
    artistId: function (newVal, oldVal) {
      if (this.$route.query.mode !== "edit") {
        let val = this.artistListRaw.find((x) => x.id === newVal);
        this.$store.dispatch("bindValueIndicators", {
          type: "artworks",
          index: 0,
          data: {
            title: val.value_indicator?.name,
            icon_url: val.value_indicator?.icon_url,
            textList: val.value_indicator?.content,
          },
        });
      }
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            artistId:
              this.artistId === "Please enter a keyword" ? 0 : this.artistId,
            price: this.price,
            sizeWidth: this.sizeWidth,
            sizeHeight: this.sizeHeight,
            quantity: this.quantity,
            year: this.year,
            year_end: this.year_end
          },
          nonRequired: [
            "nonRequired",
            "valueIndicatorsCounter",
            "nonRequiredSub",
            "validateSubFields",
            "id",
            "featured_image_id",
            "tags",
            "link",
            "collectorTrendsDeletedIds",
            // "title",
            // "description",

            // taxonomies!
            "taxCollectorId",
            "colors",
            "types",
            "gallery",
            "collectorTrends",
            "valueIndicators",
            "collector_items",
            "quantity",
            "colors",
          ],
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new"
      ) {
        this.price = state.customFields.artworks.price;
        this.sizeWidth = state.customFields.artworks.sizeWidth;
        this.sizeHeight = state.customFields.artworks.sizeHeight;
        this.artistData = state.customFields.artworks.artistData;
        this.artistId = state.customFields.artworks.artistData.id;
        this.quantity = state.customFields.artworks.quantity;
        this.year = state.customFields.artworks.year;
        this.year_end = state.customFields.artworks.year_end;
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.required-field {
  color: red;
  font-weight: bold;
}
</style>
