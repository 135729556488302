<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Orders</div>
  </div>
  <div class="items">
    <div class="serach-wrapper">
      <el-input
        v-model="search"
        @keyup.enter="serachIt"
        size="small"
        placeholder="Type to search"
      />
      <el-button type="primary" @click="serachIt" size="small"
        >Search
      </el-button>
    </div>
    <div class="table" v-if="filterTableData.length">
      <div class="row mt-3 items_form_title">
        <div class="col-1">Order</div>
        <div class="col-3">Customer</div>
        <div class="col-2">Created date</div>
        <div class="col-1">Status</div>
        <div class="col-3">Duration of current status</div>
        <div class="col-1">Total</div>
        <div class="col-1"></div>
      </div>
      <div class="row item_list" v-if="filterTableData.length !== 0">
        <div
          class="row single_row_item"
          v-for="(item, index) in filterTableData"
          :key="index"
        >
          <div class="col-1">#{{ item.id }}</div>
          <div class="col-3">
            {{ item.user?.name }}
          </div>
          <div class="col-2">
            {{ moment(item.created_at) }}
          </div>
          <div class="col-1">
            <div :class="getClassName(item.status)" class="status">
              <span>{{ item.status }}</span>
            </div>
          </div>
          <div class="col-3">
            {{ moment(item.status_changed) }}
            (
            <timeago :datetime="item.status_changed" />
            )
          </div>
          <div class="col-1">${{ item.total_amount }}</div>
          <div class="col-1">
            <el-button
              size="small"
              @click="$router.push(`/edit_order/${item.id}`)"
              >Edit</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <p class="mt-2" v-else>No data... Try to serach on the server.</p>
    <div v-if="havePagination">
      <el-pagination
        layout="prev, pager, next"
        :total="totalNum"
        :page-count="Math.ceil(totalNum / 20)"
        :current-page="pageNo"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Orders",
  components: {},
  data() {
    return {
      search: "",
      usersData: [],
      users: null,
      fullPage: false,
      editID: null,
      isLoading: true,
      dialogVisible: false,
      confirmDelete: false,
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
    };
  },
  mounted() {
    this.fetchData().then((res) => {
      if (res.data.data.length > 0) {
        this.usersData = res.data.data;
      }
      if (res.data.last_page > 1) {
        this.totalNum = res.data.total;
        this.havePagination = true;
      }
    });
  },
  methods: {
    async fetchData(pageNo) {
      const loader = this.$loading.show();
      return await this.$api
        .get(
          `${process.env.VUE_APP_SHARED_API}api/orders?per_page=20&sort=DESC${
            pageNo ? `&page=${pageNo}` : ``
          }`
        )
        .then((res) => {
          loader.hide();
          return res;
        });
    },
    async serachIt(pageNo = 1) {
      if (this.search != "") {
        const loader = this.$loading.show();
        await this.$api
          .get(
            `${process.env.VUE_APP_SHARED_API}api/orders?per_page=20&search=${this.search}&sort=DESC
          `
          )
          .then((res) => {
            loader.hide();
            if (res.data.data.length > 0) {
              this.usersData = res.data.data;
              this.search = "";
            }
            if (res.data.last_page > 1) {
              this.totalNum = res.data.total;
              this.havePagination = true;
            } else {
              this.totalNum = 0;
              this.havePagination = false;
            }
          });
      } else {
        const loader = this.$loading.show();
        await this.$api
          .get(
            `${process.env.VUE_APP_SHARED_API}api/orders?per_page=20&search=${this.search}&sort=DESC
          `
          )
          .then((res) => {
            loader.hide();
            if (res.data.data.length > 0) {
              this.usersData = res.data.data;
              this.search = "";
            }
            if (res.data.last_page > 1) {
              this.totalNum = res.data.total;
              this.havePagination = true;
            }
          });
      }
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      const loader = this.$loading.show();
      this.fetchData(pageNo).then((res) => {
        if (res.data.data.length > 0) {
          this.usersData = res.data.data;
          loader.hide();
        }
      });
    },
    openModal(id) {
      this.dialogVisible = true;
      this.editID = id;
    },
    triggerUserPopup(id) {
      this.confirmDelete = true;
      this.deleteID = id;
    },
    deleteUser() {
      const loader = this.$loading.show();
      this.$store.dispatch("deleteUser", this.deleteID).then(() => {
        loader.hide();
      });
      this.confirmDelete = false;
    },
    moment: function (date) {
      return moment(date).format("YYYY/MM/DD");
    },
    getClassName(className) {
      if (className == "completed") {
        return "status-completed";
      } else if (className == "created") {
        return "status-created";
      }
      return className;
    },
  },
  computed: {
    returnUri() {
      return process.env.VUE_APP_SHARED_API;
    },
    filterTableData() {
      return this.usersData;
    },
  },
};
</script>

<style lang="scss" scoped>
.serach-wrapper {
  display: flex;
  max-width: 300px;
}
.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #338973;
  }
}

.users_list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 30px;
}

.user {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  width: 23.6034%;

  .user_image {
    text-align: center;
    padding-bottom: 30px;

    img {
      max-width: 150px;
    }
  }

  .user_content {
    h3 {
      font-size: 24px;
    }

    p {
      margin-bottom: 30px;
    }

    button {
      background-color: #338973;
      text-transform: uppercase;
      color: white;
      border: 1px solid #338973;
      transition: all 0.4s ease;
    }

    button:hover {
      background-color: #fff;
      color: #338973;
    }
  }
}

.user:nth-child(4) {
  margin-right: 0;
}

.languages_switcher {
  .languages_switcher__p {
    display: inline-block;
    margin: 0 5px;
    a {
      display: inline-block;
      background-color: transparent;
      color: #338973;
      border: 1px solid #338973;
      border-bottom: 0;
      text-align: center;
      padding-top: 5px;
      width: 50px;
      font-size: 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #338973;
  }
}

.items {
  padding-right: 30px;
  padding-left: 30px;

  .items_form_title {
    border-radius: 0px;
    background-color: #1f3c2a;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .item_list {
    padding: 5px 25px;
    border: 2px solid #1f3c2a;
    border-top: none;
    margin-top: -15px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #338973;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #338973;
          text-decoration: none !important;
        }
      }

      .add_or_edit_item_language {
        display: flex;
        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #338973;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item > * {
      padding: 0;
      overflow-x: auto;
    }
  }
}

.dialog_category_p {
  font-size: 18px;
}
.status {
  display: inline-flex;
  line-height: 2.5em;
  color: #777;
  background: #e5e5e5;
  border-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: -0.25em 0;
  cursor: inherit !important;
  white-space: nowrap;
  max-width: 100%;
}
.status > span {
  margin: 0 1em;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
}
.status-created {
  background: #c6e1c6;
  color: #5b841b;
}
.status-completed {
  background: #c8d7e1;
  color: #2e4453;
}
.refund.status {
  background: #d47676;
  color: #ffffff;
}
</style>
