import app from '../../../main'

const getMenus = async () => {
  return await app.config.globalProperties.$api.get(`${process.env.VUE_APP_SHARED_API}api/menu`)
}
const getMenu = async (id) => {
  return await app.config.globalProperties.$api.get(`${process.env.VUE_APP_SHARED_API}api/menu/${id}`)
}
const createMenu = async (name) => {
  return await app.config.globalProperties.$api.post(`${process.env.VUE_APP_SHARED_API}api/menu`, {
    name
  })
}
const deleteMenu = async (id) => {
  return await app.config.globalProperties.$api.delete(`${process.env.VUE_APP_SHARED_API}api/menu/${id}`)
}
const updateMenu = async (id, name) => {
  return await app.config.globalProperties.$api.put(`${process.env.VUE_APP_SHARED_API}api/menu/${id}`,
    { name: name })
}
const fetchMenuItems = async (type) => {
  return await app.config.globalProperties.$api.get(`${process.env.VUE_APP_SHARED_API}api/${type}?per_page=9999`)
}
const saveMenuItems = async (id, menuItems) => {
  return await app.config.globalProperties.$api.post(`${process.env.VUE_APP_SHARED_API}api/menu-item`,
    { menu_id: id, menu_items: menuItems }
  )
}
const removeMenuItems = async (menuItems) => {
  return await app.config.globalProperties.$api.delete(`${process.env.VUE_APP_SHARED_API}api/menu-item`,
    {
      data: {
        menu_item_ids: menuItems
      }
    }
  )
}

export { getMenus, createMenu, deleteMenu, getMenu, updateMenu, fetchMenuItems, saveMenuItems, removeMenuItems }
