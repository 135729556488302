export default {
  state: {
    isError: false
  },
  mutations: {
    SET_ERROR_STATUS(state, payload) {
      state.isError = payload;
    },
  },
  actions: {
    setErrorStatus({ commit }, payload) {
      commit("SET_ERROR_STATUS", payload)
    }
  }
}
