<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Pages</div>
  </div>
  <div v-if="windowWidth > 991" class="desk-version row scrollable-editor">
    <div class="col-3 blocks-wrapper"><blocks-wrapper></blocks-wrapper></div>
    <div class="col-5 blockeditor-wrapper">
      <p
        style="padding: 20px; font-size: 20px; opacity: 0.5; text-align: center"
        v-if="blockList.length < 1"
      >
        Add some blocks and start creating...
      </p>
      <draggable
        :list="blockList"
        :disabled="!enabled"
        item-key="id"
        class="list-group"
        ghost-class="ghost"
        @update="saveUpdatedOrder"
      >
        <template #item="{ element }">
          <div class="list-group-item" :class="{ 'not-draggable': !enabled }">
            <el-collapse accordion>
              <el-collapse-item :title="element.name" :name="element.id">
                <div v-if="element.name == 'Hero Block'">
                  <hero-block :block-data="element"></hero-block>
                </div>
                <div v-else-if="element.name == 'Two Column Section'">
                  <two-column :block-data="element"></two-column>
                </div>
                <div v-else-if="element.name == 'Browse Artwork'">
                  <browse-artwork :block-data="element"></browse-artwork>
                </div>
                <div v-else-if="element.name == 'Explore Techniques'">
                  <explore-techniques
                    :block-data="element"
                  ></explore-techniques>
                </div>
                <div v-else-if="element.name == 'Discover Artists'">
                  <discover-artists :block-data="element"></discover-artists>
                </div>
                <div v-else-if="element.name == 'Our Story'">
                  <our-story :block-data="element"></our-story>
                </div>
                <div v-else-if="element.name == 'Explore Techniques Layout 2'">
                  <explore-techniques-archive
                    :block-data="element"
                  ></explore-techniques-archive>
                </div>
                <div v-else-if="element.name == 'Discover artists Layout 2'">
                  <discover-artist-archive
                    :block-data="element"
                  ></discover-artist-archive>
                </div>
                <div v-else-if="element.name == 'Daily Collections'">
                  <daily-collections :block-data="element"></daily-collections>
                </div>
                <div v-else-if="element.name == 'Wysiwyg Editor'">
                  <wysiwyg-editor :block-data="element"></wysiwyg-editor></div
              ></el-collapse-item>
            </el-collapse>
          </div>
        </template>
      </draggable>
    </div>
    <div class="col-4 page-identitywrapper">
      <page-identity
        v-if="pageData.title"
        :page-data="pageData"
      ></page-identity>
      <page-identity v-else></page-identity>
    </div>
  </div>
  <div v-else class="mob-version">
    <div class="row relative">
      <div :class="[{ active: blockWrapperToggler }, { fixed: true }]">
        <blocks-wrapper></blocks-wrapper>
      </div>
      <div
        class="blocks-wrapper-toggler"
        v-click-outside="() => handleFocusOut('blockWrapperToggler')"
        @click="blockWrapperToggler = !blockWrapperToggler"
      >
        <span v-if="!blockWrapperToggler">+</span><span v-else>-</span>
      </div>
      <div class="col-12 blockeditor-wrapper">
        <p
          style="
            padding: 20px;
            font-size: 20px;
            opacity: 0.5;
            text-align: center;
          "
          v-if="blockList.length < 1"
        >
          Add some blocks and start creating...
        </p>
        <draggable
          :list="blockList"
          :disabled="!enabled"
          item-key="id"
          class="list-group"
          ghost-class="ghost"
          @update="saveUpdatedOrder"
        >
          <template #item="{ element }">
            <div class="list-group-item" :class="{ 'not-draggable': !enabled }">
              <el-collapse accordion>
                <el-collapse-item :title="element.name" :name="element.id">
                  <div v-if="element.name == 'Hero Block'">
                    <hero-block :block-data="element"></hero-block>
                  </div>
                  <div v-else-if="element.name == 'Two Column Section'">
                    <two-column :block-data="element"></two-column>
                  </div>
                  <div v-else-if="element.name == 'Browse Artwork'">
                    <browse-artwork :block-data="element"></browse-artwork>
                  </div>
                  <div v-else-if="element.name == 'Explore Techniques'">
                    <explore-techniques
                      :block-data="element"
                    ></explore-techniques>
                  </div>
                  <div v-else-if="element.name == 'Discover Artists'">
                    <discover-artists :block-data="element"></discover-artists>
                  </div>
                  <div v-else-if="element.name == 'Our Story'">
                    <our-story :block-data="element"></our-story>
                  </div>
                  <div
                    v-else-if="element.name == 'Explore Techniques Layout 2'"
                  >
                    <explore-techniques-archive
                      :block-data="element"
                    ></explore-techniques-archive>
                  </div>
                  <div v-else-if="element.name == 'Discover artists Layout 2'">
                    <discover-artist-archive
                      :block-data="element"
                    ></discover-artist-archive>
                  </div>
                  <div v-else-if="element.name == 'Daily Collections'">
                    <daily-collections
                      :block-data="element"
                    ></daily-collections>
                  </div>
                  <div v-else-if="element.name == 'Wysiwyg Editor'">
                    <wysiwyg-editor :block-data="element"></wysiwyg-editor></div
                ></el-collapse-item>
              </el-collapse>
            </div>
          </template>
        </draggable>
      </div>
      <div class="page-data">
        <page-identity
          v-if="pageData.title"
          :page-data="pageData"
        ></page-identity>
        <page-identity v-else></page-identity>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

import PageIdentity from "../../components/blockeditor/PageIdentity.vue";
import BlocksWrapper from "../../components/blockeditor/BlocksWrapper.vue";
import HeroBlock from "../../components/blockeditor/blocks/HeroBlock.vue";
import TwoColumn from "../../components/blockeditor/blocks/TwoColumn.vue";
import BrowseArtwork from "../../components/blockeditor/blocks/BrowseArtwork.vue";
import ExploreTechniques from "../../components/blockeditor/blocks/ExploreTechniques.vue";
import DiscoverArtists from "../../components/blockeditor/blocks/DiscoverArtists.vue";
import OurStory from "../../components/blockeditor/blocks/OurStory.vue";
import ExploreTechniquesArchive from "../../components/blockeditor/blocks/ExploreTechniquesArchive.vue";
import DiscoverArtistArchive from "../../components/blockeditor/blocks/DiscoverArtistArchive.vue";
import { getSinglePage } from "../../services/items";
import DailyCollections from "../../components/blockeditor/blocks/DailyCollections.vue";
import WysiwygBlock from "../../components/blockeditor/blocks/WysiwygBlock.vue";

export default {
  name: "EditItem",
  components: {
    "page-identity": PageIdentity,
    "blocks-wrapper": BlocksWrapper,
    draggable,
    "hero-block": HeroBlock,
    "two-column": TwoColumn,
    "browse-artwork": BrowseArtwork,
    "explore-techniques": ExploreTechniques,
    "discover-artists": DiscoverArtists,
    "our-story": OurStory,
    "explore-techniques-archive": ExploreTechniquesArchive,
    "discover-artist-archive": DiscoverArtistArchive,
    "daily-collections": DailyCollections,
    "wysiwyg-editor": WysiwygBlock,
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "UPDATE_BLOCK_FROM_API") {
        this.blockList = state.block.blockList;
      }
      if (mutation.type === "ADD_BLOCK_NAME_TO_LIST") {
        // modify default block with new ID and OrderID
        let lastPositionIndex = 0;
        if (this.blockList.length > 0) {
          lastPositionIndex =
            parseInt(this.blockList[this.blockList.length - 1].order) + 1;
        }
        // console.log(lastPositionIndex);
        if (!this.$route.params.id) {
          this.blockList.push({
            id: this.i,
            name: state.block.listOfBlocks[this.i],
            order: lastPositionIndex,
          });
        } else if (this.firstPick == true) {
          this.blockList.push({
            id: this.i,
            name: state.block.listOfBlocks[this.i],
            order: lastPositionIndex,
          });
        }
        this.$store.dispatch("addBlockToList", {
          id: this.i,
          name: state.block.listOfBlocks[this.i],
          order: lastPositionIndex,
          block_content: {},
        });
        this.i = this.i + 1;
        this.blockList = state.block.blockList;
      }
      if (mutation.type === "REMOVE_BLOCK_FROM_LIST") {
        this.firstPick = true;
        this.blockList = this.blockList.filter(function (element) {
          return element.id !== state.block.lastRemoved;
        });
        this.$store.dispatch("reoderBlocks");
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  data() {
    return {
      enabled: true,
      dragging: false,
      blockList: [],
      i: 0,
      pageData: {},
      firstPick: false,
      blockWrapperToggler: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    saveUpdatedOrder: function (e) {
      this.blockList.map((block, i) => {
        this.$store.dispatch("updateOrderById", {
          id: block.id,
          order: i,
        });
      });
    },
    handleFocusOut(type) {
      // this[type] = false;
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    if (this.$route.params.id) {
      const loader = this.$loading.show();
      getSinglePage(this.$route.params.id).then((page) => {
        this.pageData = {
          ...this.pageData,
          title: page.title,
          status_id: page.status_id,
        };
        if (page.page_id) {
          this.$store.dispatch("setPageId", page.page_id);
        }
        this.$store.dispatch("updateBlockFromAPI", page?.blocks);
        loader.hide();
      });
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === "New Item") {
        this.blockList = [];
        this.pageData = {};
        this.firstPick = false;
        this.i = 0;
        this.$store.dispatch("resetToDef");
      }
    },
  },
};
</script>

<style scoped>
.scrollable-editor {
  border: 1px solid #e0e0e0;
  margin-right: 30px !important;
  max-height: 100vh;
  height: auto;
  overflow-y: scroll;
}
.page-identitywrapper {
  padding: 5px;
  border-left: 1px solid #e0e0e0;
}
.blocks-wrapper {
  /* border-right: 1px solid #e0e0e0; */
}
.list-group {
  padding: 20px 0;
  padding-right: 0 !important;
  border-radius: 0 !important;
}
.list-group-item {
  cursor: pointer;
  border: 2px dotted #e0e0e0 !important;
}
.list-group-item:not(:last-child) {
  margin-bottom: 10px !important;
  cursor: pointer;
}
.list-group-item > * {
  border: none !important;
}

@media (max-width: 991px) {
  .desk-version {
    display: none;
  }
  .mob-version {
    display: block;
  }
  .relative {
    position: relative;
  }
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    max-width: 50vw;
    background-color: white;
    overflow-y: scroll;
    max-height: 100vh;
    padding-top: 50px;
    height: 100%;
    border: none;
    transform: translateX(-100%);
    transition: all 0.3s ease-in;
  }
  .fixed .blocks-wrapper {
    border: none;
  }
  .blocks-wrapper-toggler {
    position: fixed;
    top: 70px;
    right: 22px;
    z-index: 99;
    background: #403f37;
    width: 32px;
    height: 32px;
    padding: 0;
    color: white;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fixed.active {
    transform: translateX(0);
  }
}
@media (min-width: 991px) {
  .desk-version {
    display: flex;
  }
  .mob-version {
    display: none;
  }
}
@media (max-width: 550px) {
  .fixed {
    max-width: 80vw;
  }
}
.blockeditor-wrapper {
  background-color: rgba(192, 192, 192, 0.244);
}
</style>
