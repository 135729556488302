export const tagsConvertor = async (tags, favtagId) => {
  let arrToReturn = []
  await tags.map(tag => {
    arrToReturn.push({
      id: tag,
      primary: tag === favtagId ? true : false
    })
  })
  return arrToReturn
}
