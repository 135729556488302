<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Deactivated Users</div>
  </div>
  <div class="items">
    <div class="search-export">
      <div class="serach-wrapper">
        <el-input
          v-model="search"
          @keyup.enter="serachIt"
          size="small"
          placeholder="Type to search"
        />
        <el-button type="primary" @click="serachIt" size="small"
          >Search
        </el-button>
      </div>
    </div>
    <div class="table" v-if="usersData.length">
      <div class="row mt-3 items_form_title">
        <div class="col-1"></div>
        <div class="col-2">Name</div>
        <div class="col-2">Email</div>
        <div class="col-1">Collections</div>
        <div class="col-2">Artworks in collection</div>
        <div class="col-1">Orders</div>
        <div class="col-1">Amount</div>
        <div class="col-1">Runs algorithm</div>
        <div class="col-1">Restore account</div>
      </div>
      <div class="row item_list" v-if="usersData.length !== 0">
        <div
          class="row single_row_item"
          v-for="(item, index) in usersData"
          :key="index"
        >
          <div class="col-1">
            <el-avatar
              v-if="item.avatar?.properties.thumbnail"
              :size="30"
              :src="returnUri + item.avatar?.properties.thumbnail"
            />
            <el-avatar
              :size="30"
              v-else-if="item.avatar?.slug"
              :src="returnUri + item.avatar?.slug"
            />
            <img
              v-else
              width="30"
              height="30"
              style="object-fit: cover"
              src="@/assets/img/avatar.png"
            />
          </div>
          <div class="col-2">
            {{ item?.name }}
          </div>
          <div class="col-2">
            {{ item.email }}
          </div>
          <div class="col-1">
            {{ item.collections_count }}
          </div>
          <div class="col-2">
            {{ item.artworks_in_collections }}
          </div>
          <div class="col-1">
            {{ item.orders_count }}
          </div>
          <div class="col-1">${{ item.total_amount_paid }}</div>
          <div class="col-1">{{ item.algorithm_infos_count }}</div>
          <div class="col-1">
            <el-button
              type="primary"
              @click="() => this.restoreUser(item.id)"
              size="small"
              >Restore
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <p class="mt-2" v-else>No data... Try to serach on the server.</p>
    <div v-if="havePagination">
      <el-pagination
        layout="prev, pager, next"
        :total="totalNum"
        :page-count="Math.ceil(totalNum / 20)"
        :current-page="pageNo"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { ElNotification, ElMessageBox } from "element-plus";


export default {
  name: "Deactivated Users",
  components: {},
  data() {
    return {
      search: "",
      usersData: [],
      users: null,
      fullPage: false,
      editID: null,
      isLoading: true,
      dialogVisible: false,
      confirmDelete: false,
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
      json_data: [],
    };
  },
  mounted() {
    this.fetchData().then((res) => {
      if (res.data.data.length > 0) {
        this.usersData = res.data.data;
      }
      if (res.data.last_page > 1) {
        this.totalNum = res.data.total;
        this.havePagination = true;
      }
    });
  },
  methods: {
    async fetchData(pageNo) {
      const loader = this.$loading.show();
      return await this.$api
        .get(
          `${
            process.env.VUE_APP_SHARED_API
          }api/auth/trash-list?per_page=20&role=customer${
            pageNo ? `&page=${pageNo}` : ``
          }`
        )
        .then((res) => {
          this.usersData = res.data.data;
          loader.hide();
          return res;
        });
    },
    async serachIt(pageNo = 1) {
      if (this.search != "") {
        const loader = this.$loading.show();
        await this.$api
          .get(
            `${process.env.VUE_APP_SHARED_API}api/auth/trash-list?per_page=20&role=customer&search=${this.search}&sort=DESC
          `
          )
          .then((res) => {
            loader.hide();
            if (res.data.data.length < 1) {
              this.usersData = res.data.data;
            }
            if (res.data.last_page > 1) {
              this.totalNum = res.data.total;
              this.havePagination = true;
            }
          });
      } else {
        this.fetchData().then((res) => {
          if (res.data.data.length > 0) {
            this.usersData = res.data.data;
          }
          if (res.data.last_page > 1) {
            this.totalNum = res.data.total;
            this.havePagination = true;
          }
        });
      }
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      const loader = this.$loading.show();
      this.fetchData(pageNo).then((res) => {
        if (res.data.data.length > 0) {
          this.usersData = res.data.data;
          loader.hide();
        }
      });
    },
    async restoreUser(id) {
      ElMessageBox.confirm("Are you sure you want to restore this account?", "Warning", {
        confirmButtonText: "Restore",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.isLoading = true;
        this.$api
          .post(`${process.env.VUE_APP_SHARED_API}api/auth/restore/${id}`)
          .then((res) => {
            ElNotification({
              type: "success",
              message: "Restore completed",
            });
            this.fetchData();
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            const errorMessage = this.getErrorMessage(err);
            ElNotification({
              title: "Error!",
              message: errorMessage,
            });
          });
      });
    },
  },
  computed: {
    returnUri() {
      return process.env.VUE_APP_SHARED_API;
    },
  },
};
</script>

<style lang="scss" scoped>
.serach-wrapper {
  display: flex;
  max-width: 300px;
}
.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #1f3c2a;
  }
}

.users_list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 30px;
}

.user {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  width: 23.6034%;

  .user_image {
    text-align: center;
    padding-bottom: 30px;

    img {
      max-width: 150px;
    }
  }

  .user_content {
    h3 {
      font-size: 24px;
    }

    p {
      margin-bottom: 30px;
    }

    button {
      background-color: #1f3c2a;
      text-transform: uppercase;
      color: white;
      border: 1px solid #1f3c2a;
      transition: all 0.4s ease;
    }

    button:hover {
      background-color: #fff;
      color: #1f3c2a;
    }
  }
}

.user:nth-child(4) {
  margin-right: 0;
}

.languages_switcher {
  .languages_switcher__p {
    display: inline-block;
    margin: 0 5px;
    a {
      display: inline-block;
      background-color: transparent;
      color: #1f3c2a;
      border: 1px solid #1f3c2a;
      border-bottom: 0;
      text-align: center;
      padding-top: 5px;
      width: 50px;
      font-size: 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #1f3c2a;
  }
}

.items {
  padding-right: 30px;
  padding-left: 30px;

  .items_form_title {
    border-radius: 0px;
    background-color: #1f3c2a;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
  .items_form_title > div {
    padding: 0 !important;
  }

  .item_list {
    padding: 5px 25px;
    border: 2px solid #1f3c2a;
    border-top: none;
    margin-top: -15px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #1f3c2a;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #1f3c2a;
          text-decoration: none !important;
        }
      }

      .add_or_edit_item_language {
        display: flex;
        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #1f3c2a;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item > * {
      padding: 0;
      overflow-x: auto;
      padding-right: 5px;
    }
  }
}

.dialog_category_p {
  font-size: 18px;
}
.search-export {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 400px) {
  .search-export {
    flex-wrap: wrap;
  }
  .serach-wrapper {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
  .search-export > .el-button {
    width: 100% !important;
  }
}
</style>
