<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      {{ $route.name }}
    </div>
  </div>
  <div class="items">
    <div v-if="posts.length">
      <div class="row items_form_title">
        <div class="col">Title</div>
      </div>
      <div class="row item_list" v-if="posts.length !== 0">
        <div
          class="row single_row_item"
          v-for="(item, index) in posts"
          :key="index"
        >
          <div>
            <h5>{{ item.title || item.name }}</h5>
            <p class="single_row_item__p" @click="restorePost(item.id)">
              Restore
            </p>
            <p class="single_row_item__p red-flag" @click="deletePost(item.id)">
              Delete Permanently
            </p>
          </div>
        </div>
      </div>
    </div>
    <p v-else>The bin is empty...</p>
    <div v-if="havePagination">
      <el-pagination
        layout="prev, pager, next"
        :total="totalNum"
        :page-count="Math.ceil(totalNum / 10)"
        :current-page="pageNo"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { ElPagination, ElNotification, ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      posts: [],
      isPostLoaded: false,
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
    };
  },
  components: {
    ElPagination,
  },
  methods: {
    fetchPosts(pageNo) {
      return this.$api.get(
        `${process.env.VUE_APP_SHARED_API}api/${
          this.$route.params.type
        }/trash?per_page=20${pageNo ? `&page=${pageNo}` : ``}&sort=DESC`
      );
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      const loader = this.$loading.show();
      this.fetchPosts(pageNo).then((res) => {
        loader.hide();
        if (res?.data?.data?.length > 0) {
          this.posts = res.data.data;
        }
      });
    },
    deletePost(id) {
      ElMessageBox.confirm(
        `Are you sure you want to delete ${this.$route.params.type} permanently?`,
        "Are you sure?",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          const loader = this.$loading.show();
          this.$api
            .delete(
              `${process.env.VUE_APP_SHARED_API}api/${this.$route.params.type}/trash/${id}`
            )
            .then((res) => {
              loader.hide();
              if (res.data) {
                ElNotification({
                  title: "Success!",
                  message: res.data,
                  type: "success",
                });
                this.posts = this.posts.filter((data) => data.id != id);
              } else {
                ElNotification({
                  title: "Warning!",
                  message: "Something went wrong, please try later!",
                  type: "warning",
                });
              }
            })
            .catch((e) => {
              loader.hide();
              ElNotification({
                title: "Warning!",
                message: e.response.data,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    restorePost(id) {
      const loader = this.$loading.show();
      this.$api
        .post(
          `${process.env.VUE_APP_SHARED_API}api/${this.$route.params.type}/trash/${id}`,
          {}
        )
        .then((res) => {
          loader.hide();
          if (res.data) {
            ElNotification({
              title: "Success!",
              message: res.data,
              type: "success",
            });
            this.posts = this.posts.filter((data) => data.id != id);
          } else {
            ElNotification({
              title: "Warning!",
              message: "Something went wrong, please try later!",
              type: "warning",
            });
          }
        })
        .catch((e) => {
          loader.hide();
          ElNotification({
            title: "Warning!",
            message: "Something went wrong, please try later!",
            type: "warning",
          });
        });
    },
  },
  mounted() {
    const loader = this.$loading.show();
    try {
      this.fetchPosts().then((res) => {
        loader.hide();
        if (res?.data?.data?.length > 0) {
          this.posts = res.data.data;
          if (res.data.last_page > 1) {
            this.totalNum = res.data.total;
            this.havePagination = true;
          }
        }
      });
    } catch (e) {
      loader.hide();
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.name === "Trash") {
        const loader = this.$loading.show();
        this.posts = [];
        this.isPostLoaded = false;
        this.havePagination = false;
        this.totalNum = 0;
        this.pageNo = 1;
        this.fetchPosts().then((res) => {
          loader.hide();
          if (res?.data?.data?.length > 0) {
            this.posts = res.data.data;
            if (res.data.last_page > 1) {
              this.totalNum = res.data.total;
              this.havePagination = true;
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.red-flag {
  color: #b32d2e !important;
}
.languages_switcher {
  .languages_switcher__p {
    display: inline-block;
    margin: 0 5px;
    a {
      display: inline-block;
      background-color: transparent;
      color: #1f3c2a;
      border: 1px solid #1f3c2a;
      border-bottom: 0;
      text-align: center;
      padding-top: 5px;
      width: 50px;
      font-size: 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #1f3c2a;
  }
}

.items {
  padding-right: 30px;
  padding-left: 30px;

  .items_form_title {
    border-radius: 0px;
    background-color: #1f3c2a;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .item_list {
    padding: 5px 25px;
    border: 2px solid #1f3c2a;
    border-top: none;
    margin-top: -15px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #1f3c2a;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #1f3c2a;
          text-decoration: none !important;
        }
      }

      .add_or_edit_item_language {
        display: flex;
        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #1f3c2a;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item > * {
      padding: 0;
      overflow-x: auto;
    }
  }
}

.dialog_category_p {
  font-size: 18px;
}
</style>
