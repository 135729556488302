<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      <p>{{ $route.query.mode }} {{ $route.query.singular }}</p>
    </div>
  </div>
  <div v-if="$route.query.mode === 'new'">
    <new></new>
  </div>
  <div v-else>
    <edit></edit>
  </div>
</template>

<script>
import New from "./components/New";
import Edit from "./components/Edit";

export default {
  name: "NItem",
  components: {
    New,
    Edit,
  },
};
</script>

<style lang="scss" scoped>
.navigation-title-section-content {
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.single_post_main_box {
  padding: 25px;
  background-color: #f2f2f2;
  border-radius: 5px;
  height: 100%;
}

.single_post_box {
  background-color: #f2f2f2;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 5px;

  .single_post_box__h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .featured_image_single {
    max-width: 400px;
    margin: 0 auto;
  }
}

.category_checkbox:active,
.category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.categories_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  height: 150px;
  overflow-y: scroll;
}

.single_category__div {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;

  .category_checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }

  .category_checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #42b983;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .category_checkbox:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.buttons_section {
  text-align: right;
}
.tip_tap_editor {
  max-height: 650px;
  height: 100%;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
</style>
