<template>
  <div class="">
    <div class="row">
      <div class="col-sm-3">
        <div class="card widget-flat">
          <div class="card-body">
            <div class="float-end">
              <div class="mdi mdi-account-multiple widget-icon">
                <font-awesome-icon :icon="['fas', 'users']" class="" />
              </div>
            </div>
            <h5 class="text-muted fw-normal mt-0" title="Number of Customers">
              Users
            </h5>
            <h3 class="mt-3 mb-3">{{ numberOfCustomers }}</h3>
          </div>
          <!-- end card-body-->
        </div>
        <!-- end card-->
      </div>
      <!-- end col-->

      <div class="col-sm-3">
        <div class="card widget-flat">
          <div class="card-body">
            <div class="float-end">
              <div class="mdi mdi-account-multiple widget-icon">
                <font-awesome-icon :icon="['fas', 'cart-plus']" class="" />
              </div>
            </div>
            <h5 class="text-muted fw-normal mt-0" title="Number of Orders">
              Orders
            </h5>
            <h3 class="mt-3 mb-3">{{ numberOfOrders }}</h3>
          </div>
          <!-- end card-body-->
        </div>
        <!-- end card-->
      </div>
      <!-- end col-->

      <div class="col-sm-3">
        <div class="card widget-flat">
          <div class="card-body">
            <div class="float-end">
              <div class="mdi mdi-account-multiple widget-icon">
                <font-awesome-icon :icon="['fas', 'dollar-sign']" class="" />
              </div>
            </div>
            <h5 class="text-muted fw-normal mt-0" title="Average Revenue">
              Revenue
            </h5>
            <h3 class="mt-3 mb-3">${{ totalAmount }}</h3>
          </div>
          <!-- end card-body-->
        </div>
        <!-- end card-->
      </div>
      <!-- end col-->

      <div class="col-sm-3">
        <div class="card widget-flat">
          <div class="card-body">
            <div class="float-end">
              <div class="mdi mdi-account-multiple widget-icon">
                <font-awesome-icon :icon="['fas', 'image']" class="" />
              </div>
            </div>
            <h5 class="text-muted fw-normal mt-0" title="Growth">Artworks</h5>
            <h3 class="mt-3 mb-3">{{ numberOfArtworks }}</h3>
          </div>
          <!-- end card-body-->
        </div>
        <!-- end card-->
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </div>
  <!-- end col -->

  <div class="row">
    <div class="col-12">
      <div class="card card-h-100">
        <div class="card-body pt-0">
          <div dir="ltr">
            <BarChart :chartData="chartData" :options="options" />
          </div>
        </div>
        <!-- end card-body-->
      </div>
      <!-- end card-->
    </div>
  </div>
  <!-- end col -->
</template>
<script>
import { defineComponent, ref } from "vue";
import { BarChart } from "vue-chart-3";
import { getMonthlyReport } from "@/services/statistics";

export default defineComponent({
  name: "Home",
  components: { BarChart },
  mounted() {
    getMonthlyReport().then((results) => {
      this.chartData.labels = Object.keys(results.chart_report);
      this.chartData.datasets[0].data = Object.values(results.chart_report);
      this.numberOfArtworks = results.number_of_artworks;
      this.totalAmount = results.total_cash;
      this.numberOfOrders = results.number_of_orders;
      this.numberOfCustomers = results.number_of_customers;
    });
  },
  setup() {
    const hexColors = [
      "#7A942E",
      "#8DB255",
      "#5B8930",
      "#6B9362",
      "#407A52",
      "#006442",
      "#87D37C",
      "#26A65B",
      "#26C281",
      "#049372",
      "#2ABB9B",
      "#16A085",
      "#36D7B7",
      "#03A678",
      "#4DAF7C",
      "#7A942E",
      "#8DB255",
      "#5B8930",
      "#6B9362",
      "#407A52",
      "#006442",
      "#87D37C",
      "#26A65B",
      "#26C281",
      "#049372",
      "#2ABB9B",
      "#16A085",
      "#36D7B7",
      "#03A678",
      "#4DAF7C",
    ];

    const chartData = ref({
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ref(hexColors),
        },
      ],
    });

    const options = {
      responsive: true,
      plugins: {
        legend: false,
        title: {
          display: true,
          text: "Revenue by day",
        },
      },
    };

    const numberOfCustomers = ref(0);
    const numberOfOrders = ref(0);
    const numberOfArtworks = ref(0);
    const totalAmount = ref(0);

    return {
      chartData,
      options,
      numberOfCustomers,
      numberOfOrders,
      numberOfArtworks,
      totalAmount,
    };
  },
});
</script>
