import api from './api'

export function getAllCategories () {
  return api.get('api/category').then(response => {
    return response.data.data
  })
}

export function getSingleCategory (id) {
  return api.get('api/category/' + id).then(response => {
    return response.data.category
  })
}
