import api from './api'

export const saveNFTsRecord = async (nfts) => {
  const res = await api.post('api/bulk-store-nfts', {
    items: nfts
  })
  return res;
}

export const updateNFTRecord = async (data) => { }

export const deleteNFTRecord = async (id) => {
  const res = await api.delete('api/nfts' + id, {
    items: nfts
  })
  return res;
}
