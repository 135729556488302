<template>
  <div class="row">
    <div class="col">
      <label for="value mb-2">Value Indicator: </label>
      <br />
      <label for="value-indicators-icon mt-3">Icon </label>
      <br />
      <img
        v-if="valueIndicator.iconUrl"
        class="mr-4"
        width="30"
        height="20"
        :src="domainURI + '/' + valueIndicator.iconUrl"
      />
      <br />
      <label for="value-indicators-icon">Name </label>
      <br />
      <el-input
        v-model="valueIndicator.name"
        :value="valueIndicator.name"
        disabled=""
        placeholder="Name"
      ></el-input>
      <label class="mt-2" for="year">Content </label>
      <tiptap
        :vimode="true"
        style="height: 250px; overflow: scroll"
        v-model="valueIndicator.valueIndicators"
      />
    </div>
  </div>
</template>
<script>
import { ElInput } from "element-plus";
import Tiptap from "../../components/Tiptap/Tiptap.vue";
export default {
  data() {
    return {
      valueIndicator: {
        iconUrl:
          this.$route.params.type === "techniques"
            ? "media/icons/composition.png"
            : "media/icons/recognition.png",
        name:
          this.$route.params.type === "techniques"
            ? "Composition"
            : "Recognition",
        valueIndicators: "",
      },
      valueIndicatorsIcons: [],
      selectedIcon: "",
    };
  },
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
      type: Object,
    },
  },
  components: {
    Tiptap,
    ElInput,
    // ElSelect,
    // ElOption,
  },
  mounted() {
    this.$api
      .get(`${process.env.VUE_APP_SHARED_API}api/media?per_page=50&type=icon`, {
        headers: {
          Authorization: `Bearer ${this.$cookies.get("bearerToken")}`,
        },
      })
      .then((res) => {
        if (res.data.data) {
          this.valueIndicatorsIcons = res.data.data;
        }
      });
  },
  computed: {
    domainURI() {
      return process.env.VUE_APP_SHARED_API;
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            valueIndicator: this.valueIndicator,
          },
        });
        // this.$store.dispatch("resetState");
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new"
      ) {
        if (state.customFields[this.$route.params.type].valueIndicator) {
          this.valueIndicator = {
            name: state.customFields[this.$route.params.type].valueIndicator
              .name,
            iconUrl:
              state.customFields[this.$route.params.type].valueIndicator
                .icon_url,
            valueIndicators: [],
          };
          if (
            Array.isArray(
              state.customFields[this.$route.params.type].valueIndicator.content
            )
          ) {
            state.customFields[
              this.$route.params.type
            ].valueIndicator.content.map((c) => {
              this.valueIndicator.valueIndicators = c;
            });
          } else {
            this.valueIndicator.valueIndicators.push({
              content:
                state.customFields[this.$route.params.type].valueIndicator
                  .content,
            });
          }
        }
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    convertor(slug) {
      if (slug === "media/icons/intent.png") {
        this.valueIndicator.name = "Intent";
        return "Intent";
      }
      if (slug === "media/icons/ceres-take.png") {
        this.valueIndicator.name = "Ceres Take";
        return "Ceres Take";
      }
      if (slug === "media/icons/recognition.png") {
        this.valueIndicator.name = "Recognition";
        return "Recognition";
      }
      if (slug === "media/icons/composition.png") {
        this.valueIndicator.name = "Composition";
        return "Composition";
      }
    },
  },
};
</script>

<style scoped>
.mr-4 {
  margin-right: 14px;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  left: -20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
}
.mr-4 {
  margin-right: 10px;
}
.relative-parent {
  position: relative;
  margin-bottom: 10px;
}
.relative-parent img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.relative-parent .el-select {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 10px;
  z-index: 1;
}
.relative-parent::before {
  left: 0;
  top: 0;
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1f3b2a;
  position: absolute;
  opacity: 0.3;
}
.relative-parent::after {
  left: 0;
  top: 0;
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGPSURBVEiJrdQ9axRRFMbxX8SXqCGoSaeFIBjtAinsUomp7GQLU4kgBBHxK1j4FQKJIFaWEQSNhSnsUgRZQUinSBRRsLBRSOJazBn2ZjKbcWb2gcO9c+be/3P3nDsLvZax4gAdjnEHnw9aWKKjOFe1KDfYwoWaBtN4V7XoUOH5BDoxlqkTUUs9fEwAPcyXrLuBbVk552W/oLIHRYOTuInjhTWdgKfxqIlBmYonz5//6xZVGRThxXwrg7QsZY293cagCg4TuINrSe4UHuAhZgYZDCpLmY5gPOZTso81781OsPSwi58Ru5HbTnJpfErgK1jHFXyNfc9wP+abI7Gpjn7hvOxKv8Rs8m4Rd+Pdd/ypyd6nGfyVnfYHTmMUq5F72gZ+Sb8s32Jcx+uYd3GmKfwivgRoGWNY029wF5PDgC/J/jCP4UVisNH09Gf1y7KIEXtr3sXbmN9rYvAkOXkOf2VvWcaw0AQO7wM2MQDeWpsBfIwPw4Yv6N/5PN4MC3494L9xC1dxWdaHoWgUzzFXd+M/J+Wea8xW6ssAAAAASUVORK5CYII=");
}

.option-grid {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
