export default {
  state: {
    menusItems: [],
    menuItemsGroup: [],
  },
  mutations: {
    ADD_MENU_ITEMS(state, items) {
      state.menusItems = items;
    },
    RESET_MENU_ITEMS(state) {
      state.menusItems = []
      state.menuItemsGroup = []
    },
    COLLECT_MENU_DATA(state) {
      state.menusItems = state.menusItems
    },
    COLLECT_GROUP_MENU_DATA(state) {
      state.menuItemsGroup = state.menuItemsGroup
    },
    ADD_GROUP_MEDIA_DATA(state, payload) {
      state.menuItemsGroup.push(payload)
    },
    RESET_GRUP_MEDIA_DATA(state) {
      state.menuItemsGroup = []
    },
    RESET_MENU_DATA(state) {
      state.menuItemsGroup = []
      state.menusItems = []
    }
  },
  actions: {
    addMenuItems({ commit }, items) {
      commit("ADD_MENU_ITEMS", items)
    },
    resetMenuItems({ commit }) {
      commit("RESET_MENU_ITEMS")
    },
    collectMenuData({ commit }) {
      commit("COLLECT_MENU_DATA")
    },
    collectGroupMenuData({ commit }) {
      commit("COLLECT_GROUP_MENU_DATA")
    },
    addGroupMediaData({ commit }, payload) {
      commit("ADD_GROUP_MEDIA_DATA", payload)
    },
    resetGroupMediaData({ commit }) {
      commit("RESET_GRUP_MEDIA_DATA")
    },
    resetMenuData({commit}) {
      commit("RESET_MENU_DATA")
    }
  }
}
