export default {
  state: {
    enabled: false
  },
  mutations: {
    ENABLE_LOGINMODAL(state, payload) {
      state.enabled = true;
    },
    DISABLE_LOGINMODAL(state) {
      state.enabled = false;
    },
  },
  actions: {
    enableLoginModal({ commit }) {
      commit("ENABLE_LOGINMODAL")
    },
    disableLoginModal({ commit }) {
      commit("DISABLE_LOGINMODAL")
    },
  }
}
