<template>
  <div class="wrapper_title">
    <div class="loader__wrapper" v-if="!isLoaded">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p class="mt-4">Title:</p>
      </div>
      <div class="col-8">
        <el-input
          class="mt-2"
          v-model="pageTitle"
          placeholder="Please enter page title"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p class="mt-4">Slug:</p>
      </div>
      <div class="col-8">
        <el-input
          class="mt-2"
          v-model="pageSlug"
          placeholder="Please enter page slug"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p class="mt-3">Visibility:</p>
      </div>
      <div class="col-8">
        <el-select
          v-model="statusId"
          class="m-2 pagestatus"
          placeholder="Page status"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p class="mt-3">Location:</p>
      </div>
      <div class="col-8">
        <el-select
          v-model="pageLocation"
          class="m-2 pagestatus"
          clearable
          placeholder="Page location"
          :disabled="pageLocations.length < 1"
        >
          <el-option
            v-for="item in pageLocations"
            :key="item.id"
            :label="
              item.location.charAt(0).toUpperCase() +
              item.location.slice(1).split('-').join(' ')
            "
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p class="mt-3">Meta title:</p>
      </div>
      <div class="col-8">
        <el-input
          class="mt-2"
          v-model="meta_title"
          placeholder="Meta title"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p class="mt-3">Meta description:</p>
      </div>
      <div class="col-8">
        <el-input
          class="mt-2"
          v-model="meta_description"
          placeholder="Meta description"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <el-button @click="collectData" class="save_btn">{{
          !this.$route.params.id ? "Publish" : "Update"
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElButton } from "element-plus";
import slugify from "slugify";

export default {
  props: {
    pageData: Object,
  },
  data() {
    return {
      isLoaded: true,
      pageTitle: "",
      bodyContent: "",
      statusId: "Published",
      blocks: [],
      pageSlug: "",
      options: [
        {
          value: "2",
          label: "Published",
        },
        {
          value: "1",
          label: "Draft",
        },
      ],
      pageLocations: [],
      pageLocation: "",
      tempPageLocationId: "",
      meta_title: "",
      meta_description: ""
    };
  },
  methods: {
    filterBlocks() {
      this.blocks = this.$store.state.block.blockList.map((block) => {
        if (block.name === "Explore Techniques") {
          if (block.content?.techniques.length > 0) {
            block.content.techniques = block.content.techniques.filter(
              (technqiue) => technqiue !== "Please select"
            );
          }
        }
        if (block.name === "Browse Artwork") {
          block = { ...block, block_content: "{}" };
        }
        return block;
      });
    },
    createPage() {
      if (
        this.$store.state.block.blockList.length > 0 &&
        this.pageTitle !== "" &&
        this.pageSlug !== ""
      ) {
        const loader = this.$loading.show();
        this.filterBlocks();
        let data = {
          title: this.pageTitle,
          body_content: this.bodyContent,
          slug: this.pageSlug,
          status_id: this.statusId == "Published" || this.statusId == 2 ? 2 : 1,
          blocks: JSON.stringify(this.$store.state.block.blockList),
          meta_title: this.meta_title,
          meta_description: this.meta_description,
        };

        if (this.pageLocation !== "") {
          data = {
            ...data,
            page_id: this.pageLocation,
          };
        }
        this.$api
          .post(`${process.env.VUE_APP_SHARED_API}api/page-translations`, data)
          .then((res) => {
            loader.hide();
            if (res.data.id) {
              this.$notify({
                title: "Success",
                message: "Page is successfully created.",
                type: "success",
              });
              this.$store.dispatch("resetToDef");
              this.$store.dispatch("resetPageId");
              this.$router.push("/pages");
            }
          })
          .catch((e) => {
            loader.hide();
            if (e.response.data.errors) {
              this.$notify({
                title: "Warning",
                message:
                  e.response.data.errors.slug[0] ||
                  "Something wen`t wong, please try later",
                type: "warning",
              });
            } else {
              this.$notify({
                title: "Warning",
                message:
                  e.response?.data?.message ||
                  "Something went wrong, please try later.",
                type: "warning",
              });
            }
          });
      } else {
        this.$notify({
          title: "Warning",
          message: "Content, title and slug are required.",
          type: "warning",
        });
      }
    },
    updatePage() {
      if (
        this.$store.state.block.blockList.length > 0 &&
        this.pageTitle !== "" &&
        this.pageSlug !== ""
      ) {
        const loader = this.$loading.show();
        this.filterBlocks();
        let data = {
          title: this.pageTitle,
          body_content: this.bodyContent,
          status_id: this.statusId == "Published" || this.statusId == 2 ? 2 : 1,
          blocks: JSON.stringify(this.blocks),
          slug: this.pageSlug,
          meta_title: this.meta_title,
          meta_description: this.meta_description,
        };

        if (this.pageLocation !== "") {
          data = {
            ...data,
            page_id: this.pageLocation,
          };
        }
        this.$api
          .put(
            `${process.env.VUE_APP_SHARED_API}api/page-translations/${this.$route.params.id}`,
            data
          )
          .then((res) => {
            loader.hide();
            if (res.data.id) {
              this.$notify({
                title: "Success",
                message: "Page is successfully updated.",
                type: "success",
              });
              // this.$store.dispatch("resetToDef");
              // this.$store.dispatch("resetPageId");
            }
          })
          .catch((e) => {
            loader.hide();
            this.$notify({
              title: "Warning",
              message:
                e.response?.data?.errors?.title[0] || e.response?.data?.message,
              type: "warning",
            });
          });
      } else {
        this.$notify({
          title: "Warning",
          message: "Content, title and slug are required.",
          type: "warning",
        });
      }
    },
    collectData() {
      this.$store.dispatch("collectBlockData", "");
      this.$route.params.id ? this.updatePage() : this.createPage();
    },
  },
  mounted() {
    this.$api.get(`${process.env.VUE_APP_SHARED_API}api/pages`).then((res) => {
      this.pageLocations = res.data.data || [];
      if (this.$route.params.id && this.$store.state.block.pageId !== "") {
        this.pageLocation = parseInt(this.$store.state.block.pageId);
      }
    });
    this.$api.get(`${process.env.VUE_APP_SHARED_API}api/page-translations/${this.$route.params.id}`).then((res) => {
      this.meta_description = res.data.meta_description;
      this.meta_title = res.data.meta_title
    })
  },
  created() {
    if (this.pageData?.title) {
      this.pageTitle = this.pageData.title;
      this.statusId =
        this.pageData.status_id == 2 ? "Published" : this.pageData.status_id;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "SET_PAGE_ID") {
        this.pageLocation = parseInt(state.block.pageId);
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch("resetToDef");
    },
    pageTitle(newV, oldV) {
      this.pageSlug = slugify(this.pageTitle).toLocaleLowerCase();
    },
  },
  components: {
    ElButton,
  },
};
</script>

<style scoped>
.wrapper_title {
  position: relative;
  padding: 0 10px;
}
.wrapper_title p {
  font-weight: bold;
  word-break: break-all;
}
/* .wrapper_title p {
  margin: 0;
  padding: 0;
  font-weight: bold;
  border-bottom: 4px solid #42b983;
  width: max-content;
} */
.save_btn {
  background-color: #42b983 !important;
  color: white !important;
  margin-top: 10px !important;
  transition: all 0.3s ease-in-out;
}
.save_btn:hover {
  background-color: #35956a !important;
  color: white !important;
  transition: all 0.3s ease-in-out;
}
.loader__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: white;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.6);
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #42b983;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.pagestatus {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
}
.el-tabs__active-bar.is-top {
  background-color: #338973;
}
</style>
