import api from '../../services/api'

export default {
  state: {
    user: null,
    users: null
  },
  mutations: {
    SET_USER (state, user) {
      state.user = user
    },
    LOG_OUT_USER (state) {
      state.user = null
    },
    SET_USERS_ARRAY (state, users) {
      state.users = users
    },
    DELETE_USER (state, id) {
      state.users.map((singleUser, index) => {
        if (singleUser.id === id) {
          state.users.splice(index, 1)
        }
      })
    },
    ADD_USER (state, user) {
      state.users.push(user)
    }
  },
  actions: {
    setUserObject ({ commit }, user) {
      if (user) {
        commit('SET_USER', user)
      }
    },
    fetchUsersData ({ commit }) {
      return api.get('api/auth/users').then(response => {
        commit('SET_USERS_ARRAY', response.data.data)
      })
    },
    logOutUser ({ commit }) {
      commit('LOG_OUT_USER')
    },
    deleteUser ({ commit }, id) {
      if (id) {
        return api.delete('api/auth/user/delete/' + id).then(response => {
          commit('DELETE_USER', id)
        })
      }
    },
    addUser ({ commit }, user) {
      if (user.id) {
        return api.post('api/auth/update', {
          user_id: user.id,
          role_id: user.role_id,
          name: user.name
        })
      } else {
        return api.post('api/auth/register',
          {
            name: user.name,
            email: user.email,
            password: user.password,
            role_id: user.role_id
          }
        ).then(response => {
          if (user.role_id === 1) {
            response.data.user_object.roles = [{
              id: 1,
              name: 'Administrator',
              slug: 'administrator'
            }]
            commit('ADD_USER', response.data.user_object)
            return response
          } else if (user.role_id === 2) {
            response.data.user_object.roles = [{
              id: 1,
              name: 'Assistant Manager',
              slug: 'manager'
            }]
            commit('ADD_USER', response.data.user_object)
            return response
          }
        }).catch((error) => {
          return error
        })
      }
    }
  }
}
