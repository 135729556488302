<template>
  <h4>{{ formatParam }}</h4>
  <div class="row">
    <div class="col-12 col-sm-4">
      <form class="mt-4" @submit.prevent>
        <label for="size-w"
          >Item name <span class="required-field">*</span></label
        >
        <el-input
          v-model="name"
          type="text"
          required
          id="name"
          placeholder="Item name"
          class="mt-2"
        ></el-input>
        <div v-if="$route.params.type === 'colors'">
          <label class="mt-2" for="size-w"
            >Colors <span class="required-field">*</span></label
          >
          <div class="color-picker">
            <div class="colors-wrapper-box">
              <div
                class="preview"
                :style="{ backgroundColor: colors[0] }"
              ></div>
              <el-color-picker size="small" v-model="colors[0]" />
              <code>{{ colors[0] || "No color..." }}</code>
            </div>
            <div class="colors-wrapper-box">
              <div
                class="preview"
                :style="{ backgroundColor: colors[1] }"
              ></div>
              <el-color-picker size="small" v-model="colors[1]" />
              <code>{{ colors[1] || "No color..." }}</code>
            </div>
          </div>
          <div>
              <span class="mr-5">Focus screen?</span>
              <el-checkbox v-model="isFocus" :checked="isFocus" />
            </div>
        </div>
        <br />
        <el-button class="button_style_1 mt-4" @click="createOrUpdate">
          {{ mode === "create" ? "Add New Item" : "Update Item" }}
        </el-button>
      </form>
    </div>
    <div class="col-12 col-sm-8 mt-2">
      <el-table :data="taxs" stripe style="width: 100%">
        <el-table-column label="Item Name">
          <template #default="scope"
            >{{
              this.$route.params.type !== "colors"
                ? taxs[scope.$index].name
                : taxs[scope.$index].title
            }}
            <div class="danger-area">
              <p
                @click="
                  () => {
                    prepareTaxForUpdate(
                      taxs[scope.$index],
                      taxs[scope.$index].name || taxs[scope.$index].title,
                      taxs[scope.$index].colors,
                      taxs[scope.$index].focus_screen
                    );
                  }
                "
              >
                Edit
              </p>
              <p
                class="delete"
                @click="() => deleteTaxs(taxs[scope.$index].id)"
              >
                Delete
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="havePagination">
        <el-pagination
          layout="prev, pager, next"
          :total="totalNum"
          :page-count="Math.ceil(totalNum / 20)"
          :current-page="pageNo"
          @current-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElButton,
  ElInput,
  ElNotification,
  ElTable,
  ElTableColumn,
  ElMessage,
  ElMessageBox,
  ElCheckbox,
} from "element-plus";
export default {
  components: {
    ElButton,
    ElInput,
    ElTable,
    ElTableColumn,
    ElCheckbox
  },
  data() {
    return {
      name: "",
      taxs: [],
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
      taxToUpdate: {},
      mode: "create",
      colors: ["", ""],
      isFocus: false
    };
  },
  methods: {
    prepareTaxForUpdate(i, name, colors, isFocus) {
      this.taxToUpdate = i;
      this.name = name;
      this.mode = "update";
      this.colors = colors;
      this.isFocus = isFocus == "1" ? true : false;
    },
    createOrUpdate() {
      if (
        this.name == "" ||
        (this.$route.params.type === "colors" &&
          (this.colors[0] === "" || this.colors[0] === null))
      ) {
        ElNotification({
          title: "Warning!",
          message: "Mandatory fields must be filled in!",
          type: "warning",
        });
      } else {
        if (this.mode === "create") {
          if (!this.colors[1]) this.colors[1] = this.colors[0];
          this.$api
            .post(
              `${process.env.VUE_APP_SHARED_API}api/${this.$route.params.type}`,
              this.$route.params.type !== "colors"
                ? {
                    name: this.name,
                  }
                : {
                    title: this.name,
                    colors: this.colors,
                    focus_screen: this.isFocus ? '1' : '0'
                  }
            )
            .then((res) => {
              if (res.data.id) {
                ElMessage({
                  type: "success",
                  message: `${this.name} created successfuly`,
                });
                this.name = "";
                this.colors = ["", ""];
                this.isFocus = false;
                if (this.$route.params.type !== "colors") {
                  this.taxs.push({
                    id: res.data.id,
                    name: res.data.name,
                  });
                } else {
                  this.taxs.push({
                    id: res.data.id,
                    title: res.data.title,
                    colors: res.data.colors,
                  });
                  this.name = "";
                }
              } else {
                ElNotification({
                  title: "Warning!",
                  message: "Something went wrong, please try later!",
                  type: "warning",
                });
              }
            })
            .catch((e) => {
              if (e.response?.data?.errors) {
                ElNotification({
                  title: "Warning!",
                  message: e.response.data.errors.name[0],
                  type: "warning",
                });
              }
            });
        } else {
          if (this.$route.params.type === "colors") {
            if (this.colors[1] == null) this.colors[1] = this.colors[0];
            if (!this.colors[1]) this.colors[1] = this.colors[0];
          }
          this.$api
            .put(
              `${process.env.VUE_APP_SHARED_API}api/${this.$route.params.type}/${this.taxToUpdate.id}`,
              this.$route.params.type !== "colors"
                ? {
                    name: this.name,
                  }
                : {
                    title: this.name,
                    colors: this.colors,
                    focus_screen: this.isFocus ? '1' : '0'
                  }
            )
            .then((res) => {
              if (res.data.id > 0) {
                ElMessage({
                  type: "success",
                  message: `${this.name} updated successfuly`,
                });
                this.fetchTax();

                this.mode = "create";
                this.name = "";
                this.colors = ["", ""];
                this.isFocus = false;
              } else {
                ElNotification({
                  title: "Warning!",
                  message: "Something went wrong, please try later!",
                  type: "warning",
                });
              }
            })
            .catch((e) => {
              if (e.response?.data?.errors) {
                ElNotification({
                  title: "Warning!",
                  message: e.response.data.errors.name[0],
                  type: "warning",
                });
              }
            });
        }
      }
    },
    async fetchTax(pageNo) {
      const loader = this.$loading.show();
      return this.$api
        .get(
          `${process.env.VUE_APP_SHARED_API}api/${
            this.$route.params.type
          }?per_page=20${pageNo ? `&page=${pageNo}` : ``}&sort=DESC`
        )
        .then((res) => {
          if (res.data.current_page) {
            this.taxs = res.data.data;
            loader.hide();
          }
          if (res.data.last_page > 1) {
            this.havePagination = true;
            this.totalNum = res.data.total;
          }
        });
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.fetchTax(pageNo);
    },
    deleteTaxs(id) {
      ElMessageBox.confirm("Are you sure?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$api
          .delete(
            `${process.env.VUE_APP_SHARED_API}api/${this.$route.params.type}/${id}`
          )
          .then((res) => {
            let deleteByIdnex = this.taxs.findIndex((x) => x.id == id);
            if (res.status === 200) {
              ElMessage({
                type: "success",
                message: "Delete completed",
              });
              this.name = "";
              this.colors = ["", ""];
              this.taxs.splice(deleteByIdnex, 1);
            }
          })
          .catch((e) => {
            if (e.response) {
              ElNotification({
                title: "Warning!",
                message: e.response.data,
                type: "warning",
              });
            } else {
              ElNotification({
                title: "Warning!",
                message: e.message,
                type: "warning",
              });
            }
          });
      });
    },
  },
  mounted() {
    this.$store.dispatch("setMediaData", "image");
    this.$store.dispatch("setMediaData", "documents");
    this.$store.dispatch("setMediaData", "audio");
    this.fetchTax();
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.type) {
        this.taxs = [];
        this.fetchTax();
        this.colors = [];
        this.name = "";
        this.mode = "create";
        this.havePagination = false;
        this.isFocus = false;
      }
    },
  },
  computed: {
    formatParam: function () {
      if (this.$route.params.type === "subject-matters") {
        return "Subject Matter";
      }
      return this.$route.params.type.replace("-", " ");
    },
    domainURI() {
      return process.env.VUE_APP_SHARED_API;
    },
  },
};
</script>

<style lang="scss" scoped>
.required-field {
  color: red;
  font-weight: bold;
}
.danger-area {
  width: 100%;
  display: flex;
  opacity: 0;
  transition: all 0.3s;
}
tr:hover .danger-area {
  transition: all 0.3s;
  opacity: 1;
}
.danger-area p {
  margin: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  color: #2271b1;
  margin-right: 10px;
  line-height: 10px;
}
.danger-area p.delete {
  color: #b32d2e;
}
h4 {
  text-transform: capitalize;
}
.colors-wrapper-box {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #f1f5f9;
  max-width: 200px;
  padding-right: 10px;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 20px;
}
.preview {
  min-width: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-right: 20px;
  min-height: 30px;
  background-color: silver;
  cursor: not-allowed;
}
.colors-wrapper-box code {
  font-size: 10px;
  margin-left: 10px;
}
.color-picker {
  display: flex;
  flex-wrap: wrap;
}
.mr-5 {
  margin-right: 5px;
}
</style>
