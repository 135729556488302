<template>
  <div class="single_post_box">
    <h3 class="single_post_box__h3">Tags</h3>
    <div v-if="tags.length > 0">
      <div class="search-wrapper">
        <div class="search-wrapper">
          <el-input
            v-model="searchQuery"
            placeholder="Search tags"
            class="mb-4"
          ></el-input>
          <el-button @click="serverSearch" type="primary">Search</el-button>
        </div>
        <div>
          <div>
            <el-button class="ml-1" @click="() => createNewTag()" type="primary"
              >+</el-button
            >
          </div>
        </div>
      </div>
      <ul class="tags-list">
        <el-checkbox-group v-model="choosenTags">
          <li
            :class="[
              primaryTagId == tag.id
                ? 'active-star tag-list-item'
                : 'tag-list-item',
            ]"
            v-for="tag in filterTagList"
            :key="tag.id"
          >
            <el-checkbox size="large" :label="tag.id"
              >{{ tag.name }}
            </el-checkbox>
            <font-awesome-icon
              @click="() => addTagAsPrimary(tag.id)"
              :icon="['fa', 'star']"
            />
          </li>
        </el-checkbox-group>
      </ul>
    </div>
    <div v-else>No data...</div>
  </div>
</template>

<script>
import { ElCheckbox, ElCheckboxGroup } from "element-plus";
import { searchCategory } from "../../../services/searchCategories";
import { ElMessageBox, ElNotification } from "element-plus";

export default {
  props: {
    tag: {
      type: Array,
      required: true,
    },
    choosenTagS: {
      type: Number,
      required: true,
    },
    primaryTagID: {
      type: Number,
      required: true,
    },
  },
  components: {
    ElCheckbox,
    ElCheckboxGroup,
  },
  data() {
    return {
      searchQuery: "",
      choosenTags: "",
      primaryTagId: 0,
      tags: [],
    };
  },
  watch: {
    tag: {
      immediate: true,
      handler(val, oldVal) {
        this.tags = this.tag;
      },
    },
    choosenTagS: {
      immediate: true,
      handler(val, oldVal) {
        this.choosenTags = this.choosenTagS;
      },
    },
    primaryTagID: {
      immediate: true,
      handler(val, oldVal) {
        this.primaryTagId = this.primaryTagID;
      },
    },
    choosenTags: {
      immediate: true,
      handler(val, oldVal) {
        this.$emit("onPropsChange", {
          choosenTags: this.choosenTags,
        });
      },
    },
    primaryTagId: {
      immediate: true,
      handler(val, oldVal) {
        this.$emit("onPropsChange", {
          primaryTagId: this.primaryTagId,
        });
      },
    },
  },
  methods: {
    addTagAsPrimary(id) {
      this.$emit("onFavorite", id);
    },
    serverSearch() {
      if (this.searchQuery !== "") {
        const loader = this.$loading.show();
        searchCategory(
          this.searchQuery,
          this.$cookies.get("bearerToken"),
          "tags"
        )
          .then((res) => {
            loader.hide();
            if (res.statusText === "OK") {
              this.tags = res.data.data;
            }
          })
          .catch(() => loader.hide());
      }
    },
    createNewTag() {
      ElMessageBox.prompt(`Please enter new item name`, `New tag item`, {
        confirmButtonText: "Create",
        cancelButtonText: "Cancel",
      }).then(async ({ value }) => {
        if (value) {
          const loader = this.$loading.show();
          this.$api
            .post(`${process.env.VUE_APP_SHARED_API}api/tags`, {
              name: value,
            })
            .then((res) => {
              if (res.data.id) {
                this.choosenTags.push(res.data.id);
                this.$emit("onTagCreate", res.data);
                ElNotification({
                  title: "Success!",
                  message: `${value} created successfully.`,
                  type: "success",
                });
              }
              loader.hide();
            })
            .catch((e) => {
              loader.hide();
              if (e.response) {
                for (let error in e.response.data.errors) {
                  for (
                    var i = 0;
                    i < e.response.data.errors[error].length;
                    i++
                  ) {
                    ElNotification({
                      title: "Warning!",
                      message: e.response.data.errors[error][i],
                      type: "warning",
                    });
                  }
                }
              } else {
                ElNotification({
                  title: "Warning!",
                  message: e.message,
                  type: "warning",
                });
              }
            });
        }
      });
    },
  },
  computed: {
    filterTagList() {
      return this.tags.filter((t) => {
        if (t.title !== undefined) {
          return t.title.toLowerCase().includes(this.searchQuery.toLowerCase());
        } else {
          return t.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-title-section-content {
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.single_post_main_box {
  padding: 25px;
  background-color: #f2f2f2;
  border-radius: 5px;
  height: 100%;
}

.single_post_box {
  background-color: #f2f2f2;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 5px;

  .single_post_box__h3 {
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .featured_image_single {
    max-width: 400px;
    margin: 0 auto;
  }
}

.category_checkbox:active,
.category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.categories_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  height: 150px;
  overflow-y: scroll;
}

.single_category__div {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;

  .category_checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }

  .category_checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #42b983;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .category_checkbox:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.buttons_section {
  text-align: right;
}
.tip_tap_editor {
  max-height: 650px;
  height: 100%;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.tags-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-height: 150px;
  height: 100%;
  overflow-y: scroll;
}
.tags-list li {
  padding-left: 10px;
}
.tag-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
.tag-list-item svg {
  width: 18px;
  height: 18px;
  margin-left: 30px;
  cursor: pointer;
  margin-right: 10px;
}
.active-star svg {
  color: #f79c34;
}
.search-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}
.ml-1 {
  margin-left: 5px;
}
</style>
