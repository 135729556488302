<template>
  <div class="row">
    <div class="col">
      <label class="mb-2">General about artist</label>
      <br />
      <label class="mb-2">Born</label>
      <el-input
        v-model="born"
        type="text"
        required
        placeholder="Born"
        class="mb-2"
      ></el-input>
      <br />
      <label class="mb-2">Died </label>
      <el-input
        v-model="died"
        type="text"
        required
        placeholder="Died"
        class="mb-2"
      ></el-input>
    </div>
  </div>
</template>
<script>
import { ElDatePicker } from "element-plus";
export default {
  data() {
    return {
      born: "",
      died: "",
    };
  },
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
      type: Object,
    },
  },
  components: {
    // ElDatePicker,
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            born: this.born === null ? "" : this.born,
            died: this.died === null ? "" : this.died,
          },
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new"
      ) {
        this.born = state.customFields.artists.born;
        this.died = state.customFields.artists.died;
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style>
</style>
