<template>
  <div class="collapse-wrapper">
    <el-collapse v-model="activeNames">
      <sidebar-item-v-2 :selected="selected" />
    </el-collapse>
  </div>
</template>

<script>
import SidebarItemV2 from "./SidebarItemV2.vue";

export default {
  components: {
    SidebarItemV2,
  },
  props: {
    items: Array,
    selected: [Number, Boolean],
  },
  data() {
    return {
      activeNames: "1",
    };
  },
};
</script>

<style lang="scss" scoped>
.collapse-wrapper {
  border: 1px solid #c3c4c7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  background-color: white;
}
</style>
