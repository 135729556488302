<template>
  <div class="row">
    <div class="col">
      <label for="value">Exhibitions</label>
      <div>
        <div v-if="exhibitions.length > 0">
          <div v-for="(item, index) in exhibitions" :key="index">
            <div class="row-wrapper p-2">
              <label class="mt-2" for="year">Cover image </label>
              <ImageSelectorInput
                v-if="item.image_url"
                v-model="item.image_url"
                :multiple="false"
                :featuredImageSlug="item.image_url"
                :pdfSelector="false"
              ></ImageSelectorInput>
              <ImageSelectorInput
                v-else
                v-model="item.image_url"
                :multiple="false"
                :pdfSelector="false"
                :returnSlug="true"
                :featuredImageSlug="'empty'"
              ></ImageSelectorInput>
              <label class="mt-2" for="title">Title </label>
              <el-input
                v-model="item.title"
                type="text"
                placeholder="Title"
                class="mb-2"
              ></el-input>
              <label class="mt-2" for="title">Description </label>
              <tiptap
                style="height: 250px; overflow: scroll"
                v-model="item.text"
              />
              <el-input
                v-model="item.link"
                type="text"
                placeholder="External link"
                class="mt-2"
              ></el-input>
              <div
                class="delete-row"
                @click="
                  () => {
                    this.exhibitions.splice(index, 1);
                  }
                "
              >
                <svg
                  class="svg-inline--fa fa-trash-alt fa-w-14"
                  data-v-b4ae8fbc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="trash-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <el-button
          class="button_style_1 mt-2"
          @click="
            () => {
              this.exhibitions.push({
                image_url: '',
                title: '',
                text: '',
                link: '',
              });
            }
          "
        >
          Add New Row
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElButton, ElInput } from "element-plus";
import ImageSelectorInput from "../Tiptap/ImageSelectorInput.vue";
import Tiptap from "../../components/Tiptap/Tiptap.vue";

export default {
  data() {
    return {
      exhibitions: [],
    };
  },
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
      type: Object,
    },
  },
  components: {
    ElButton,
    ElInput,
    ImageSelectorInput,
    Tiptap,
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            exhibitions: this.exhibitions,
          },
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new"
      ) {
        if (typeof state.customFields.artists.exhibitions == "string") {
          this.exhibitions = JSON.parse(state.customFields.artists.exhibitions);
        } else {
          this.exhibitions = state.customFields.artists.exhibitions;
        }
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  getDomainURL() {
    return process.env.VUE_APP_SHARED_API;
  },
};
</script>

<style lang="scss" scoped>
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  left: -20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
</style>
