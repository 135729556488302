<template>
  <el-dialog
    class="dialog-fix"
    :close-on-click-modal="false"
    :show-close="false"
    v-model="dialog"
  >
    <header class="tab-header media_tab_header">Add new NFTs</header>
    <div class="p-10">
      <div class="nft-repeater">
        <div v-for="(nftItem, index) in nfts" :key="index" class="nft-item">
          <div
            v-if="index > 0"
            class="delete-row"
            style="z-index: 1"
            @click="
              () => {
                nfts.splice(index, 1);
              }
            "
          >
            <svg
              class="svg-inline--fa fa-trash-alt fa-w-14"
              data-v-b4ae8fbc=""
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="trash-alt"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
              ></path>
            </svg>
          </div>
          <div>
            <p>Url <span class="required-field">*</span></p>
            <el-input
              class="mt-2"
              v-model="nftItem.url"
              placeholder="Please enter link"
            />
          </div>
          <div style="max-width: 400px; width: 100%">
            <p>Short description</p>
            <el-input
              class="mt-2"
              v-model="nftItem.description"
              placeholder="Please enter short description"
              type="textarea"
            />
          </div>
        </div>
      </div>
      <el-button @click="addNewRow" class="button_style_1 mv-2"
        >Add new row</el-button
      >
    </div>
    <el-button
      @click="refreshTable"
      :disabled="nfts[0].url === ''"
      class="button_style_1"
      >Save</el-button
    >
    <el-button class="button_style_2" @click="closeDialogInner">
      Close
    </el-button>
  </el-dialog>
</template>

<script>
export default {
  emits: ["onClose", "onSave"],
  data() {
    return {
      dialog: true,
      nfts: [
        {
          url: "",
          description: "",
        },
      ],
    };
  },
  methods: {
    validURL(str) {
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" +
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      return !!pattern.test(str);
    },
    urlValidator() {
      for (const nft of this.nfts) {
        if (nft.url === "" || !this.validURL(nft.url)) {
          return false;
        }
      }
      return true;
    },
    refreshTable() {
      const isValid = this.urlValidator();
      if (isValid) {
        this.$emit("onSave", this.nfts);
      } else {
        this.$notify.error({
          title: "Error",
          message: "Empty required field or URL invalid format.",
          type: "error",
        });
      }
    },
    closeDialogInner() {
      this.$emit("onClose");
      this.dialog = false;
    },
    addNewRow() {
      this.nfts.push({
        url: "",
        description: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mv-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.nft-repeater {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  margin-top: 20px;
}
.nft-item {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  width: 100%;
  position: relative;
}
.nft-item p {
  margin: 0;
}
.delete-row {
  position: absolute;
  top: 5px;
  right: -20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
</style>
