const getDefaultState = () => {
  return {
    serialNumbers: [],
    artworks: {
      collectorTrendsTitle: "",
      collectorTrendsDeletedIds: [],
      taxCollectorId: 0,
      colors: [],
      types: [],
      collector_items: [],
      artistId: 0,
      price: 0,
      sizeWidth: 0,
      sizeHeight: 0,
      quantity: 0,
      valueIndicators: [],
      gallery: [],
      collectorTrends: [],
      techniques: [],
      validateSubFields: true,
      tags: [],
      suits: [],
      subject_matters: [],
      year: "",
      year_end: "",
      nonRequired: [
        "nonRequired",
        "valueIndicatorsCounter",
        "nonRequiredSub",
        "validateSubFields",
        "id",
        "featured_image_id",
        "tags",
        "link",
        "collectorTrendsDeletedIds",
        "collector_items",
        "year",
        "year_end",
        // "title",
        // "description",

        // taxonomies!
        "taxCollectorId",
        "colors",
        "types",
        "gallery",
        // "collectorTrends",
        "valueIndicators",
        "collectorTrendsTitle",
        "techniques",
        "quantity",
        "subject-matters",
      ],
    },
    artists: {
      valueIndicator: {},
      short_description: "",
      born: 0,
      died: 0,
      biography: "",
      awards: [],
      exhibitions: [],
      validateSubFields: true,
      nonRequired: [],
    },
    techniques: {
      valueIndicator: {},
      validateSubFields: true,
      principles: "",
      artist_ids: [],
      pdfData: {},
      nonRequired: [
        "principles",
        "artist_ids",
        "nonRequired",
        "nonRequiredSub",
        "validateSubFields",
        "pdfData",
        "id",
        "valueIndicator",
        "valueIndicators",
        "iconUrl",
      ],
    },
  };
};

export default {
  state: getDefaultState,
  mutations: {
    UPDATE_VALUE(state, payload) {
      state[payload.type] = {
        ...state[payload.type],
        ...payload.data,
      };
    },
    UPGRADE_VALUE(state, payload) {
      state[payload.type] = {
        ...state[payload.type],
        ...payload.data,
      };
    },
    COLLECT_CF_DATA(state) {
      return state;
    },
    COLLECT_FROM_CF(state) {
      return state;
    },
    BIND_VALUE_INDICATORS(state, payload) {
      if (
        state[payload.type].valueIndicators[payload.index] &&
        state[payload.type].valueIndicators.length <= 2
      ) {
        state[payload.type].valueIndicators[payload.index].imageUrl =
          payload.data.icon_url;
        state[payload.type].valueIndicators[payload.index].title =
          payload.data.title;
        state[payload.type].valueIndicators[payload.index].textList =
          payload.data.textList;
      } else if (state[payload.type].valueIndicators.length <= 2) {
        state[payload.type].valueIndicators.push({
          imageUrl: payload.data.icon_url,
          title: payload.data.title,
          textList: payload.data.textList,
        });
      }
    },
    RESET_IMG_SELECTOR(state) {
      return state;
    },
    RESET_COLLECT_FROM_CF(state) {
      state.artworks.valueIndicators = [];
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    REMOVE_FROM_TECHNIQUES(state, payload) {
      let index = state.artworks.techniques.findIndex(function (o) {
        return o.id === payload;
      });
      if (index !== -1) state.artworks.techniques.splice(index, 1);
    },
    ADD_COLLECTOR_ID_TO_REMOVE(state, id) {
      state.artworks.collectorTrendsDeletedIds.push(id);
    },
    REMOVE_FROM_TAGS(state, id) {
      state.artworks.tags = [];
    },
    SERIAL_NUMBERS(state, numbers) {
      let modifyArr = [];
      numbers.map((number) => {
        modifyArr.push({
          ...number,
          available: number.available == 0 ? false : true,
        });
      });
      state.serialNumbers = modifyArr;
    },
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("UPDATE_VALUE", payload);
    },
    fetchSerialNumbers({ commit }, payload) {
      commit("SERIAL_NUMBERS", payload);
    },
    upgradeValue({ commit }, payload) {
      commit("UPGRADE_VALUE", payload);
    },
    collectData({ commit }) {
      commit("COLLECT_CF_DATA");
    },
    bindValueIndicators({ commit }, value) {
      commit("BIND_VALUE_INDICATORS", value);
    },
    resetImgSelector({ commit }) {
      commit("RESET_IMG_SELECTOR");
    },
    collectFromCF({ commit }) {
      commit("COLLECT_FROM_CF");
    },
    resetCollectFromCf({ commit }) {
      commit("RESET_COLLECT_FROM_CF");
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
    removeFromTechniquesArr({ commit }, payload) {
      commit("REMOVE_FROM_TECHNIQUES", payload);
    },
    addCollectorIdForRemove({ commit }, id) {
      commit("ADD_COLLECTOR_ID_TO_REMOVE", id);
    },
    removeFromTags({ commit }) {
      commit("REMOVE_FROM_TAGS");
    },
  },
};
