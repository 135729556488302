<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      <span v-if="$route.name !== 'CustomCat'"> {{ $route.name }}</span>
    </div>
  </div>
  <div class="items container-fluid">
    <div v-if="$route.name === 'Tags'">
      <tags />
    </div>
    <div v-else-if="$route.name === 'CustomCat'">
      <custom-tax />
    </div>
    <div
      v-if="$route.name !== 'Tags' && $route.name !== 'CustomCat'"
      class="serach-wrapper"
    >
      <el-input
        v-model="search"
        size="small"
        @keyup.enter="serachIt"
        placeholder="Type to search"
      />
      <el-button type="primary" @click="serachIt" size="small"
        >Search
      </el-button>
    </div>
    <div
      class="table"
      v-if="$route.name !== 'Tags' && $route.name !== 'CustomCat'"
    >
      <div class="row mt-3 items_form_title">
        <div class="col col-md-6">Title</div>
        <div class="col col-md-2">Author</div>
        <div class="col col-md-2">Created at</div>
        <div class="col col-md-2">Status</div>
      </div>
      <div class="item_list">
        <h2 v-if="$store.state.items.items && !$store.state.items.items[0]">
          No pages added yet.
        </h2>
        <div
          v-else
          class="row mb-2"
          v-for="item in $store.state.items.items"
          :key="item.id"
        >
          <div class="col col-md-6 page-actions">
            <div>
              {{ item.title }}
              <small v-if="item?.page"
                ><b> - {{ item.page.location }}</b></small
              >
            </div>
            <div
              v-if="!$route.name.includes('trash')"
              class="page-actions__wrapper"
            >
              <router-link
                :to="{
                  name: 'edit_item',
                  params: {
                    type: 'page',
                    id: item.id,
                  },
                }"
                >Edit page
              </router-link>
              <button @click="deleteItem('page-translations', item.id)">
                Move to trash
              </button>
              <a
                style="margin-left: 10px"
                target="_blank"
                :href="slugParser(item)"
                >Preview</a
              >
            </div>
            <div class="page-actions__wrapper" v-else>
              <p
                class="single_row_item__p"
                @click="restoreItem('page-translations', item.id)"
              >
                Restore page
              </p>
              <button
                @click="permanentDeleteItem('page-translations', item.id)"
              >
                Delete page permanently
              </button>
            </div>
          </div>
          <div class="col col-md-2">{{ item?.author?.first_name }}</div>
          <div class="col col-md-2">
            {{ $moment(item.created_at).format("DD MMM YYYY") }}
          </div>
          <div class="col col-md-2">
            <span v-if="item.status_id == 2">Publish</span>
            <span v-else>Draft</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="havePagination">
      <el-pagination
        layout="prev, pager, next"
        :total="totalNum"
        :page-count="Math.ceil(totalNum / 10)"
        :current-page="pageNo"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>
<script>
import Tags from "./Tags.vue";
import CustomTax from "./CustomTax.vue";
import { ElMessage, ElMessageBox } from "element-plus";
//Are you sure you want to move this page to trash?
export default {
  name: "ItemsList",
  components: {
    Tags,
    CustomTax,
  },
  data() {
    return {
      type: null,
      props: {},
      languages: [],
      current_page: 1,
      categories: [],
      itemLanguages: [],
      dialogVisible: false,
      singularType: "",
      propsTitle: [],
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
      search: "",
    };
  },
  props: ["singleObj"],
  methods: {
    deleteItem(type, id) {
      ElMessageBox.confirm(
        "Are you sure you want to move this page to trash?",
        "Are you sure?",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          const loader = this.$loading.show();
          this.$store
            .dispatch("deleteItem", {
              type: type,
              id: id,
            })
            .then(() => {
              loader.hide();
            });
        })
        .catch(() => {});
    },
    restoreItem(type, id) {
      const loader = this.$loading.show();
      this.$store
        .dispatch("restoreItem", {
          type: type,
          id: id,
        })
        .then(() => {
          loader.hide();
        });
    },
    permanentDeleteItem(type, id) {
      ElMessageBox.confirm(
        "Are you sure you want to delete this page?",
        "Are you sure?",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "error",
          center: true,
        }
      )
        .then(() => {
          const loader = this.$loading.show();
          this.$store
            .dispatch("permanentDelete", {
              type: type,
              id: id,
            })
            .then(() => {
              loader.hide();
            });
        })
        .catch(() => {});
    },
    openCategoryDialog(categoryName) {
      this.categories = [];
      categoryName.map((category) => {
        category.category_content.map((singleCat) => {
          this.categories.push(singleCat.name);
        });
      });
      this.dialogVisible = true;
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      const loader = this.$loading.show();
      this.$store
        .dispatch("fetchData", { type: "page-translations", page: this.pageNo })
        .then((result) => {
          loader.hide();
        });
    },
    isTrashMode() {
      if (this.$route.fullPath.includes("trash")) {
        return true;
      }
      return false;
    },
    async serachIt(pageNo = 1) {
      let isSearchInTrashMode = this.isTrashMode();
      if (this.search != "") {
        const loader = this.$loading.show();
        let url = `${process.env.VUE_APP_SHARED_API}api/page-translations?per_page=10&search=${this.search}&sort=DESC
          `;
        if (isSearchInTrashMode) {
          url = `${process.env.VUE_APP_SHARED_API}api/page-translations/trash?per_page=10&search=${this.search}&sort=DESC
          `;
        }
        await this.$api
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.$cookies.get("bearerToken")}`,
            },
          })
          .then((res) => {
            loader.hide();
            this.$store.dispatch("setItemData", res);
            if (res.data.last_page > 1) {
              this.totalNum = res.data.total;
              this.havePagination = true;
            } else {
              this.havePagination = false;
            }
          });
      } else {
        const loader = this.$loading.show();
        if (!isSearchInTrashMode) {
          this.$store
            .dispatch("fetchData", {
              type: "page-translations",
              page: this.pageNo,
            })
            .then((result) => {
              if (result.data.last_page > 1) {
                this.totalNum = result.data.total;
                this.havePagination = true;
              }
              loader.hide();
            });
        } else {
          this.$store
            .dispatch("fetchData", {
              type: `page-translations${
                this.$route.name.includes("trash") ? "/trash" : ""
              }`,
            })
            .then(() => loader.hide());
        }
      }
    },
    slugParser(item) {
      if (item?.page?.location == "frontpage") {
        return process.env.VUE_APP_FRONT_PREVIEW;
      } else if (item?.page?.location == "archive-techniques") {
        return process.env.VUE_APP_FRONT_PREVIEW + "techniques";
      } else if (item?.page?.location == "archive-artists") {
        return process.env.VUE_APP_FRONT_PREVIEW + "artist";
      } else if (item.slug) {
        return process.env.VUE_APP_FRONT_PREVIEW + "pages/" + item.slug;
      } else {
        return "#";
      }
    },
  },
  mounted() {
    const loader = this.$loading.show();
    if (
      this.$route.name !== "Tags" &&
      this.$route.name !== "CustomCat" &&
      this.$route.name !== "Pages trash"
    ) {
      this.$store
        .dispatch("fetchData", { type: "page-translations", page: this.pageNo })
        .then((result) => {
          if (result.data.last_page > 1) {
            this.totalNum = result.data.total;
            this.havePagination = true;
          }
          loader.hide();
        });
    } else {
      this.$store
        .dispatch("fetchData", {
          type: `page-translations${
            this.$route.name.includes("trash") ? "/trash" : ""
          }`,
        })
        .then(() => loader.hide());
    }
  },
  watch: {
    $route(to) {
      const loader = this.$loading.show();
      this.$store
        .dispatch("fetchData", {
          type: `page-translations${
            this.$route.name.includes("trash") ? "/trash" : ""
          }`,
        })
        .then(() => loader.hide());
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  // padding-right: 30px;
  padding-left: 30px;

  .items_form_title {
    border-radius: 0px;
    background-color: #1f3c2a;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .item_list {
    padding: 5px 0;
    border: 2px solid #1f3c2a;
    border-top: none;
    padding-top: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #338973;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;
        text-decoration: none !important;

        a {
          color: #338973;
          text-decoration: none !important;
        }
      }

      .add_or_edit_item_language {
        display: flex;
        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #338973;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item > * {
      padding: 0;
      overflow-x: auto;
    }
  }
}
.dialog_category_p {
  font-size: 18px;
}
.item_list .row {
  padding: 5px;
  padding-top: 15px;
}
.item_list .row:nth-child(2n + 1) {
  background-color: #e5e5e5;
}
.item_list .col {
  display: flex;
}
.page-actions {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.page-actions__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 10px;
}
.page-actions__wrapper a,
.single_row_item__p {
  color: #2271b1;
  font-size: 13px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.page-actions__wrapper button {
  color: #b32d2e;
  font-size: 13px;
  background-color: transparent;
  border: none;
  margin: 0;
  margin-left: 10px;
  padding: 0;
}
.item_list h2 {
  text-align: center;
  padding: 20px;
  opacity: 0.7;
  margin: 0;
}

@media (max-width: 991px) {
  .item_list h2 {
    font-size: 16px;
  }
}
.serach-wrapper {
  display: flex;
  max-width: 300px;
}
@media (max-width: 550px) {
  .single_row_item__p {
    font-size: 12px;
  }
}
</style>
