<template>
  <div class="row">
    <div class="col">
      <label for="gallery">Gallery</label>
      <div>
        <ImageSelectorInput
          v-if="gallery.length === 0"
          v-model="gallery"
          :multiple="true"
          :featuredImageSlug="'empty'"
        ></ImageSelectorInput>
        <ImageSelectorInput
          v-else
          v-model="gallery"
          :multiple="true"
          :gallery="gallery"
        ></ImageSelectorInput>
        <!-- <pre>{{ gallery }}</pre> -->
      </div>
    </div>
  </div>
</template>

<script>
import ImageSelectorInput from "../Tiptap/ImageSelectorInput.vue";
export default {
  props: {
    postType: {
      type: String,
    },
    serverData: {
      require: false,
    },
  },
  components: {
    ImageSelectorInput,
  },
  data() {
    return {
      gallery: [],
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$store.dispatch("upgradeValue", {
          type: this.postType,
          data: {
            gallery: this.gallery,
          },
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new"
      ) {
        this.gallery = state.customFields.artworks.gallery;
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  left: -20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
</style>
