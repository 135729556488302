<template>
  <div class="single_post_box">
    <h3 class="single_post_box__h3">Sub Technique</h3>
    <div class="search-wrapper">
      <div class="search-wrapper">
        <el-input v-model="query" :value="query" placeholder="Search sub techniques" class="mb-4"></el-input>
      </div>
      <div>
        <div>
          <el-button class="ml-1" @click="() => createNewSubTech()" type="primary">+</el-button>
        </div>
      </div>
    </div>
    <div v-if="subtechniques.length > 0">
      <ul class="tags-list">
        <el-checkbox-group v-model="choosenSubTechniques">
          <li style="position: relative" v-for="sub in subtechniques" :key="sub.id">
            <div class="delete-row" style="z-index: 1" @click="delectSubTech(sub.id)">
              <svg class="svg-inline--fa fa-trash-alt fa-w-14" data-v-b4ae8fbc="" aria-hidden="true" focusable="false"
                data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512" data-fa-i2svg="">
                <path fill="currentColor"
                  d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z">
                </path>
              </svg>
            </div>
            <el-checkbox @change="selectOrDeSelect(sub)" :checked="this.isInArray(sub.id)" style="margin-left: 30px"
              size="large" :label="sub.id">{{ sub.name }}
            </el-checkbox>
          </li>
        </el-checkbox-group>
      </ul>
    </div>
    <div v-else>No data...</div>
  </div>
</template>
<script>
import { ElMessageBox, ElNotification } from "element-plus";
import slugify from "slugify";

export default {
  name: "SubTechnique",
  props: {
    parentId: String,
    propSubtechniques: [],
  },
  data() {
    return {
      subtechniques: [],
      localParentId: null,
      query: "",
      postData: {
        title: "",
        slug: "",
      },
      choosenSubTechniques: [],
      subtechniques_backup: []
    };
  },
  mounted() {
    // maybe to load data from the server?
  },
  methods: {
    async subTechniqueAPICall(id) {
      if (id) {
        const loader = this.$loading.show();
        const formdata = new FormData();
        formdata.append("parent_id", id);
        formdata.append("name", this.postData.title);
        formdata.append("slug", this.postData.slug);
        await this.$api.post(
          `${process.env.VUE_APP_SHARED_API}api/techniques`,
          formdata
        ).then(async (res) => {
          ElNotification({
            title: "Success!",
            message: `${res.data.name} created successfully.`,
            type: "success",
          });
          this.subtechniques.push({
            name: res.data.name,
            parent_id: res.data.parent_id,
            id: res.data.id,
          });
          await this.select(res.data, this.parentId)
          loader.hide();
        }).catch((err) => {
          if (err.response.status == 422) {
            this.postData.slug = this.postData.slug + '-1';
            this.subTechniqueAPICall(this.parentId)
          } else {
            ElNotification({
              title: "Warning!",
              message: "Unable to create sub-technique, please try again!",
              type: "warning",
            });
          }
          loader.hide();
        });
      }
    },
    async createNewSubTech() {
      ElMessageBox.prompt(
        `Please enter new sub-technique name`,
        `New sub-technique item`,
        {
          confirmButtonText: "Create",
          cancelButtonText: "Cancel",
        }
      ).then(async ({ value }) => {
        if (value) {
          this.postData.title = value;
          this.postData.slug = slugify(value).toLocaleLowerCase();
          await this.subTechniqueAPICall(this.parentId);
        }
      });
    },
    isInArray: function (id) {
      for (let i = 0; i < this.subtechniques.length; i++) {
        if (this.subtechniques[i].id === id) {
          return true;
        }
      }
      return false;
    },
    deleteFromArray: function (id) {
      for (let i = 0; i < this.subtechniques.length; i++) {
        if (this.subtechniques[i].id === id) {
          this.subtechniques.splice(i, 1);
          break;
        }
      }
    },
    async mutateSub(sub, parentId) {
      try {
        await this.$api.put(
          `${process.env.VUE_APP_SHARED_API}api/techniques/${sub.id}`,
          {
            name: sub.name,
            slug: sub.slug,
            parent_id: parentId,
          }
        );
      } catch (_) {
        ElNotification({
          title: "Warning!",
          message: "Unable to pre-select sub-technique, please try again!",
          type: "warning",
        });
      }
    },
    async select(sub) {
      this.mutateSub(sub, this.parentId);
    },
    async deSelect(sub) {
      this.mutateSub(sub, null);
    },
    selectOrDeSelect(sub) {
      if (this.isInArray(sub.id)) {
        this.deSelect(sub);
      } else {
        this.select(sub);
      }
    },
    async delectSubTech(id) {
      const loader = this.$loading.show();
      try {
        const res = await this.$api.delete(
          `${process.env.VUE_APP_SHARED_API}api/techniques/${id}`
        );
        this.deleteFromArray(id);
        loader.hide();
        ElNotification({
          title: "Success!",
          message: `Deleted successfully.`,
          type: "success",
        });
      } catch (_) {
        loader.hide();
        ElNotification({
          title: "Warning!",
          message: "Unable to delete sub-technique, please try again!",
          type: "warning",
        });
      }
    },
  },
  watch: {
    query: {
      immediate: true,
      handler(val, oldVal) {
        const query = val.toLowerCase();
        if (query === '') {
          this.subtechniques = this.subtechniques_backup;
        } else {
          this.subtechniques = this.subtechniques.filter(item => item.name.toLowerCase().includes(query));
        }
      },
    },
    propSubtechniques: {
      immediate: true,
      handler(val, oldVal) {
        if (val?.length > 0) {
          this.subtechniques = this.propSubtechniques;
          this.subtechniques_backup = this.propSubtechniques;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-title-section-content {
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.single_post_main_box {
  padding: 25px;
  background-color: #f2f2f2;
  border-radius: 5px;
  height: 100%;
}

.single_post_box {
  background-color: #f2f2f2;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 5px;

  .single_post_box__h3 {
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .featured_image_single {
    max-width: 400px;
    margin: 0 auto;
  }
}

.category_checkbox:active,
.category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.categories_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  height: 150px;
  overflow-y: scroll;
}

.single_category__div {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;

  .category_checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }

  .category_checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #42b983;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .category_checkbox:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.buttons_section {
  text-align: right;
}

.tip_tap_editor {
  max-height: 650px;
  height: 100%;
}

.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}

.row-wrapper {
  position: relative;
}

.delete-row {
  position: absolute;
  top: 0px;
  left: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}

.delete-row>svg {
  color: white;
  fill: white;
  height: 10px;
  width: 10px;
}

.required-field {
  color: red;
  font-weight: bold;
}

.tags-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-height: 150px;
  height: 100%;
  overflow-y: scroll;
}

.tags-list li {
  padding-left: 10px;
}

.tag-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.tag-list-item svg {
  width: 18px;
  height: 18px;
  margin-left: 30px;
  cursor: pointer;
  margin-right: 10px;
}

.active-star svg {
  color: #f79c34;
}

.search-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}

.ml-1 {
  margin-left: 5px;
}
</style>
