<template>
  <el-button @click="openDialog" class="mt-2" size="small" type="primary"
    >Add new NFTs</el-button
  >
  <AddNFTDialog @onSave="saveNFT" @onClose="closeDialog" v-if="dialog" />
</template>

<script>
import AddNFTDialog from "./AddNFTDialog.vue";
import { saveNFTsRecord } from "@/services/nfts.js";

export default {
  emits: ["onRefresh"],
  data() {
    return {
      NFTs: [],
      dialog: false,
    };
  },
  components: {
    AddNFTDialog,
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      setTimeout(() => {
        this.dialog = false;
      }, 500);
    },
    async saveNFT(nfts) {
      const loader = this.$loading.show();
      try {
        if (nfts.length > 5) {
          this.closeDialog();
          this.$notify.warning({
            title: "Warning",
            message:
              "The data has been sent successfully. You will get notified when upload is finished.",
            type: "warning",
          });
          loader.hide();
        }
        const res = await saveNFTsRecord(nfts);
        if (!res.data.errors) {
          this.closeDialog();
          this.$notify.success({
            title: "Success",
            message: "NFTs uploaded successfully.",
            type: "success",
          });
          this.$emit("onRefresh");
        } else {
          this.$notify.warning({
            title: "Warning",
            message: res.data.errors[0].message,
            type: "warning",
          });
        }
        loader.hide();
      } catch (_) {
        loader.hide();
        this.$notify.error({
          title: "Error",
          message: "Unable to create NFTs. Please try later.",
          type: "error",
        });
      }
    },
  },
};
</script>

<style>
</style>
