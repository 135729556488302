import api from './api'

export function getSinglePage(id, singlePageObject) {
  return api.get('api/page-translations/' + id).then(
    results => {
      return results.data
    }
  )
}

export function getSinglePost(id) {
  return api.get('api/posts/' + id).then(
    results => {
      return results.data
    }
  )
}
