import api from './api'
import moment from "moment";

export function getMonthlyReport() {
  let startDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
  return api.get(`api/dashboard-statistics?report=monthly&start_date=${startDate}`).then(
    results => {
      return results.data
    }
  )
}

export function getYearReport() {
  return api.get(`api/dashboard-statistics?report=year`).then(
    results => {
      return results.data
    }
  )
}

export const getAdaptiveReport = (startDate, endDate) => {
  return api.get(`api/dashboard-statistics?start_date=${moment(startDate.value).format('YYYY-MM-DD')}&end_date=${moment(endDate.value).format('YYYY-MM-DD')}`).then(
    results => {
      return results.data
    }
  )
}

export function getWeeklyReport() {
  let startDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
  return api.get(`api/dashboard-statistics?report=monthly&start_date=${startDate}&end_date=${moment(Date.now()).format('YYYY-MM-DD')}`).then(
    results => {
      return results.data
    }
  )
}
