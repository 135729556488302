<template>
  <div>
    <div v-if="mediaType === 'image'">
      <el-button v-if="showButton" @click="showModal()" class="button_style_1"
        >Select image
      </el-button>
    </div>
    <div v-else-if="mediaType === 'gallery'">
      <el-button v-if="showButton" @click="showModal()" class="button_style_1"
        >Add images to gallery
      </el-button>
    </div>
    <div v-else-if="mediaType === 'audio'">
      <el-button v-if="showButton" @click="showModal()" class="button_style_1"
        >Select audio file
      </el-button>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :show-close="false"
      class="dialog-fix"
      v-model="dialog"
      style="max-width: 1200px; width: 95vw"
    >
      <div class="search-modal-wrapper">
        <label for="search">Search</label>
        <el-input
          v-model="searchQuery"
          type="text"
          id="search"
          placeholder="Search image"
          @keyup.enter="searchImage"
        ></el-input>
      </div>
      <div class="media-wrapper">
        <div class="media-box-wrapper">
          <header class="tab-header media_tab_header">
            <button
              v-if="mediaType === 'image'"
              @click="tab = 1"
              :class="{ active: tab == 1 }"
              type="button"
            >
              Images
            </button>
            <button
              v-if="mediaType === 'gallery'"
              @click="tab = 1"
              :class="{ active: tab == 1 }"
              type="button"
            >
              Images
            </button>
            <button
              v-if="mediaType === 'document'"
              @click="tab = 1"
              :class="{ active: tab == 1 }"
              type="button"
            >
              Documents
            </button>
            <button
              v-if="mediaType === 'audio'"
              @click="tab = 1"
              :class="{ active: tab == 1 }"
              type="button"
            >
              Audio Files
            </button>
            <button
              @click="tab = 0"
              :class="{ active: tab == 0 }"
              type="button"
            >
              Upload (Drag 'n' Drop)
            </button>
          </header>
          <div v-if="tab == 1">
            <div v-if="!$store.state.media.isSearch">
              <div v-if="mediaType === 'image'" class="media_images">
                <div
                  v-for="(img, i) in $store.state.media.images"
                  :key="'img-' + i"
                  class="ma-2 selectable"
                  :class="{
                    selected: selectedImage && img.id === selectedImage.id,
                  }"
                >
                  <img
                    :src="img.src"
                    :key="'img-' + i"
                    :alt="img.alt"
                    @click="selectImage(img)"
                    style="
                      height: 100%;
                      object-fit: cover;
                      width: 100%;
                      background-color: #c5c5c5;
                      display: flex;
                    "
                  />
                  <div class="info-box" @click="(e) => infoMedia(e, img)">
                    <i class="fas fa-info"></i>
                  </div>
                  <div
                    class="delete_media"
                    @click="
                      confirmDelete = true;
                      mediaForDelete(img.id);
                    "
                  >
                    <i class="fas fa-trash-alt"></i>
                  </div>
                </div>
              </div>

              <div
                v-if="mediaType === 'gallery'"
                class="d-flex flex-row flex-wrap media_images"
              >
                <div
                  v-for="(img, i) in $store.state.media.images"
                  :key="'img-' + i"
                  class="ma-2 selectable"
                  :class="{
                    selected: multipleSelections.includes(img.id),
                  }"
                  @click="
                    !multipleSelections.includes(img.id)
                      ? selectGalleryImage(img)
                      : removeSelection(img)
                  "
                  style="max-width: 100px; max-height: 100px; margin: 3px"
                >
                  <img
                    :src="img.src"
                    :key="'img-' + i"
                    :alt="img.alt"
                    style="height: 100%"
                  />
                  <div class="info-box" @click="(e) => infoMedia(e, img)">
                    <i class="fas fa-info"></i>
                  </div>
                  <div
                    class="delete_media"
                    @click="
                      (e) => {
                        e.stopPropagation();
                        confirmDelete = true;
                        mediaForDelete(img.id);
                      }
                    "
                  >
                    <i class="fas fa-trash-alt"></i>
                  </div>
                </div>
              </div>

              <div
                v-if="mediaType === 'document'"
                class="d-flex flex-row flex-wrap media_images"
              >
                <div
                  v-for="(doc, i) in $store.state.media.documents"
                  :key="'doc-' + i"
                  @click="
                    selectDocument({
                      id: doc.id,
                      src: doc.src,
                      name: doc.alt,
                      type: 'document',
                    })
                  "
                >
                  <div
                    class="media_document ma-2 selectable"
                    :class="{
                      selected:
                        selectedDocument && doc.id === selectedDocument.id,
                    }"
                  >
                    <i class="far fa-file"></i>
                    <p>{{ doc.alt }}</p>
                  </div>
                  <div
                    class="delete_media"
                    @click="
                      confirmDelete = true;
                      mediaForDelete(doc.id);
                    "
                  >
                    <i class="fas fa-trash-alt"></i>
                  </div>
                </div>
              </div>

              <div
                v-if="mediaType === 'audio'"
                class="d-flex flex-row flex-wrap media_images"
              >
                <div
                  v-for="(singleAudio, i) in $store.state.media.audio"
                  :key="'doc-' + i"
                  class="ma-2 selectable"
                  :class="{
                    selected:
                      selectedAudio && singleAudio.id === selectedAudio.id,
                  }"
                  @click="selectAudio(singleAudio)"
                >
                  <div
                    class="media_document ma-2 selectable"
                    :class="{
                      selected:
                        selectedDocument && doc.id === selectedDocument.id,
                    }"
                  >
                    <i class="fas fa-file-audio"></i>
                    {{ singleAudio.alt }}
                  </div>
                  <div
                    class="delete_media"
                    @click="
                      confirmDelete = true;
                      mediaForDelete(singleAudio.id);
                    "
                  >
                    <i class="fas fa-trash-alt"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tab == 0">
            <div class="drag-and-drop__box">
              <div v-bind="getRootProps()">
                <input v-bind="getInputProps()" />
                <p v-if="isDragActive">Drop the files here ...</p>
                <p v-else>
                  Drag 'n' drop some files here, or click to select files
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="media-preview-box">
          <MediaPreview :previewMediaId="previewMediaId" />
        </div>
      </div>

      <el-button
        v-if="mediaType === 'image'"
        @click="insertImage"
        class="button_style_1"
        :title="validImage ? '' : 'Image URL needs to be valid'"
        :disabled="!selectedImage"
      >
        Add Image
      </el-button>

      <el-button
        v-if="mediaType === 'gallery'"
        @click="insertGallery"
        class="button_style_1"
        :title="validImage ? '' : 'Image URL needs to be valid'"
        :disabled="!selectGalleryImage"
      >
        Add To Gallery
      </el-button>

      <el-button
        v-else-if="mediaType === 'document'"
        @click="insertDocument"
        class="button_style_1"
        :disabled="!selectedDocument"
      >
        Add Document
      </el-button>

      <el-button
        v-else-if="mediaType === 'audio'"
        @click="insertAudio"
        class="button_style_1"
        :disabled="!selectedAudio"
      >
        Add Audio
      </el-button>
      <el-button
        v-if="
          current_page <= last_page && $store.state.media.images.length >= 10
        "
        @click="loadMore"
        class="button_style_1"
      >
        Load More Images
      </el-button>
      <el-button
        class="button_style_2"
        @click="
          dialog = false;
          selectedImage = null;
          closeModal();
        "
      >
        Close
      </el-button>
      <el-button
        v-if="mediaType === 'gallery'"
        class="button_style_1"
        @click="selectAllMedia"
      >
        {{ !selectAll ? "Select all" : "Deselect all" }}
      </el-button>
    </el-dialog>
    <el-dialog
      v-model="confirmDelete"
      title="Are you sure?"
      width="30%"
      :before-close="handleClose"
    >
      <span>Are you sure you want to delete?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="confirmDelete = false" class="button_style_1"
            >Cancel</el-button
          >
          <el-button
            type="primary"
            class="button_style_2 c-w"
            @click="
              confirmDelete = false;
              destroyIt();
            "
            >Delete</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
/**
 * Example of a custom Image selector
 * Key is to emit a select-file event when a file needs to be added
 */
import api from "../../services/api";
import { useDropzone } from "vue3-dropzone";
// import { getAllImages, getAllDocuments, getAllAudio } from '../../services/media'
import app from "../../main";
import { ElMessageBox, ElNotification } from "element-plus";
import { useStore } from "vuex";
import MediaPreview from "./components/MediaPreview.vue";

export default {
  name: "FileSelector",
  components: {
    MediaPreview,
  },
  setup() {
    const store = useStore();
    function onDrop(acceptFiles, rejectReasons) {
      const globalProp = app.config.globalProperties;
      const loader = globalProp.$loading.show();
      const formData = new FormData();

      if (acceptFiles.length == 1) {
        if (acceptFiles[0].size > process.env.VUE_APP_MAX_WEIGHT_BYTE) {
          loader.hide();
          globalProp.$notify({
            title: "Error",
            message: `Your media larger then ${process.env.VUE_APP_MAX_WEIGHT_BYTE_DISPLAY}MB limit!`,
            type: "error",
          });
        } else {
          formData.append("file", acceptFiles[0]);
          formData.append("caption", acceptFiles[0].name);
          api
            .post("/api/media", formData)
            .then((results) => {
              loader.hide();
              store.dispatch("setMediaDataAsLast", {
                id: results.data.id,
                alt: results.data.alt,
                slug: results.data.slug,
                src: process.env.VUE_APP_SHARED_API + results.data.slug,
                caption: results.data.caption
              });
              store.dispatch("setTabIndex", 1);
              globalProp.$notify({
                title: "Success",
                message: "Your media is successfully uploaded!",
                type: "success",
              });
            })
            .catch((e) => {
              loader.hide();
              globalProp.$notify({
                title: `${e.message}`,
                message: `${e.response.data?.errors?.file[0]}`,
                type: "error",
              });
            });
        }
      } else {
        // multiple upload
        const formData = new FormData();
        for (var x = 0; x < acceptFiles.length; x++) {
          if (acceptFiles[x].size < process.env.VUE_APP_MAX_WEIGHT_BYTE) {
            formData.append("media[]", acceptFiles[x]);
          } else {
            loader.hide();
            globalProp.$notify({
              title: "Error",
              message: `${acceptFiles[x].path} is too large. ${process.env.VUE_APP_MAX_WEIGHT_BYTE_DISPLAY}MB is limit!`,
              type: "error",
            });
          }
        }
        api.post("/api/media/bulk-upload", formData).then((res) => {
          loader.hide();
          res.data.media.map((singleMedia) => {
            store.dispatch("setMediaDataAsLast", {
              id: singleMedia.id,
              alt: singleMedia.alt,
              slug: singleMedia.slug,
              caption: singleMedia.caption,
              src:
                process.env.VUE_APP_SHARED_API + singleMedia.properties.medium,
            });
          });
          store.dispatch("setTabIndex", 1);
          globalProp.$notify({
            title: "Success",
            message: "Your media is successfully uploaded!",
            type: "success",
          });
        });
      }
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    const handleClose = (done) => {
      ElMessageBox.confirm("Are you sure to close this dialog?")
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    };

    return {
      getRootProps,
      getInputProps,
      handleClose,
      ...rest,
    };
  },
  props: {
    fileSelect: {
      type: Function,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    mediaType: {
      type: String,
    },
  },
  data() {
    // Some public domain images from wikimedia.
    return {
      dialog: false,
      confirmDelete: false,
      selectedImage: null,
      mediaForDeleteData: null,
      selectedDocument: null,
      selectedAudio: null,
      images: [],
      gallery: [],
      audio: [],
      documents: [],
      imageSrc: "",
      command: null,
      show: false,
      tab: 1,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        addRemoveLinks: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      current_page: 1,
      last_page: 1,
      searchQuery: "",
      mediaToDelete: 0,
      multipleSelections: [],
      selectAll: false,
      previewMediaId: 0,
    };
  },
  computed: {
    validImage() {
      return (
        this.imageSrc.match(/unsplash/) !== null ||
        this.imageSrc.match(/\.(jpeg|jpg|gif|png)$/) != null
      );
    },
  },
  methods: {
    infoMedia(e, img) {
      this.previewMediaId = img.id;
      e.stopPropagation();
      // setTimeout(() => {
      //   this.previewMediaId = 0;
      // }, 10000);
    },
    selectAllMedia() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.$store.state.media.images.map((media) => {
          this.selectGalleryImage(media);
          this.multipleSelections.push(media);
        });
      } else {
        this.multipleSelections = [];
      }
    },
    async loadMore() {
      if (this.current_page <= this.last_page) {
        const loader = this.$loading.show();
        await api
          .get(
            `api/media?per_page=10&sort=DESC&load_meta=1&type=image&page=${
              this.current_page + 1
            }`
          )
          .then((res) => {
            if (res.data) {
              this.$store.dispatch("updateMedia", res.data);
            }
            loader.hide();
          })
          .catch((e) => {
            loader.hide();
          });
      }
      this.current_page = this.current_page + 1;
    },
    async searchImage() {
      // this.isSearch = true;
      const loader = this.$loading.show();
      await this.$api
        .get(
          `${
            process.env.VUE_APP_SHARED_API
          }api/media?search=${this.searchQuery.toLowerCase()}&per_page=10&load_meta=1&type=image&sort=DESC`
        )
        .then((res) => {
          loader.hide();
          this.current_page = res.data.current_page;
          this.last_page = res.data.last_page;
          // this.isSearch = false;
          if (res.data.data[0]) {
            this.$store.dispatch("searchMedia", res.data);
          } else {
            ElNotification({
              title: "Warning!",
              message: "No media items found.",
              type: "warning",
            });
          }
        });
    },
    destroyIt() {
      const loader = this.$loading.show();
      this.$api
        .delete(
          `${process.env.VUE_APP_SHARED_API}api/media/${this.mediaForDeleteData}`
        )
        .then((res) => {
          loader.hide();
          ElNotification({
            title: "Success!",
            message: res.data.message,
            type: "success",
          });
          this.$store.dispatch("removeFromMedia", this.mediaForDeleteData);
          const filteredArray = this.gallery.filter(
            (item) => item.id !== this.mediaForDeleteData
          );
          this.gallery = filteredArray;
          this.mediaForDeleteData = 0;
          this.selectedImage = null;
        })
        .catch((e) => {
          loader.hide();
          ElNotification({
            title: "Warning!",
            message: e.response?.data?.message,
            type: "warning",
          });
        });
    },
    selectImage(img) {
      this.selectedImage = img;
    },
    fetchData(type) {
      this.$store.dispatch("setMediaData", type);
    },
    selectDocument(document) {
      this.selectedDocument = document;
    },
    mediaForDelete(id) {
      const index = this.multipleSelections.indexOf(id);
      if (index !== -1) this.multipleSelections.splice(index, 1);
      this.selectedImage = null;
      this.mediaForDeleteData = id;
    },
    deleteMedia(id) {
      const index = this.multipleSelections.indexOf(id);
      if (index !== -1) this.multipleSelections.splice(index, 1);
      this.selectedImage = null;
      this.$store.dispatch("deleteMedia", id);
    },
    selectGalleryImage(img) {
      this.selectedImage = img;

      this.multipleSelections.push(img.id);

      function imageExists(gallery) {
        return gallery.some(function (el) {
          return el.id === img.id;
        });
      }

      if (this.gallery.length === 0) {
        this.gallery.push(img);
      } else {
        if (imageExists(this.gallery)) {
        } else {
          this.gallery.push(img);
        }
      }
    },
    removeSelection(img) {
      this.multipleSelections.splice(
        this.multipleSelections.indexOf(img.id),
        1
      );
      this.gallery.splice(this.gallery.indexOf(img.id), 1);
    },
    selectAudio(audio) {
      this.selectedAudio = audio;
    },
    showModal() {
      this.dialog = true;
      this.$emit("openModal");
    },
    insertImage() {
      this.fileSelect(this.selectedImage);
      this.selectedImage = "";
      this.closeModal();
    },
    insertGallery() {
      this.fileSelect(this.gallery);
      this.closeModal();
    },
    insertDocument() {
      this.fileSelect(this.selectedDocument);
      this.closeModal();
    },
    insertAudio() {
      this.fileSelect(this.selectedAudio);
      this.closeModal();
    },
    closeModal() {
      this.selectedImage = "";
      this.multipleSelections = [];
      this.selectAll = false;
      this.show = true;
      this.imageSrc = "";
      this.tab = 1;
      this.dialog = false;
      this.gallery = [];
      this.images = [];
      this.$store.dispatch("resetSetMediaDataAsLast");
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "SET_TAB_INDEX") {
        this.tab = state.media.tabIndex;
      }
      if (mutation.type === "UPDATE_IMAGE_MEDIA_DATA") {
        this.last_page = state.media.imageMediaData.last_page;
        this.current_page = state.media.imageMediaData.current_page;
      }
      if (mutation.type === "SET_MEDIA_DATA_AS_LAST") {
        const selectedIds = new Set([
          ...this.multipleSelections,
          ...state.media.selectAutomaticalyIds,
        ]);
        this.multipleSelections = Array.from(selectedIds);
        for (const img of state.media.images) {
          if (selectedIds.has(img.id) && !this.gallery.includes(img)) {
            this.gallery.push(img);
          }
        }
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  mounted() {
    this.last_page = this.$store.state.media.imageMediaData.last_page;
    this.current_page = this.$store.state.media.imageMediaData.current_page;
  },
};
</script>

<style lang="scss" scoped>
.media-wrapper {
  display: flex;
}
.media-preview-box {
  flex: 1 0 20%;
  border: 1px solid #1f3c2a;
  margin-bottom: 30px;
  margin-left: 5px;
}
.media-box-wrapper {
  flex: 1 0 70%;
}
@media (max-width: 991px) {
  .media-wrapper {
    flex-direction: column;
  }
  .media-preview-box,
  .media-box-wrapper {
    flex: 1 0 100%;
  }
}
.media_images {
  border: 1px solid #1f3c2a;
  padding: 15px;
  margin-bottom: 30px;
  max-height: 900px;
  overflow-y: scroll;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(8, minmax(0, 1fr));

  .delete_media {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: red;
    border-radius: 50%;
    align-items: center;
    z-index: 22;
    svg {
      color: white;
    }
  }

  .selectable {
    max-width: 100px;
    max-height: 100px;
    margin: 3px;
  }

  div {
    position: relative;
    flex: 1 1 content;
  }

  div.selected:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  div.selected:after {
    content: "✓";
    font-size: 24px;
    position: absolute;
    top: 20px;
    left: 5px;
    color: #338973;
  }
}

.selectable {
  cursor: pointer;
  flex: 1 1 content;
}

.drag-and-drop__box {
  height: 300px;
  border: 1px solid #338973;
  border-top: 0;
  cursor: pointer;
  margin-bottom: 30px;

  div {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    p {
      font-size: 18px;
    }
  }
}

.media_document {
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0 8px;
  flex-direction: column;
  text-align: center;

  svg {
    font-size: 30px;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
  }
}

.tab-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #222;
}

.tab-header button {
  color: #222;
  background: none;
  border: 0;
  flex: 1;
  padding: 5px 10px;
  cursor: pointer;
}

.tab-header button.active {
  color: #fff;
}

.tab-header.media_tab_header button {
  padding: 20px 0;
  font-size: 20px;
}

.tab-header.media_tab_header button.active {
  background-color: #338973;
}

@media (max-width: 550px) {
  .tab-header.media_tab_header button {
    font-size: 15px;
    word-break: break-all;
  }
}
.tab-header.media_tab_header {
  border-bottom: 1px solid #338973;
}
.dialog-fix {
  max-height: 1200px;
  overflow: scroll;
}
.search-modal-wrapper {
  margin-bottom: 10px;
  display: flex;
  max-width: 300px;
  align-items: center;
  justify-content: space-between;
  align-content: flex-start;
}
.search-modal-wrapper label {
  margin: 0;
  padding: 0;
  flex: 1 1 20%;
}
.c-w {
  color: white !important;
}
@media (max-width: 550px) {
  .media_images {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .drag-and-drop__box div {
    text-align: center;
    font-size: 15px !important;
  }
}
.info-box {
  position: absolute !important;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
