<template>
  <div class="row">
    <div class="col-6">
      <p>Headline</p>
      <el-input
        v-model="blockContent.content.headline"
        placeholder="Headline"
      />
    </div>
    <div class="col-6">
      <p>Sub Headline</p>
      <el-input
        v-model="blockContent.content.subheadline"
        placeholder="Sub Headline"
      />
    </div>
    <el-button
      @click="deleteBlock(blockData.id)"
      class="mt-2 ml-10 col-3"
      size="small"
      type="danger"
      >Delete this block</el-button
    >
  </div>
</template>

<script>
export default {
  name: "DailyCollections",
  props: {
    blockData: Object,
  },
  data() {
    return {
      blockContent: {
        content: {
          headline: "",
          subheadline: "",
        },
      },
    };
  },
  methods: {
    deleteBlock(id) {
      this.$store.dispatch("removeBlockFromList", id);
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_BLOCK_CONTENT") {
        this.$store.dispatch("updateBlockContent", {
          blockData: this.blockData,
          blockContent: this.blockContent,
        });
      }
    });
    if (this.blockData.created_at) {
      this.blockContent.content = this.blockData.content;
    }
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.block-wrapper {
  border: #adb2ad solid 1px;
  padding: 16px 20px;
}
.input-bottom {
  padding-left: 8px;
  margin-top: 8px;
}
.ml-10 {
  margin-left: 11px;
}
</style>
