<template>
  <h3>Browse Artwork</h3>
  <el-button
    @click="deleteBlock(blockData.id)"
    class="mt-2"
    size="small"
    type="danger"
    >Delete this block</el-button
  >
</template>

<script>
export default {
  name: "BrowseArtwork",
  props: {
    blockData: Object,
  },
  methods: {
    deleteBlock(id) {
      this.$store.dispatch("removeBlockFromList", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.block-wrapper {
  border: #adb2ad solid 1px;
  padding: 16px 20px;
}
.input-bottom {
  padding-left: 8px;
  margin-top: 8px;
}
.delete-row {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.relative-parent {
  position: relative;
}
</style>
