<template>
  <h3 class="single_post_box__h3">
    {{
      tax.title !== "subject-matters"
        ? tax.title.replace("-", " ")
        : "Subject Matter"
    }}
    <span v-if="!nonRequired.includes(tax.title)" class="required-field"
      >*</span
    >
  </h3>
  <div v-if="tax && tax.data.length > 0">
    <ul class="tags-list">
      <div class="search-wrapper">
        <div class="search-wrapper">
          <el-input @keyup.enter="serverSearch()"
            v-model="searchQuery"
            placeholder="Search item"
            class="mb-4 p-sticky"
          ></el-input>
          <el-button @click="serverSearch" type="primary">Search</el-button>
        </div>
        <div>
          <el-button
            class="ml-1"
            @click="() => createNewCategory(tax.title)"
            type="primary"
            >+</el-button
          >
        </div>
      </div>
      <div v-if="tax.title !== 'suits'">
        <el-checkbox-group v-model="choosenTax">
          <li class="tag-list-item" v-for="item in filteredList" :key="item.id">
            <el-checkbox size="large" :label="item.id"
              >{{ item.title || item.name }}
            </el-checkbox>
          </li>
        </el-checkbox-group>
      </div>
      <div v-else>
        <el-radio-group v-model="choosenTax[0]">
          <li class="tag-list-item" v-for="item in filteredList" :key="item.id">
            <el-radio size="large" :label="item.id"
              >{{ item.title || item.name }}
            </el-radio>
          </li>
        </el-radio-group>
      </div>
      <el-button
        @click="unSelect"
        style="max-width: fit-content"
        v-if="tax.title === 'suits'"
        type="primary"
        >Unselect</el-button
      >
    </ul>
  </div>
  <div v-else>
    <p>No data...</p>
    <div>
      <el-button @click="() => createNewCategory(tax.title)" type="primary"
        >Add new</el-button
      >
    </div>
  </div>
  <div v-if="isColor">
    <color-modal @onClose="onClose" @onSave="colorSave" />
  </div>
</template>

<script>
import { ElCheckbox, ElCheckboxGroup, ElButton } from "element-plus";
import { ElMessageBox, ElNotification } from "element-plus";
import { searchCategory } from "../../../services/searchCategories";
import ColorModal from "./ColorModal.vue";

export default {
  components: {
    ElCheckbox,
    ElCheckboxGroup,
    ElButton,
    ColorModal,
  },
  props: {
    tax: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: "",
      choosenTax: [],
      serverResponse: [],
      nonRequired: [
        "collector-items",
        "suits",
        "types",
        "colors",
        "subject-matters",
      ],
      isColor: false,
      taxData: this.tax.data,
    };
  },
  methods: {
    serverSearch() {
      if (this.searchQuery !== "") {
        const loader = this.$loading.show();
        searchCategory(
          this.searchQuery,
          this.$cookies.get("bearerToken"),
          this.tax.title
        )
          .then((res) => {
            if (res.status === 200) {
              this.taxData = res.data.data;
            }
            loader.hide();
          })
          .catch(() => loader.hide());
      } else {
        this.taxData = this.tax.data;
      }
    },
    repleaceUnderScore(name) {
      return name.replace(/-/g, "_");
    },
    unSelect() {
      this.choosenTax = [];
    },
    colorsModal() {
      this.isColor = true;
    },
    colorSave(data) {
      const loader = this.$loading.show();
      if (!data.colors[1]) data.colors[1] = data.colors[0];
      this.$api
        .post(`${process.env.VUE_APP_SHARED_API}api/colors`, {
          title: data.title,
          colors: data.colors,
        })
        .then((res) => {
          if (res.data.id) {
            this.$emit("onCreate", {
              categoryName: "colors",
              data: res.data,
            });
            this.onClose();
            loader.hide();
            this.choosenTax.push(res.data.id);
            ElNotification({
              title: "Success!",
              message: `${res.data.title} created successfully.`,
              type: "success",
            });
          }
        })
        .catch((e) => {
          loader.hide();
          if (e.response) {
            for (let error in e.response.data.errors) {
              for (var i = 0; i < e.response.data.errors[error].length; i++) {
                ElNotification({
                  title: "Warning!",
                  message: e.response.data.errors[error][i],
                  type: "warning",
                });
              }
            }
          } else {
            ElNotification({
              title: "Warning!",
              message: e.message,
              type: "warning",
            });
          }
        });
    },
    createNewCategory(categoryName) {
      if (categoryName !== "colors") {
        ElMessageBox.prompt(
          `Please enter new item name`,
          `New ${this.repleaceUnderScore(categoryName)} item`,
          {
            confirmButtonText: "Create",
            cancelButtonText: "Cancel",
          }
        ).then(async ({ value }) => {
          if (value) {
            const loader = this.$loading.show();
            this.$api
              .post(`${process.env.VUE_APP_SHARED_API}api/${categoryName}`, {
                name: value,
              })
              .then((res) => {
                if (res.data.id) {
                  this.$emit("onCreate", {
                    categoryName: categoryName,
                    data: res.data,
                  });
                  this.choosenTax.push(res.data.id);
                  ElNotification({
                    title: "Success!",
                    message: `${value} created successfully.`,
                    type: "success",
                  });
                }
                loader.hide();
              })
              .catch((e) => {
                loader.hide();
                if (e.response) {
                  for (let error in e.response.data.errors) {
                    for (
                      var i = 0;
                      i < e.response.data.errors[error].length;
                      i++
                    ) {
                      ElNotification({
                        title: "Warning!",
                        message: e.response.data.errors[error][i],
                        type: "warning",
                      });
                    }
                  }
                } else {
                  ElNotification({
                    title: "Warning!",
                    message: e.message,
                    type: "warning",
                  });
                }
              });
          }
        });
      } else {
        this.colorsModal();
      }
    },
    onClose() {
      setTimeout(() => {
        this.isColor = false;
      }, 500);
    },
  },
  computed: {
    filteredList() {
      return this.taxData.filter((t) => {
        if (t.title !== undefined) {
          return t.title.toLowerCase().includes(this.searchQuery.toLowerCase());
        } else {
          return t.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        }
      });
    },
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_CF_DATA") {
        this.$emit("collectTaxs", {
          name: this.tax.title,
          taxs: this.choosenTax,
        });
      }
      if (
        mutation.type === "UPDATE_VALUE" &&
        this.$route.query.mode !== "new" &&
        this.choosenTax.length == 0
      ) {
        this.$store.state.customFields.artworks[
          this.repleaceUnderScore(this.tax.title)
        ].map((item) => {
          this.choosenTax.push(item.id);
        });
      }
    });
  },
  mounted() {
    if (this.$route.query.mode !== "new") {
      this.$store.state.customFields.artworks[
        this.repleaceUnderScore(this.tax.title)
      ].map((item) => {
        this.choosenTax.push(item.id);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.navigation-title-section-content {
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.single_post_main_box {
  padding: 25px;
  background-color: #f2f2f2;
  border-radius: 5px;
  height: 100%;
}

.single_post_box {
  background-color: #f2f2f2;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 5px;

  .single_post_box__h3 {
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .featured_image_single {
    max-width: 400px;
    margin: 0 auto;
  }
}

.category_checkbox:active,
.category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.categories_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  height: 150px;
  overflow-y: scroll;
}

.single_category__div {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;

  .category_checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }

  .category_checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #42b983;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .category_checkbox:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.buttons_section {
  text-align: right;
}
.tip_tap_editor {
  max-height: 650px;
  height: 100%;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.tags-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-height: 150px;
  height: 100%;
  overflow-y: scroll;
}
.tags-list li {
  padding-left: 10px;
}
.tag-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
.tag-list-item svg {
  width: 18px;
  height: 18px;
  margin-left: 30px;
  cursor: pointer;
}
.active-star svg {
  color: #f79c34;
}
.p-sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
}
.search-btn {
  max-width: 32px;
  max-height: 32px;
  position: aboslute;
  top: 10px;
  right: 5px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid white;
}
.search-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}
.ml-1 {
  margin-left: 5px;
}
</style>
