<template>
  <div class="blocks-wrapper">
    <el-input v-model="search" placeholder="Search" />
    <div style="padding: 0 2px">
      <small><strong>BLOCKS</strong></small>
      <ul>
        <li v-for="block in filteredList" :key="block.id">
          <span @click="() => addBlock(block)">
            {{ block.name }} <button>+</button>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlocksWrapper",
  methods: {
    addBlock(block) {
      this.$store.dispatch("removeBlockFromList", -1); // quick fix bug
      this.$store.dispatch("addBlockNameToList", block.name);
    },
  },
  computed: {
    filteredList() {
      return this.blockList.filter((block) => {
        return block.querySearch
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  data() {
    return {
      search: "",
      blockList: [
        {
          name: "Hero Block",
          id: 0,
          querySearch: "hero block",
        },
        {
          name: "Two Column Section",
          id: 1,
          querySearch: "two column section",
        },
        {
          name: "Browse Artwork",
          id: 2,
          querySearch: "browse artwork",
        },
        {
          name: "Explore Techniques",
          id: 3,
          querySearch: "explore techniques",
        },
        {
          name: "Discover Artists",
          id: 4,
          querySearch: "discover artists",
        },
        {
          name: "Our Story",
          id: 5,
          querySearch: "our story",
        },
        {
          name: "Explore Techniques Layout 2",
          id: 6,
          querySearch: "explore techniques layout2",
        },
        {
          name: "Discover artists Layout 2",
          id: 7,
          querySearch: "discover artists layout2",
        },
        {
          name: "Daily Collections",
          id: 8,
          querySearch: "daily collections",
        },
        {
          name: "Wysiwyg Editor",
          id: 9,
          querySearch: "wysiwyg editor",
        },
      ],
    };
  },
};
</script>

<style scoped>
.blocks-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 10px 0;
  height: 100%;
  margin: 0;
  border: none;
}
strong {
  margin: 10px 12px 10px 3px;
  color: #757575;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
}
ul {
  list-style-type: none;
  margin: 0;
  margin-left: 3px;
  padding: 0;
}
li {
  display: flex;
  width: 100%;
  font-size: 18px;
  color: #1e1e1e;
  /* padding: 8px; */
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: transparent;
  word-break: break-word;
  border-radius: 0px;
  border: 1px solid transparent;
  transition: all 0.05s ease-in-out;
  position: relative;
  height: auto;
  box-shadow: 0 0 0 2px #338973;
  outline: 3px solid transparent;
  margin-bottom: 15px;
}
li span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
}
button {
  outline: none;
  border: none;
  background: #403f37;
  margin-right: 5px;
  min-width: 32px;
  width: 32px;
  height: 32px;
  padding: 0;
  color: white;
  font-size: 20px;
}
</style>
