<template>
  <div class="block-wrapper">
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="() => {}">
        <el-tab-pane label="Background Image" name="backgroundImage">
          <div style="max-width: 200px">
            <ImageSelectorInput
              v-model="blockContent.content.backgroundImage.url"
              :multiple="false"
              :featuredImageSlug="
                blockContent.content.backgroundImage.url
                  ? blockContent.content.backgroundImage.url
                  : 'empty'
              "
              :returnSlug="true"
            ></ImageSelectorInput>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Headline" name="headline">
          <el-input
            v-model="blockContent.content.headline.text"
            placeholder="Please enter headline"
          />
        </el-tab-pane>
        <el-tab-pane label="Button" name="button">
          <div class="row">
            <div class="col">
              <el-select
                v-model="blockContent.content.button.type"
                class="mt-2"
                placeholder="Button type"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              /></el-select>
              <div class="mt-2">
                <el-select
                  v-if="blockContent.content.button.type == 'action'"
                  v-model="blockContent.content.button.link"
                  placeholder="Link type"
                >
                  <el-option
                    v-for="item in actionsList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                /></el-select>
              </div>
              <div
                class="mt-2"
                v-if="blockContent.content.button.type == 'internal-page'"
              >
                <el-select
                  v-model="blockContent.content.button.link"
                  filterable
                  remote
                  placeholder="Please enter a keyword"
                  :remote-method="remoteMethod"
                  :loading="loading"
                  value-key="id"
                >
                  <el-option
                    v-for="item in data"
                    :key="item.value"
                    :label="item.label"
                    :value="item"
                  />
                </el-select>
              </div>
            </div>
            <div class="col">
              <el-input
                v-model="blockContent.content.button.label"
                placeholder="Please enter button label"
              />
              <el-input
                class="mt-2"
                v-if="blockContent.content.button.type == 'link'"
                v-model="blockContent.content.button.link"
                placeholder="Please enter link"
              />
              <el-checkbox
                class="mt-2"
                v-if="blockContent.content.button.type != 'action'"
                v-model="blockContent.content.button.targetBlank"
                true-value="1"
                false-value="0"
                >Open in new tab?</el-checkbox
              >
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <el-button
    @click="deleteBlock(blockData.id)"
    class="mt-2"
    size="small"
    type="danger"
    >Delete this block</el-button
  >
</template>

<script>
import ImageSelectorInput from "../../Tiptap/ImageSelectorInput.vue";
export default {
  name: "HeroBlock",
  props: {
    blockData: Object,
  },
  data() {
    return {
      options: [
        {
          value: "link",
          label: "External Link",
        },
        {
          value: "action",
          label: "Action",
        },
        {
          value: "internal-page",
          label: "Internal page",
        },
      ],
      blockContent: {
        content: {
          backgroundImage: {
            url: "",
            type: "img",
          },
          headline: {
            text: "",
            type: "text",
          },
          button: {
            type: "",
            label: "",
            link: "",
            targetBlank: false,
          },
        },
      },
      activeName: "backgroundImage",
      actionsList: [
        {
          value: "focusScreen",
          label: "Focus Modal",
        },
      ],
      data: [],
      loading: false,
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_BLOCK_CONTENT") {
        this.$store.dispatch("updateBlockContent", {
          blockData: this.blockData,
          blockContent: this.blockContent,
        });
      }
    });
    if (this.blockData.created_at) {
      if (this.blockData.content.button.link?.id) {
        this.data = [this.blockData.content.button.link];
      }
      this.blockContent.content = this.blockData.content;
    }
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  components: {
    ImageSelectorInput,
  },
  watch: {
    "blockContent.content.button.type"(newV, oldV) {
      if (this.blockContent.content.button) {
        if (oldV) this.blockContent.content.button.link = "";
      }
    },
  },
  methods: {
    deleteBlock(id) {
      this.$store.dispatch("removeBlockFromList", id);
    },
    remoteMethod: function (query) {
      if (query) {
        this.loading = true;
        this.data = [];
        this.$api
          .get(
            `${
              process.env.VUE_APP_SHARED_API
            }api/page-translations?search=${query.toLowerCase()}`
          )
          .then((res) => {
            if (res.data.data) {
              res.data.data.map((item) => {
                this.data.push({
                  id: item.id,
                  label: item.name || item.title,
                  value: item,
                });
              });
              this.loading = false;
            } else {
              this.loading = false;
            }
          });
      } else {
        this.data = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.block-wrapper {
  border: #adb2ad solid 1px;
  padding: 16px 20px;
}
.mt-2 {
  margin-top: 20px;
}
</style>
