<template>
  <table width="100%">
    <thead>
      <tr>
        <td width="70%">Item</td>
        <td width="10%">Cost</td>
        <td width="10%">Qty</td>
        <td width="10%">Total</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="order in orderData" :key="order.id">
        <td width="70%">
          <div class="row">
            <div class="col-1">
              <el-avatar
                :size="30"
                :src="returnServer() + order?.featured_image?.slug"
                :alt="order?.featured_image?.alt"
              />
            </div>
            <div class="col-11">
              <a
                :href="returnFront() + 'artworks/' + order?.slug"
                target="_blank"
              >
                {{ order.title }}
              </a>
            </div>
          </div>
        </td>
        <td width="10%">${{ order.pivot.price }}</td>
        <td width="10%">x{{ order.pivot.quantity }}</td>
        <td width="10%">${{ order.pivot.price * order.pivot.quantity }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td
          width="70%
        "
        ></td>
        <td
          width="10%
        "
        ></td>
        <td
          width="10%
        "
        >
          Order Total:
        </td>
        <td
          width="10%
        "
        >
          <strong>${{ totalNumber }}</strong>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  props: {
    orderData: Object,
  },
  data() {
    return {
      orders: [],
    };
  },
  methods: {
    returnFront() {
      return process.env.VUE_APP_FRONT_PREVIEW;
    },
    returnServer() {
      return process.env.VUE_APP_SHARED_API;
    },
  },
  computed: {
    totalNumber() {
      return this.orders.reduce(
        (acc, current) => acc + current.pivot.price * current.pivot.quantity,
        0
      );
    },
  },
  mounted() {
    this.orders = this.orderData;
  },
};
</script>

<style lang="scss" scoped>
thead {
  background-color: #f8f8f8;
  line-height: 1.4;
  font-size: 13px;
  color: #3c434a;
}
tfoot {
  font-size: 20px;
}
thead td,
tbody td {
  padding: 10px;
  text-align: left;
}
@media (max-width: 550px) {
  tfoot,
  thead td,
  tbody td {
    font-size: 14px;
  }
}
</style>
