<template>
  <div class="preview-box">
    <p class="info-text" v-if="!selectedMedia && !onSelectMedia">
      No media data to preview
    </p>
    <div class="loader-area" v-if="selectedMedia && onSelectMedia">
      <span class="paytm-loader"></span>
    </div>
    <div class="media-data" v-if="isLoaded">
      <ul>
        <li v-for="(value, name, i) in mediaData" :key="i">
          <div class="caption-wrapper">
            <span @click="updateMedia('alt')" v-if="name == 'alt'">
              <b>Alt name:</b> {{ value }}
              <div class="caption-editor">
                <font-awesome-icon
                  :icon="['fas', 'pen']"
                  class="nav_menu_icons"
                />
              </div>
            </span>
          </div>
          <span v-if="name == 'metadata'">
            <b>File type:</b> {{ value.mime }}
          </span>
          <span v-if="name == 'metadata'">
            <b>File size:</b> {{ convertToMB(value.size) }}mb
          </span>
          <span v-if="name == 'metadata'">
            <b>Dimensions:</b> {{ value.width }}X{{ value.height }}
          </span>
          <div class="caption-wrapper">
            <span @click="updateMedia('caption')" v-if="name == 'caption'">
              <b>Caption:</b> {{ value }}
              <div class="caption-editor">
                <font-awesome-icon
                  :icon="['fas', 'pen']"
                  class="nav_menu_icons"
                />
              </div>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ElMessageBox, ElNotification } from "element-plus";

export default {
  data() {
    return {
      selectedMedia: false,
      onSelectMedia: false,
      mediaData: {},
      isLoaded: false,
      whatToUpdate: "alt",
    };
  },
  methods: {
    resetField() {
      setTimeout(() => {
        this.onSelectMedia = false;
        this.selectedMedia = false;
        this.isLoaded = false;
      }, 2000);
    },
    convertToMB(bytes) {
      const megabytes = bytes / (1024 * 1024);
      return megabytes.toFixed(2);
    },
    updateMedia(whatToUpdte) {
      if (this.selectedMedia) {
        ElMessageBox.prompt(
          `Please update media "${whatToUpdte}" name`,
          "Update media",
          {
            confirmButtonText: "Update",
            cancelButtonText: "Cancel",
            inputValue: this.mediaData[whatToUpdte]
          }
        ).then(async ({ value }) => {
          if (value) {
            const loader = this.$loading.show();
            try {
              loader.hide();
              const res = await this.$api.put(
                `${process.env.VUE_APP_SHARED_API}api/media/${this.mediaData.id}`,
                {
                  [whatToUpdte]: value,
                  alt: whatToUpdte === "alt" ? value : this.mediaData.alt
                }
              );
              ElNotification({
                title: "Success!",
                message: `${whatToUpdte} updated successfully.`,
                type: "success",
              });
              if (res.data) {
                this.mediaData = {
                  ...this.mediaData,
                  ...res.data,
                };
              }
            } catch (_) {
              ElNotification({
                title: "Warning!",
                message: `Unable to update "${whatToUpdte}, please try again later!"`,
                type: "warning",
              });
            }
          }
        });
      }
    },
  },
  props: ["previewMediaId"],
  watch: {
    previewMediaId: function (newVal, oldVal) {
      if (newVal > 0) {
        this.selectedMedia = true;
        const desiredMedia = this.$store.state.media.images.find(
          (obj) => obj.id === newVal
        );
        this.mediaData = desiredMedia;
        this.isLoaded = true;
      } else {
        this.isLoaded = false;
        this.selectedMedia = false;
        this.onSelectMedia = false;
      }
    },
  },
};
</script>

<style scoped>
.preview-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.info-text {
  text-align: center;
}

.loader-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paytm-loader {
  color: #1f3c2a;
  width: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
  transform: translateX(-38px);
  animation: loader 0.5s infinite alternate linear;
  -webkit-box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
  -webkit-transform: translateX(-38px);
  -webkit-animation: loader 0.5s infinite alternate linear;
}

@keyframes loader {
  50% {
    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
  }

  100% {
    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
  }
}

@-webkit-keyframes loader {
  50% {
    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
  }

  100% {
    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
  }
}

.media-data {
  max-height: 100%;
  overflow-y: scroll;
  padding: 5px;
  min-width: 220px;
}

@media (max-width: 991px) {
  .media-data {
    padding: 20px 0;
  }

  .preview-box {
    justify-content: flex-start;
    padding-left: 5px;
  }
}

.media-data ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

li {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}

.info-text {
  margin: 0;
}

.caption-wrapper {
  position: relative;
}

.caption-editor {
  position: absolute;
  top: 0px;
  right: 5px;
  border-radius: 2px;
  color: white;
  background-color: #1f3c2a;
  z-index: 22;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.caption-editor svg {
  width: 10px;
  height: 10px;
  transform: translateX(2px);
}
</style>
